import { HttpError } from './types';

export const DEFAULT_ERROR_DISPLAY_MESSAGE = 'Oops..something went wrong';

export class GeneralError extends Error {
  displayMessage: string;

  constructor(message: string, displayMessage?: string) {
    super(message);
    this.displayMessage = displayMessage || message;
    this.displayMessage = this.displayMessage
      ? this.displayMessage
      : DEFAULT_ERROR_DISPLAY_MESSAGE;

    /**
     * The name needs to be hard-coded for each class instead of using this.constructor.name
     * because when javascript is minified/uglified on release build, the class names are shortened
     * and hence this.constructor.name will return the shortened/uglified class name
     */
    this.name = 'GeneralError';

    // capture stack trace doesn't work in React Native and hence stack traces include the constructor of our custom errors
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(message).stack;
    }
  }
}

/**
 * @description Used when a error is raised from BE.
 */
export class APIError extends GeneralError {
  httpError;
  status;
  data;

  constructor(httpError: HttpError, message?: string) {
    let msg = message !== undefined ? message : httpError.message;
    msg = msg || DEFAULT_ERROR_DISPLAY_MESSAGE;
    super(msg, httpError.message);
    this.status = httpError.status;
    this.httpError = httpError;
    this.data = httpError.data;
    this.name = 'BackendError';
  }
}

/**
 * @description Used when a task is cancelled explicitly
 */
export class Cancelled extends GeneralError {
  constructor(message = DEFAULT_ERROR_DISPLAY_MESSAGE) {
    super(message);
    this.name = 'Cancelled';
  }
}

/**
 * @description Used when access token is expired / invalid credentials are provided / refresh token is expired
 */
export class BadAuthentication extends APIError {
  constructor(httpError: HttpError) {
    super(
      httpError,
      `Url: ${httpError.request.url}, Res: ${JSON.stringify(
        httpError?.message
      )} Token: ${JSON.stringify(localStorage.getItem('auth'))}`
    );
    this.name = 'BadAuthentication';
  }
}

/**
 * @description Used when access to a requested resource is restricted
 */
export class ForbiddenAccess extends APIError {
  constructor(httpError: HttpError) {
    super(httpError, `Url: ${httpError.request.url}`);
    this.name = 'ForbiddenAccess';
  }
}

/**
 * @description Used when network issue occurs on client side
 */
export class BadNetwork extends APIError {
  constructor(httpError: HttpError) {
    super(httpError, `Url: ${httpError.request.url}`);
    this.name = 'BadNetwork';
  }
}

/**
 * @description Used when requested resource does not exists on the server
 */
export class NotFound extends APIError {
  constructor(httpError: HttpError) {
    super(httpError, `Url: ${httpError.request.url}`);
    this.name = 'NotFound';
  }
}

/**
 * @description Used when server crashes
 */
export class ServerError extends APIError {
  constructor(httpError: HttpError) {
    super(httpError, `Url: ${httpError.request.url}`);
    this.name = 'ServerError';
  }
}

/**
 * @description Used when fault lies in the response given by backend
 */
export class BadResponse extends APIError {
  constructor(httpError: HttpError, message: string) {
    super(httpError, message);
    this.name = 'BadResponse';
  }
}

/**
 * @description Used when fault lies in the request given by frontend
 */
export class BadRequest extends APIError {
  constructor(httpError: HttpError) {
    super(httpError, `Url: ${httpError.request.url}`);
    this.name = 'BadRequest';
  }
}

/**
 * @description Used when frontend fires too many requests
 */
export class TooManyRequests extends APIError {
  constructor(httpError: HttpError) {
    super(httpError, `Url: ${httpError.request.url}`);
    this.name = 'TooManyRequests';
  }
}

export class BrowserCancel extends APIError {
  constructor(httpError: HttpError) {
    super(httpError, `Url: ${httpError.request.url}`);
    this.name = 'BrowserCancel';
  }
}
