import {
  ButtonBase,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@/lib/components';
import React, { useState } from 'react';
import { SignOutTypes } from '@/lib/contexts/AuthContext/types';
import { useAuthContext } from '@/lib/contexts';
import { PersonIcon, RightOpenKey } from '@/lib/icons';
import { useSidebarContext } from '@/lib/components/layout/Sidebar/SidebarContext';
import { useRouter } from 'next/router';
import { ProfileRoutes, SelectBusinessRoutes } from '@/pages/routes/routes';
import { useTranslation } from 'react-i18next';

export const UserInfo = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { route } = useRouter();
  const { state } = useAuthContext();
  const { slimProfile } = useSidebarContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { signOut } = useAuthContext();

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsMenuOpen(true);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);
  };

  const handleLogout = () => {
    signOut(SignOutTypes.SIGN_OUT_BUTTON);
    handleMenuClose();
  };

  const handleActivityLogsClick = () => {
    router.push(ProfileRoutes.ACTIVITY_LOGS);
    handleMenuClose();
  };

  const name = state.user?.first_name
    ? state.user?.first_name + ' ' + state.user?.last_name
    : 'User';

  return (
    <>
      <ButtonBase
        onClick={handleMenuClick}
        disabled={route === SelectBusinessRoutes.SELECT_BUSINESS}>
        <ListItemButton
          selected={route === ProfileRoutes.ACTIVITY_LOGS || isMenuOpen}>
          {slimProfile ? (
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
          ) : (
            <>
              <ListItemIcon sx={{ px: 0.5 }}>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText
                primary={name}
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  lineHeight: '24px',
                }}
              />
              <RightOpenKey />
            </>
          )}
        </ListItemButton>
      </ButtonBase>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{ paddingLeft: 2 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}>
        <MenuItem onClick={handleActivityLogsClick}>
          {t('profile.activityLogs')}
        </MenuItem>
        <MenuItem onClick={handleLogout}>{t('profile.logout')}</MenuItem>
      </Menu>
    </>
  );
};
