import {
  setGoogleAnalyticsUserProperties,
  setCustomMicrosoftClarityTags,
  AnalyticsService,
} from '@/lib/analytics';
import { UserInfoResponse, UserTypes } from './components/UserInfo';
import { extractBusinessNameFromEmail } from '@/lib/utils/Utils';
import * as Sentry from '@sentry/nextjs';
import { Logger } from '@/lib/logger';
import { Monitoring } from '@/lib/monitoring/MonitoringService';

export const identifyUsersOnThirdPartyServices = (data: UserInfoResponse) => {
  if (
    data?.feature_flags_list?.show_third_party_tracking_and_analytics &&
    data?.business_user_id
  ) {
    let userType = '';
    if (data.is_internal) {
      userType = UserTypes.STAFF_USER;
    } else if (data.is_demo_account) {
      userType = UserTypes.DEMO_CUSTOMER;
    } else if (data.is_sme) {
      userType = UserTypes.SME_CUSTOMER;
    } else {
      userType = UserTypes.NON_SME_CUSTOMER;
    }

    const userName = `${data.first_name}${
      data.last_name ? ` ${data.last_name}` : ''
    }`;

    if (
      userType === UserTypes.SME_CUSTOMER &&
      process.env.NEXT_PUBLIC_PNP_ENABLE_GOOGLE_ANALYTICS === 'true'
    ) {
      setGoogleAnalyticsUserProperties(userType, data.business_user_id);
    } else if (process.env.NEXT_PUBLIC_ENABLE_GOOGLE_ANALYTICS === 'true') {
      setGoogleAnalyticsUserProperties(userType, data.business_user_id);
    }
    if (process.env.NEXT_PUBLIC_ENABLE_MICROSOFT_CLARITY === 'true') {
      setCustomMicrosoftClarityTags(userType, data.business_user_id);
    }
    if (process.env.NEXT_PUBLIC_ENABLE_MIXPANEL === 'true') {
      AnalyticsService.identify(data.business_user_id.toString(), {
        userType: userType,
        businessName: extractBusinessNameFromEmail(data?.business_name ?? ''),
        firstName: data.first_name,
        lastName: data.last_name,
      });
    }

    if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true') {
      Sentry.setUser({
        id: data.business_user_id.toString(),
        business_user_id: data.business_user_id.toString(),
        user_type: userType,
        name: userName,
        businessName: extractBusinessNameFromEmail(data?.business_name ?? ''),
      });
    }
    if (process.env.NEXT_PUBLIC_ENABLE_DATADOG_LOGGER === 'true') {
      Logger.setUser(data.business_user_id.toString(), {
        userName: userName,
      });
    }
    if (process.env.NEXT_PUBLIC_ENABLE_DATADOG_RUM === 'true') {
      Monitoring.setUser(data.business_user_id.toString(), {
        userName: userName,
      });
    }
  }
};
