import { Box, Text } from '@/lib/components';
import React from 'react';
import { theme } from '@/lib/theme';

export enum StatusBadgeVariant {
  GREEN = 'green',
  RED = 'red',
  YELLOW = 'yellow',
  BLUE = 'blue',
  GRAY = 'gray',
}

const StatusConfig = {
  [StatusBadgeVariant.GREEN]: {
    color: theme.baseTheme.palette.typography.jadeite600,
    backgroundColor: theme.baseTheme.palette.typography.jadeite100,
  },
  [StatusBadgeVariant.RED]: {
    color: theme.baseTheme.palette.typography.oldSpice700,
    backgroundColor: theme.baseTheme.palette.typography.oldSpice100,
  },
  [StatusBadgeVariant.YELLOW]: {
    color: theme.baseTheme.palette.typography.warning700,
    backgroundColor: theme.baseTheme.palette.typography.warning100,
  },
  [StatusBadgeVariant.BLUE]: {
    color: theme.baseTheme.palette.typography.oasis700,
    backgroundColor: theme.baseTheme.palette.typography.oasis100,
  },
  [StatusBadgeVariant.GRAY]: {
    color: theme.baseTheme.palette.typography.shades700,
    backgroundColor: theme.baseTheme.palette.typography.shades100,
  },
};

type StatusBadgeType = {
  status: StatusBadgeVariant;
  title: string;
};

export const StatusBadge: React.FC<StatusBadgeType> = ({ status, title }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      paddingX={1}
      paddingY={0.8}
      width="fit-content"
      borderRadius="1000px"
      bgcolor={StatusConfig[status].backgroundColor}>
      <Text color={StatusConfig[status].color} variant="tinySemibold">
        {title}
      </Text>
    </Box>
  );
};
