import { translate } from '@/lib/transalation';
import { SettingsRoutes } from '@/pages/routes/routes';

export interface SettingsTab {
  index: number;
  label: string;
  route: string;
}

export const SettingsTabsConfig: SettingsTab[] = [
  {
    index: 0,
    label: translate('settings.tabs.account'),
    route: SettingsRoutes.ACCOUNT,
  },
  {
    index: 1,
    label: translate('settings.tabs.integrations'),
    route: SettingsRoutes.INTEGRATIONS,
  },
  {
    index: 2,
    label: translate('settings.tabs.manageSubscriptions'),
    route: SettingsRoutes.SUBSCRIPTIONS,
  },
  {
    index: 3,
    label: translate('settings.tabs.teams'),
    route: SettingsRoutes.TEAMS,
  },
];
