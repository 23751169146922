import { NoActivityIcon, NoNotificationIcon, NoReportIcon } from '@/lib/icons';
import { translate } from '@/lib/transalation';

enum NotificationHistoryVariant {
  ALL = 'all',
  NOTIFICATIONS = 'notification',
  ACTIVITY = 'activity',
  REPORT = 'report',
}

export const filters = [
  {
    label: translate('notificationHistory.filterTypes.all.label'),
    value: NotificationHistoryVariant.ALL,
    icon: NoNotificationIcon,
    noDataTitle: translate('notificationHistory.filterTypes.all.noDataTitle'),
    noDataSubtitle: translate(
      'notificationHistory.filterTypes.all.noDataSubTitle'
    ),
  },
  {
    label: translate('notificationHistory.filterTypes.notifications.label'),
    value: NotificationHistoryVariant.NOTIFICATIONS,
    icon: NoNotificationIcon,
    noDataTitle: translate(
      'notificationHistory.filterTypes.notifications.noDataTitle'
    ),
    noDataSubtitle: translate(
      'notificationHistory.filterTypes.notifications.noDataSubTitle'
    ),
  },
  {
    label: translate('notificationHistory.filterTypes.activity.label'),
    value: NotificationHistoryVariant.ACTIVITY,
    icon: NoActivityIcon,
    noDataTitle: translate(
      'notificationHistory.filterTypes.activity.noDataTitle'
    ),
    noDataSubtitle: translate(
      'notificationHistory.filterTypes.activity.noDataSubTitle'
    ),
  },
  {
    label: translate('notificationHistory.filterTypes.report.label'),
    value: NotificationHistoryVariant.REPORT,
    icon: NoReportIcon,
    noDataTitle: translate(
      'notificationHistory.filterTypes.report.noDataTitle'
    ),
    noDataSubtitle: translate(
      'notificationHistory.filterTypes.report.noDataSubTitle'
    ),
  },
];
