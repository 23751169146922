import { SIQLogo, SquadStackLogo } from '@/lib/icons';
import { Box, ButtonBase } from '@/lib/components';
import Link from 'next/link';
import { useSidebarContext } from './SidebarContext';
import { useRouter } from 'next/router';
import { SelectBusinessRoutes } from '@/pages/routes/routes';
import { useAuthContext } from '@/lib/contexts';

export const LogoSection = () => {
  const { slimProfile, sidebarWidth } = useSidebarContext();
  const { state } = useAuthContext();
  const { route } = useRouter();

  return (
    <Box sx={{ width: sidebarWidth, flexGrow: 0, py: 1, px: 0.5 }}>
      <ButtonBase
        component={Link}
        href={'/campaigns'}
        disabled={
          route === SelectBusinessRoutes.SELECT_BUSINESS &&
          state.user?.feature_flags_list.enable_invite_user_feature
        }>
        <Box component={'span'}>
          {slimProfile ? <SIQLogo /> : <SquadStackLogo />}
        </Box>
      </ButtonBase>
    </Box>
  );
};
