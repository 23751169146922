import React from 'react';
import { Box, Tabs, Tab, Text } from '../..';
import { useTheme } from '@mui/material';

export enum TabVariant {
  primary = 'primary',
  secondary = 'secondary',
}

export interface Tab<T> {
  label: string;
  value: T;
}

export interface TableTabType<T> {
  curTab: Tab<T>;
  handleChange: (event: React.SyntheticEvent, newValue: T) => void;
  tabs: Tab<T>[];
  fullWidth?: boolean;
  variant?: TabVariant;
}

export const TableTab = <T,>({
  curTab,
  handleChange,
  tabs,
  fullWidth = false,
  variant = TabVariant.primary,
}: TableTabType<T>) => {
  const theme = useTheme();
  return (
    <Box>
      <Tabs
        value={curTab.value}
        textColor="inherit"
        onChange={handleChange}
        variant="standard"
        sx={
          TabVariant.primary === variant
            ? {
                width: fullWidth ? '100%' : 'fit-content',
                '& .MuiTabs-flexContainer': {
                  borderBottom: '2px solid ',
                  borderColor: theme.baseTheme.palette.typography.neutrals300,
                },
                '& .MuiTab-root': {
                  padding: '4px 16px',
                  textTransform: 'none',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '24px',
                  '&.Mui-selected': {
                    fontWeight: '600',
                    color: theme.baseTheme.palette.typography.shades900,
                  },
                },
                '& .MuiTabs-indicator': {
                  height: '2px',
                  backgroundColor: theme.baseTheme.palette.typography.shades900,
                },
              }
            : TabVariant.secondary === variant
            ? {
                width: fullWidth ? '100%' : 'fit-content',
                '& .MuiTab-root': {
                  textTransform: 'none',
                  fontWeight: '400',
                  lineHeight: '12px',
                  '&.Mui-selected': {
                    fontWeight: '600',
                    borderRadius: '4px',
                    paddingX: 1,
                    paddingY: 0.5,
                    border: 'none',
                    backgroundColor:
                      theme.baseTheme.palette.components.button
                        .topBarBackgroundColor,
                    color: theme.baseTheme.palette.surfaces.primaryBackground,
                  },
                },
                '& .MuiTabs-indicator': {
                  display: 'none',
                },
                '& .MuiButtonBase-root': {
                  minHeight: 'auto',
                  paddingX: 1,
                  paddingY: 0.5,
                },
              }
            : {}
        }>
        {tabs.map((tab) => (
          <Tab
            key={tab.label}
            value={tab.value}
            label={<Text variant="captionSemibold">{tab.label}</Text>}
          />
        ))}
      </Tabs>
    </Box>
  );
};
