import {
  StoredCampaignFilter,
  StoredLeadsFilter,
} from '@/pages/campaigns/constants';
import { validEmailRegex } from '../constants';
import { IS_SME } from '../constants/auth';

export const getInitials = (
  firstName: string | undefined,
  lastName: string | undefined
) => {
  let initials = '';
  if (firstName && firstName.length > 0) {
    initials += firstName.charAt(0).toUpperCase();
  }
  if (lastName && lastName.length > 0) {
    initials += lastName.charAt(0).toUpperCase();
  }
  return initials;
};

export const getStoredFilterKeys = (): string[] => {
  const campaignFilterKeys = Object.keys(localStorage).filter((key) =>
    key.startsWith(StoredCampaignFilter.KEY)
  );
  const leadsFilterKeys = Object.keys(localStorage).filter((key) =>
    key.startsWith(StoredLeadsFilter.KEY)
  );
  const combinedFilterKeys = [
    ...campaignFilterKeys,
    ...leadsFilterKeys,
    IS_SME,
  ];
  return combinedFilterKeys;
};

export const clearLocalStorageExceptForKeys = (keysToKeep: string[]): void => {
  Object.keys(localStorage).forEach((key) => {
    if (!keysToKeep.includes(key)) {
      localStorage.removeItem(key);
    }
  });
};

export const extractEmailFromQueryParams = (query: string): string => {
  const searchString = 'email=';
  const indexOfString = query.search(searchString);
  const lenOfString = searchString.length;
  const indexOfDot = query.indexOf('.', indexOfString + lenOfString);
  const indexOfAmpersand = query.indexOf('&', indexOfDot);
  const email = query.substring(
    indexOfString + lenOfString,
    indexOfAmpersand === -1 ? undefined : indexOfAmpersand
  );

  return email;
};

export const currencyFormat = (value: number, toFixed = 2) => {
  return new Intl.NumberFormat('en-IN', {
    currency: 'INR',
    minimumFractionDigits: 0,
    maximumFractionDigits: toFixed,
  }).format(value);
};

export const extractBusinessNameFromEmail = (businessName: string) => {
  const isValidEmail = validEmailRegex.test(businessName);

  if (!isValidEmail) {
    return businessName;
  } else {
    let bizName = '';
    const emailParts = businessName.split('@');
    if (emailParts.length > 0) {
      bizName = emailParts[1].split('.')[0];
      bizName = bizName.charAt(0).toUpperCase() + bizName.slice(1);
    }
    return bizName;
  }
};

export const convertTextToHTML = (originalText: string) => {
  const lines = originalText.split('\n');
  if (lines.length > 1) {
    const modifiedLines = lines.map((line) => {
      if (line.trim() !== '') {
        return separateTextAndLink(line);
      }
    });
    const resultText = modifiedLines
      .filter((line) => Boolean(line))
      .join('<br>');
    return `<br>${resultText}`;
  } else {
    return originalText;
  }
};

const separateTextAndLink = (str: string) => {
  const linkRegex = /(https?:\/\/[^\s]+)/;
  const link = str.match(linkRegex);
  if (link !== null && link.length > 0) {
    const text = str.split(link[0]);
    if (text[0] === '') {
      if (text[1] && text[1].includes('=')) {
        return (
          text[1].split('=')[1] +
          ' = ' +
          `<a href="${link[0]}" target="_blank" rel="nofollow noreferrer">Link 1 </a>`
        );
      } else {
        return str;
      }
    } else if (text[1] === '') {
      return (
        text[0].split('=')[0] +
        ' = ' +
        `<a href="${link[0]}" target="_blank" rel="nofollow noreferrer">Link 1 </a>`
      );
    }
  } else {
    return str;
  }
};
