import get from 'lodash/get';
import { HttpRequest, HttpResponse } from './types';
export class HttpError {
  name = 'HttpError';
  request;
  data;
  status;
  message;
  constructor(request: HttpRequest, response: HttpResponse) {
    this.request = request;
    this.data = get(response, 'body');
    this.status = get(response, 'status');
    this.message =
      get(response, 'body.error_display_message') ||
      get(response, 'body.error_message') ||
      get(response, 'body.detail');
  }
}
