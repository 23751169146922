import { Box, Card, Text, TextField, Button } from '@/lib/components';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import { useAuthenticatedSWRMutation } from '@/lib/network';
import { CampaignService } from '@/lib/apis/CampaignService';
import toast from 'react-hot-toast';
import { BadRequest } from '@/lib/network/errors';
import { useRouter } from 'next/router';
import { CampaignRoutes } from '@/pages/routes/routes';
import { useAuthContext } from '@/lib/contexts';
import { AnalyticsService } from '@/lib/analytics';
import Image from 'next/image';
import {
  NewCampaignIllustration,
  NewCampaignIllustrationSME,
} from '@/lib/icons';
import { useFeatureFlag } from '@/lib/utils/FeatureFlags';

type AddCampaignNameProps = {
  hasCampaigns: boolean;
  handleClose?: () => void;
};

export const AddCampaignName: React.FC<AddCampaignNameProps> = ({
  hasCampaigns,
  handleClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { state, updateUserInfo } = useAuthContext();
  const [title, setTitle] = useState<string>();
  const titleRef = useRef<string>();
  const router = useRouter();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const featureFlags = useFeatureFlag();

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    titleRef.current = event.target.value;
    if (errorMessage) {
      setErrorMessage(null);
    }
  };

  const {
    trigger: triggerCreateCampaign,
    isMutating: isCreateCampaignLoading,
  } = useAuthenticatedSWRMutation({
    ...CampaignService.createCampaign(),
  });

  const handleCreateButtonClick = () => {
    if (title) {
      AnalyticsService.trackEvent('User clicks on Create button', {
        actor: 'User',
        verb: 'clicks',
        direct_object: 'on Create button',
        direct_object_key: hasCampaigns
          ? 'on create campaign modal of all campaigns page'
          : 'on create campaign component of first campaign page',
        context: hasCampaigns
          ? 'to create a new campaign'
          : 'to create first campaign',
      });

      triggerCreateCampaign(
        { campaignName: title },
        {
          onSuccess: async (data) => {
            AnalyticsService.trackEvent(
              `User creates ${
                hasCampaigns ? 'a new campaign' : 'their first campaign'
              }`,
              {
                actor: 'User',
                verb: 'creates',
                direct_object: hasCampaigns
                  ? 'a new campaign'
                  : 'their first campaign',
                direct_object_key: hasCampaigns
                  ? 'on create campaign modal of all campaigns page'
                  : 'on create campaign component of first campaign page',
                context: 'of customer dashboard',
              }
            );
            if (hasCampaigns && handleClose) {
              handleClose();
            }
            await router.push({
              pathname: CampaignRoutes.CREATE_CAMPAIGN,
              query: { id: data.id.toString() },
            });

            if (!hasCampaigns && state.user) {
              updateUserInfo({ ...state.user, has_campaign: true });
            }
          },
          onError: (error) => {
            if (error instanceof BadRequest) {
              setErrorMessage(
                `${titleRef.current} ` +
                  t('campaigns.addCampaignName.errorMessage')
              );
            } else {
              const message = error?.data?.error || t('somethingWentWrong');
              toast.error(message);
            }
          },
        }
      );
    }
  };

  const handleKeypress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleCreateButtonClick();
    }
  };

  return (
    <>
      <Box display="flex" justifyContent="space-around" alignItems="center">
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
            padding: theme.spacing(2),
            gap: theme.spacing(2),
            minWidth: '460px',
            background: theme.baseTheme.palette.surfaces.surfaceBackground,
            boxShadow: theme.baseTheme.palette.components.card.boxShadow,
            borderRadius: '8px',
          }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Text variant="bodySemibold" sx={{ letterSpacing: '-0.022em' }}>
              {hasCampaigns
                ? t('campaigns.addCampaignName.newCampaigntitle')
                : t('campaigns.addCampaignName.firstCampaigntitle')}
            </Text>
          </Box>
          <Image
            src={
              featureFlags.is_sme
                ? NewCampaignIllustrationSME
                : NewCampaignIllustration
            }
            alt="An illustration depicting a campaign's journey"
            style={{ width: '100%', height: '100%' }}
          />
          <Box width="100%">
            <Text variant="captionSemibold" sx={{ letterSpacing: '-0.022em' }}>
              {t('campaigns.addCampaignName.campaignNameLabel')}
            </Text>
            <Box display="flex" gap="16px" mt={theme.spacing(0.25)}>
              <TextField
                type="text"
                value={title}
                onChange={handleTitleChange}
                placeholder={
                  t('campaigns.addCampaignName.textFieldPlaceHolder') as string
                }
                variant="outlined"
                size="small"
                fullWidth
                onKeyDown={handleKeypress}
              />
              <Button
                onClick={handleCreateButtonClick}
                sx={{
                  borderRadius: '4px',
                  px: theme.spacing(4),
                  py: theme.spacing(1),
                }}
                loadingOptions={{
                  status: isCreateCampaignLoading,
                  showLoader: true,
                  size: 20,
                }}
                disabled={!title}>
                <Text
                  variant="bodyBold"
                  color={theme.baseTheme.palette.typography.contrastTextColor}>
                  {t('campaigns.addCampaignName.createButton')}
                </Text>
              </Button>
            </Box>
            {errorMessage && (
              <Text
                variant="captionRegular"
                sx={{ color: theme.baseTheme.palette.typography.oldSpiceMain }}>
                {errorMessage}
              </Text>
            )}
          </Box>
        </Card>
      </Box>
    </>
  );
};
