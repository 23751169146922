import React from 'react';
import { Inter } from 'next/font/google';
import { createTheme } from '@mui/material/styles';
import {
  eclipse,
  jadeite,
  shades,
  lavender,
  neutrals,
  oasis,
  oldSpice,
  warning,
  indigo,
  green,
} from './colors/panPasand';

export const inter = Inter({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
  fallback: ['sans-serif'],
});

type BaseTheme = {
  palette: {
    surfaces: {
      primaryBackground: string;
      surfaceBackground: string;
      sidebarBackground: string;
      activeBorderColor: string;
      appBarContrastBackground: string;
      seletedSurfaceBackground: string;
      selectedSurfaceContrastBackground: string;
      blackBackgroundColor: string;
      lavender100: string;
      shades100: string;
      oasis100: string;
      oasis200: string;
      positiveBackgroundColor: string;
      eclipse50: string;
      lavender800: string;
      indigo100: string;
      neutrals50: string;
    };
    components: {
      button: {
        backgroundColor: string;
        primaryBackgroundColor: string;
        secondaryBackgroundColor: string;
        disabled: string;
        textButtonColor: string;
        topBarBackgroundColor: string;
        deleteButtonBackgroundColor: string;
        cancelButtonBackgroundColor: string;
        alertButtonbackgroundColor: string;
        successButtonBackgroundColor: string;
        accentButtonBackgroundColor: string;
        radioBackgroundColor: string;
        oldSpice600: string;
        eclipseMain: string;
        neutrals200: string;
      };
      card: {
        boxShadow: string;
        alternateBackground: string;
      };
      radio: {
        color: string;
        checkedColor: string;
      };
      checkbox: {
        color: string;
        checkedColor: string;
        disabledColor: string;
      };
      input: {
        outlineColor: string;
        alternateBackground: string;
        neutrals300: string;
      };
      alert: {
        successBackgroundColor: string;
        infoBackgroundColor: string;
      };
      circularLoader: {
        color: string;
        neutralsMain: string;
      };
      chip: {
        successBackgroundColor: string;
        errorBackgroundColor: string;
        warningBackgroundColor: string;
        secondaryBackgroundColor: string;
        infoBackgroundColor: string;
        primaryBackgroundColor: string;
        templateBackgroundColor: string;
      };
      dots: {
        success: string;
        warning: string;
        disabled: string;
      };
      select: {
        alternateBackground: string;
      };
      tooltip: {
        backgroundColor: string;
      };
      icon: {
        accordianArrowUpBackgroundcolor: string;
        blackColor: string;
        disableIconColor: string;
        greyColor: string;
        successColor: string;
        failureColor: string;
        oasis600: string;
        indigo600: string;
      };
      audioPlayer: {
        primaryColor: string;
      };
      skeletonLoader: {
        backgroundColor: string;
      };
      dialog: {
        backgroundColor: string;
      };
    };
    typography: {
      primaryTextColor: string;
      contrastTextColor: string;
      labelColor: string;
      labelFocusedColor: string;
      alertTextColor: string;
      metabaseDashboardTextColor: string;
      eclipseMain: string;
      lavenderMain: string;
      oldSpiceMain: string;
      oldSpice700: string;
      oldSpice100: string;
      oldSpice500: string;
      neutrals200: string;
      neutrals300: string;
      neutrals400: string;
      neutrals600: string;
      neutrals700: string;
      neutrals800: string;
      oasisMain: string;
      oasis100: string;
      oasis700: string;
      warning600: string;
      shades900: string;
      shades500: string;
      shades300: string;
      lavender700: string;
      warning700: string;
      jadeite700: string;
      jadeiteMain: string;
      shades100: string;
      oasis600: string;
      lavender600: string;
      jadeite300: string;
      jadeite500: string;
      jadeite600: string;
      oldSpice600: string;
      warningMain: string;
      warning100: string;
      neutralsMain: string;
      lavender300: string;
      jadeite100: string;
      shades700: string;
      lavendarMain: string;
      eclipse200: string;
      eclipse50: string;
      lavender500: string;
      neutrals500: string;
    };
    shadows: {
      navbarShadow: string;
    };
    gradient: {
      callerSelection: string;
      businessNameIntial: string;
    };
    border: {
      accordionBorderColor: string;
      borderColor: string;
      buttonBorderColor: string;
      radioBorderColor: string;
      checkboxBorderColor: string;
      textBorderColor: string;
      oldSpice200: string;
      neutrals300: string;
      eclipse100: string;
      chipBorder: string;
    };
  };
  defaultValues: {
    APPBAR_HEIGHT: number;
    TABBAR_HEIGHT: number;
  };
};

declare module '@mui/material/styles/createPalette' {
  interface ColorRange {
    main: string;
    100: string;
    200: string;
    300: string;
    600: string;
    700: string;
    900: string;
  }

  interface PaletteColor extends ColorRange {}
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    default: true;
    selected: true;
    error: true;
    warning: true;
    success: true;
  }
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    display1: React.CSSProperties;
    display2Bold: React.CSSProperties;
    display2Semibold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h3Semibold: React.CSSProperties;
    h3Regular: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h4Semibold: React.CSSProperties;
    h4Regular: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    bodySemibold: React.CSSProperties;
    bodyBold: React.CSSProperties;
    tinyRegular: React.CSSProperties;
    tinySemibold: React.CSSProperties;
    tinyBold: React.CSSProperties;
    captionRegular: React.CSSProperties;
    captionSemibold: React.CSSProperties;
    captionBold: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    display1?: React.CSSProperties;
    display2Bold: React.CSSProperties;
    display2Semibold: React.CSSProperties;
    h3Bold: React.CSSProperties;
    h3Semibold: React.CSSProperties;
    h3Regular: React.CSSProperties;
    h4Bold: React.CSSProperties;
    h4Semibold: React.CSSProperties;
    h4Regular: React.CSSProperties;
    bodyRegular: React.CSSProperties;
    bodySemibold: React.CSSProperties;
    bodyBold: React.CSSProperties;
    tinyRegular: React.CSSProperties;
    tinySemibold: React.CSSProperties;
    tinyBold: React.CSSProperties;
    captionRegular: React.CSSProperties;
    captionSemibold: React.CSSProperties;
    captionBold: React.CSSProperties;
  }
  interface Theme {
    baseTheme: BaseTheme;
  }
  interface ThemeOptions {
    baseTheme: BaseTheme;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    display1: true;
    display2Bold: true;
    display2Semibold: true;
    h3Bold: true;
    h3Semibold: true;
    h3Regular: true;
    h4Bold: true;
    h4Semibold: true;
    h4Regular: true;
    bodyRegular: true;
    bodySemibold: true;
    bodyBold: true;
    tinyRegular: true;
    tinySemibold: true;
    tinyBold: true;
    captionRegular: true;
    captionSemibold: true;
    captionBold: true;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    button: false;
    overline: false;
    caption: false;
  }
}

export const theme = createTheme({
  baseTheme: {
    palette: {
      surfaces: {
        primaryBackground: neutrals['100'],
        surfaceBackground: neutrals.main,
        sidebarBackground: eclipse.main,
        activeBorderColor: lavender.main,
        appBarContrastBackground: eclipse.main,
        seletedSurfaceBackground: lavender['100'],
        selectedSurfaceContrastBackground: lavender['200'],
        blackBackgroundColor: neutrals['700'],
        lavender100: lavender['100'],
        shades100: shades['100'],
        oasis100: oasis['100'],
        oasis200: oasis['200'],
        positiveBackgroundColor: jadeite['100'],
        eclipse50: eclipse['50'],
        lavender800: lavender['800'],
        indigo100: indigo['100'],
        neutrals50: neutrals['50'],
      },
      components: {
        button: {
          backgroundColor: jadeite.main,
          primaryBackgroundColor: jadeite['500'],
          secondaryBackgroundColor: shades['100'],
          disabled: neutrals['200'],
          textButtonColor: lavender[600],
          topBarBackgroundColor: lavender['600'],
          deleteButtonBackgroundColor: oldSpice.main,
          cancelButtonBackgroundColor: neutrals['100'],
          alertButtonbackgroundColor: oldSpice['100'],
          successButtonBackgroundColor: jadeite['100'],
          accentButtonBackgroundColor: lavender['600'],
          radioBackgroundColor: green['100'],
          oldSpice600: oldSpice['600'],
          eclipseMain: eclipse.main,
          neutrals200: neutrals['200'],
        },
        card: {
          boxShadow:
            '0px 0px 0px rgba(48, 49, 51, 0.04),0px 4px 8px rgba(48, 49, 51, 0.08)',
          alternateBackground: neutrals['100'],
        },
        radio: { color: neutrals['300'], checkedColor: jadeite.main },
        checkbox: {
          color: neutrals['300'],
          checkedColor: jadeite.main,
          disabledColor: neutrals['300'],
        },
        input: {
          outlineColor: jadeite.main,
          alternateBackground: neutrals.main,
          neutrals300: neutrals['300'],
        },
        alert: {
          successBackgroundColor: jadeite.main,
          infoBackgroundColor: oasis.main,
        },
        circularLoader: {
          color: jadeite.main,
          neutralsMain: neutrals.main,
        },
        chip: {
          successBackgroundColor: jadeite['100'],
          errorBackgroundColor: oldSpice['100'],
          infoBackgroundColor: oasis['100'],
          primaryBackgroundColor: lavender['100'],
          warningBackgroundColor: warning['100'],
          secondaryBackgroundColor: neutrals['200'],
          templateBackgroundColor: neutrals['900'],
        },
        dots: {
          success: jadeite.main,
          warning: warning.main,
          disabled: neutrals['300'],
        },
        select: {
          alternateBackground: neutrals.main,
        },
        tooltip: {
          backgroundColor: neutrals['700'],
        },
        icon: {
          accordianArrowUpBackgroundcolor: shades['900'],
          blackColor: neutrals['700'],
          disableIconColor: shades['500'],
          greyColor: shades['700'],
          successColor: jadeite.main,
          failureColor: oldSpice.main,
          oasis600: oasis['600'],
          indigo600: indigo['600'],
        },
        audioPlayer: {
          primaryColor: lavender.main,
        },
        skeletonLoader: {
          backgroundColor: neutrals['300'],
        },
        dialog: {
          backgroundColor: neutrals['50'],
        },
      },
      typography: {
        primaryTextColor: neutrals['700'],
        contrastTextColor: neutrals.main,
        labelColor: neutrals['600'],
        labelFocusedColor: jadeite.main,
        alertTextColor: oldSpice['600'],
        eclipseMain: eclipse.main,
        metabaseDashboardTextColor: indigo['600'],
        lavenderMain: lavender.main,
        lavender300: lavender['300'],
        lavender600: lavender['600'],
        lavender700: lavender['700'],
        oldSpiceMain: oldSpice.main,
        oldSpice100: oldSpice['100'],
        oldSpice500: oldSpice['500'],
        oldSpice600: oldSpice['600'],
        oldSpice700: oldSpice['700'],
        neutralsMain: neutrals.main,
        neutrals200: neutrals['200'],
        neutrals300: neutrals['300'],
        neutrals400: neutrals['400'],
        neutrals600: neutrals['600'],
        neutrals700: neutrals['700'],
        neutrals800: neutrals['800'],
        oasisMain: oasis.main,
        oasis100: oasis['100'],
        oasis600: oasis['600'],
        oasis700: oasis['700'],
        warningMain: warning.main,
        warning100: warning['100'],
        warning600: warning['600'],
        warning700: warning['700'],
        shades100: shades['100'],
        shades300: shades['300'],
        shades500: shades['500'],
        shades700: shades['700'],
        shades900: shades['900'],
        jadeiteMain: jadeite.main,
        jadeite100: jadeite['100'],
        jadeite300: jadeite['300'],
        jadeite500: jadeite['500'],
        jadeite600: jadeite['600'],
        jadeite700: jadeite['700'],
        lavendarMain: lavender.main,
        eclipse200: eclipse['200'],
        eclipse50: eclipse['50'],
        lavender500: lavender['500'],
        neutrals500: neutrals['500'],
      },
      gradient: {
        callerSelection:
          'linear-gradient(95.34deg, #E3FFF7 0.72%, #F1E3FF 100%) !important',
        businessNameIntial:
          'linear-gradient(to bottom right , #6912CB, #2EB4E6 , #39CEA0 ) !important',
      },
      shadows: {
        navbarShadow:
          '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1)',
      },
      border: {
        accordionBorderColor: neutrals['700'],
        borderColor: neutrals['200'],
        buttonBorderColor: shades['300'],
        radioBorderColor: jadeite['500'],
        textBorderColor: shades['700'],
        checkboxBorderColor: neutrals['600'],
        oldSpice200: oldSpice['200'],
        neutrals300: neutrals['300'],
        eclipse100: eclipse['100'],
        chipBorder: lavender['200'],
      },
    },
    defaultValues: {
      APPBAR_HEIGHT: 56,
      TABBAR_HEIGHT: 48,
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
    display1: {
      fontSize: 56,
      lineHeight: '72px',
      fontWeight: 700,
    },
    display2Bold: { fontSize: 48, lineHeight: '56px', fontWeight: 700 },
    display2Semibold: { fontSize: 48, lineHeight: '56px', fontWeight: 700 },
    h1: { fontSize: 40, lineHeight: '56px', fontWeight: 700 },
    h2: { fontSize: 32, lineHeight: '48px', fontWeight: 600 },
    h3Bold: { fontSize: 24, lineHeight: '32px', fontWeight: 700 },
    h3Semibold: { fontSize: 24, lineHeight: '32px', fontWeight: 600 },
    h3Regular: { fontSize: 24, lineHeight: '32px', fontWeight: 400 },
    h4Bold: { fontSize: 20, lineHeight: '32px', fontWeight: 700 },
    h4Semibold: { fontSize: 20, lineHeight: '32px', fontWeight: 600 },
    h4Regular: { fontSize: 20, lineHeight: '32px', fontWeight: 400 },
    bodyRegular: { fontSize: 16, lineHeight: '24px', fontWeight: 400 },
    bodySemibold: { fontSize: 16, lineHeight: '24px', fontWeight: 600 },
    bodyBold: { fontSize: 16, lineHeight: '24px', fontWeight: 700 },
    captionRegular: { fontSize: 14, lineHeight: '24px', fontWeight: 400 },
    captionSemibold: { fontSize: 14, lineHeight: '24px', fontWeight: 600 },
    tinySemibold: { fontSize: 12, lineHeight: '16px', fontWeight: 600 },
    h3: undefined,
    captionBold: { fontSize: 14, lineHeight: '24px', fontWeight: 700 },
    tinyRegular: { fontSize: 12, lineHeight: '16px', fontWeight: 400 },
    tinyBold: { fontSize: 12, lineHeight: '16px', fontWeight: 700 },
    h4: undefined,
    h5: undefined,
    h6: undefined,
    subtitle1: undefined,
    subtitle2: undefined,
    body1: undefined,
    body2: undefined,
    button: undefined,
    caption: undefined,
    overline: undefined,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        WebkitFontSmoothing: 'anti-aliased',
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiFab: {
      defaultProps: {
        disableRipple: true,
        size: 'small',
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor:
            theme.baseTheme.palette.components.button.backgroundColor,
          color: theme.baseTheme.palette.typography.contrastTextColor,
          padding: theme.spacing(1.5, 2),
          '&.Mui-disabled': {
            backgroundColor: theme.baseTheme.palette.components.button.disabled,
            color: theme.baseTheme.palette.typography.contrastTextColor,
          },
        }),
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'default' },
          style: {
            borderRadius: '100px',
            border: '1px solid ',
            boxSizing: 'border-box',
            height: '32px',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '16px',
            backgroundColor: neutrals.main,
            borderColor: neutrals['200'],
            color: eclipse.main,
          },
        },
        {
          props: { variant: 'selected' },
          style: {
            borderRadius: '100px',
            border: '1px solid ',
            boxSizing: 'border-box',
            height: '32px',
            fontSize: '12px',
            lineHeight: '16px',
            backgroundColor: lavender['100'],
            borderColor: lavender['700'],
            color: lavender['700'],
            fontWeight: '700',
          },
        },
        {
          props: { variant: 'error' },
          style: {
            height: '24px',
            backgroundColor: oldSpice['100'],
            color: oldSpice['600'],
          },
        },
        {
          props: { variant: 'warning' },
          style: {
            borderRadius: '100px',
            border: '1px solid',
            boxSizing: 'border-box',
            height: '32px',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '24px',
            backgroundColor: warning['100'],
            borderColor: warning['700'],
            color: warning['700'],
          },
        },
        {
          props: { variant: 'success' },
          style: {
            borderRadius: '100px',
            border: '1px solid',
            boxSizing: 'border-box',
            height: '32px',
            fontWeight: '700',
            fontSize: '14px',
            lineHeight: '24px',
            backgroundColor: jadeite['200'],
            borderColor: jadeite['700'],
            color: jadeite['700'],
          },
        },
      ],
    },
    MuiListItem: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontSize: '14px',
          fontWeight: '500',
          lineHeight: '24px',
          WebkitFontSmoothing: 'subpixel-antialiased',
          paddingLeft: theme.spacing(1.5),
        }),
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: () => ({
          minWidth: 'auto',
        }),
      },
    },
    MuiListItemButton: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.baseTheme.palette.surfaces.sidebarBackground,
          color: theme.baseTheme.palette.typography.contrastTextColor,
          margin: theme.spacing(0.25, 1.5),
          padding: theme.spacing(1, 1.25),
          '&:hover, &.Mui-selected': {
            backgroundColor: eclipse['300'],
            borderRadius: '2px 2px 8px 2px',
          },
          '&.Mui-selected': {
            '&:hover, &.Mui-focusVisible': {
              backgroundColor: eclipse['300'],
              borderRadius: '2px 2px 8px 2px',
            },
          },
        }),
      },
    },
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.baseTheme.palette.surfaces.surfaceBackground,
        }),
      },
    },
    MuiToolbar: {
      defaultProps: {
        variant: 'dense',
      },
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.baseTheme.palette.surfaces.surfaceBackground,
          color: theme.baseTheme.palette.typography.primaryTextColor,
          boxShadow:
            '0px 0px 1px rgba(48, 49, 51, 0.05), 0px 8px 16px rgba(48, 49, 51, 0.1)',
          padding: theme.spacing(2, 3),
        }),
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.baseTheme.palette.components.radio.color,
          '&.Mui-checked': {
            color: theme.baseTheme.palette.components.radio.checkedColor,
          },
        }),
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: ({ theme }) => ({
          color: theme.baseTheme.palette.components.checkbox.color,
          '&.Mui-checked': {
            color: theme.baseTheme.palette.components.checkbox.checkedColor,
          },
          '&.Mui-disabled': {
            color: theme.baseTheme.palette.components.checkbox.disabledColor,
          },
        }),
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-focused': {
            // color: theme.baseTheme.palette.typography.labelColor,
            color: theme.baseTheme.palette.typography.labelFocusedColor,
          },
        }),
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: ({ theme }) => ({
          '& .MuiPaginationItem-root': {
            '&.Mui-selected': {
              background: theme.baseTheme.palette.typography.shades900,
              color: theme.baseTheme.palette.typography.contrastTextColor,
              borderRadius: theme.spacing(0.5),
            },
          },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.baseTheme.palette.components.input.outlineColor,
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.MuiTableCell-footer': {
            fontSize: 16,
          },
          '&.MuiTableCell-body': {
            fontSize: 14,
            padding: theme.spacing(1, 2),
          },
        }),
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.severity === 'success'
            ? {
                backgroundColor:
                  theme.baseTheme.palette.components.alert
                    .successBackgroundColor,
              }
            : ownerState.severity === 'info' && {
                backgroundColor:
                  theme.baseTheme.palette.components.alert.infoBackgroundColor,
              }),
        }),
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          color:
            ownerState.color === 'secondary'
              ? theme.baseTheme.palette.components.circularLoader.neutralsMain
              : theme.baseTheme.palette.components.circularLoader.color,
        }),
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: ({ theme }) => ({
          backgroundColor:
            theme.baseTheme.palette.components.tooltip.backgroundColor,
          fontWeight: '400',
          fontSize: '12px',
          lineHeight: '16px',
        }),
        arrow: ({ theme }) => ({
          color: theme.baseTheme.palette.components.tooltip.backgroundColor,
        }),
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: ({ theme }) => ({
          height: '8px',
          color: '#B5EDDB',
          '& .MuiSlider-mark': {
            backgroundColor: '#BDBDBD',
            height: '8px',
            width: '8px',
            borderRadius: '50%',
            '&.MuiSlider-markActive': {
              opacity: 1,
              backgroundColor: '#1D6750',
            },
          },
          '& .MuiSlider-rail': {
            color: theme.baseTheme.palette.surfaces.primaryBackground,
            opacity: 1,
          },
          '& .MuiSlider-thumb': {
            border: '4px solid #1D6750',
            boxShadow: 'none',
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
              boxShadow: 'inherit',
            },
            '&:before': {
              display: 'none',
            },
          },
        }),
      },
    },

    MuiSelect: {
      defaultProps: {
        MenuProps: {
          PaperProps: {
            style: {
              maxHeight: '30em',
            },
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: () => ({
          '& .MuiTabs-indicator': {
            backgroundColor: '#323232',
            height: '2px',
          },
        }),
      },
    },
  },
});

export type Theme = typeof theme;
