import { theme } from '@/lib/theme';
import { translate } from '@/lib/transalation';

export const campaignModes = [
  'drafting',
  'published',
  'paused',
  'underReview',
  'underSetup',
  'completed',
  'custom',
];

export const campaignIntegrationStatus = ['integrated', 'notIntegrated'];

export const campaignStatusConfig = {
  drafting: {
    label: translate('campaigns.allCampaigns.campaignStatus.drafting'),
    background:
      theme.baseTheme.palette.components.chip.secondaryBackgroundColor,
    color: theme.baseTheme.palette.typography.primaryTextColor,
  },
  published: {
    label: translate('campaigns.allCampaigns.campaignStatus.published'),
    background: theme.baseTheme.palette.components.chip.successBackgroundColor,
    color: theme.baseTheme.palette.typography.jadeite700,
  },
  paused: {
    label: translate('campaigns.allCampaigns.campaignStatus.paused'),
    background: theme.baseTheme.palette.components.chip.errorBackgroundColor,
    color: theme.baseTheme.palette.typography.oldSpice700,
  },
  underReview: {
    label: translate('campaigns.allCampaigns.campaignStatus.underReview'),
    background: theme.baseTheme.palette.components.chip.warningBackgroundColor,
    color: theme.baseTheme.palette.typography.warning700,
  },
  template: {
    label: translate('campaigns.allCampaigns.campaignStatus.template'),
    background: theme.baseTheme.palette.components.chip.templateBackgroundColor,
    color: theme.baseTheme.palette.typography.contrastTextColor,
  },
  underSetup: {
    label: translate('campaigns.allCampaigns.campaignStatus.underSetup'),
    background: theme.baseTheme.palette.components.chip.infoBackgroundColor,
    color: theme.baseTheme.palette.typography.primaryTextColor,
  },
  completed: {
    label: translate('campaigns.allCampaigns.campaignStatus.completed'),
    background: theme.baseTheme.palette.components.chip.templateBackgroundColor,
    color: theme.baseTheme.palette.typography.contrastTextColor,
  },
  custom: {
    label: translate('campaigns.allCampaigns.campaignStatus.custom'),
    background: theme.baseTheme.palette.components.chip.successBackgroundColor,
    color: theme.baseTheme.palette.typography.jadeite700,
  },
};

export const IMPORT_HISTORY = {
  STATUS_SUCCESSFUL: 1,
  STATUS_FAILED: 2,
  TYPE_BLACKLIST_LEADS: 0,
  TYPE_ADD_LEADS: 0,
};

export enum MenuTypes {
  DEFAULT = 'default',
  RESUME = 'resume',
  PAUSE = 'pause',
}

export const menuTypesColor = {
  default: '#171717',
  resume: '#39CEA0',
  pause: '#E74C3C',
};

export enum CampaignSetupTypes {
  CREATE = 'create',
  EDIT = 'edit',
}

export enum StoredCampaignFilter {
  KEY = 'storedCampaignFilter_',
}

export enum StoredLeadsFilter {
  KEY = 'storedLeadsFilter_',
}

export enum GDocEmbedTypes {
  SCRIPT = 'Script',
  FAQ = 'FAQ',
  DEFAULT = '',
}
