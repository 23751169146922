import { UserTypes } from '@/lib/layout/SidebarLayout/components/UserInfo';

const getGoogleAnalyticsMeasurementId = (
  userType: UserTypes | null
): string => {
  if (userType === UserTypes.SME_CUSTOMER) {
    return `${process.env.NEXT_PUBLIC_PNP_GOOGLE_ANALYTICS_KEY}`;
  } else {
    return `${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY}`;
  }
};

export const setGoogleAnalyticsUserProperties = (
  userType: string,
  userId: number
) => {
  const measurementId = getGoogleAnalyticsMeasurementId(userType as UserTypes);

  if (window.gtag !== undefined) {
    window.gtag('config', measurementId, {
      user_id: `${userId}`,
    });

    window.gtag('set', 'user_properties', {
      user_type: `${userType}`,
    });
  }
};

export const removeGoogleAnalyticsUserProperties = (
  userType: UserTypes | null = null
) => {
  const measurementId = getGoogleAnalyticsMeasurementId(userType);

  if (window.gtag !== undefined) {
    window.gtag('config', measurementId, {
      user_id: null,
    });

    window.gtag('set', 'user_properties', {
      user_type: null,
    });
  }
};
