import { useTheme } from '@mui/material';
import { Box, Text, Card } from '@/lib/components';
import { GDocEmbedTypes } from '@/pages/campaigns/constants';
import { ScriptLoadingAnimation } from '../AddCampaignName/AddCampaignName';
import { useTranslation } from 'react-i18next';

export const CloneCampaignLoader = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Card
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        zIndex: 9999,
      }}>
      <Box
        display="flex"
        color={theme.baseTheme.palette.components.circularLoader.color}
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        padding={theme.spacing(1.5, 2)}
        gap={theme.spacing(2)}
        borderRadius="12px"
        bgcolor={theme.baseTheme.palette.typography.neutralsMain}
        height="90%"
        width="50%"
        sx={{ opacity: 1 }}>
        <ScriptLoadingAnimation type={GDocEmbedTypes.DEFAULT} />
        <Text
          variant="h4Bold"
          sx={{
            color: theme.baseTheme.palette.typography.neutrals600,
          }}>
          {t('campaigns.createCampaign.cloningCampaign')}
        </Text>
      </Box>
    </Card>
  );
};
