import { useTheme } from '@mui/material';
import { Box, Card, Text, Grid } from '@/lib/components';
import { FilterOptionsDataType } from '../../type';
import Image from 'next/image';
import { DefaultUseCase } from '@/lib/icons';

type UseCaseListProps = {
  useCase: string;
  useCaseItem: FilterOptionsDataType;
  showFreeTrialBanner: boolean;
  handleUseCaseChange: (useCase: string) => void;
};

export const UseCaseList: React.FC<UseCaseListProps> = ({
  useCase,
  useCaseItem,
  showFreeTrialBanner,
  handleUseCaseChange,
}) => {
  const theme = useTheme();

  return (
    <Grid item key={useCaseItem.label} xs={12} sm={6}>
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: theme.spacing(2),
          gap: theme.spacing(2),
          background: theme.baseTheme.palette.surfaces.surfaceBackground,
          boxShadow: theme.baseTheme.palette.components.card.boxShadow,
          borderRadius: '8px',
          cursor: useCaseItem.label === useCase ? 'pointer' : 'initial',
          opacity: useCaseItem.label !== useCase ? '0.5' : '1',
          width: '100%',
        }}
        onClick={() => handleUseCaseChange(useCaseItem.label)}>
        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: '50px',
              height: '50px',
            }}>
            {useCaseItem.icon_image_url ? (
              <Image
                src={useCaseItem.icon_image_url}
                alt="Use-case icon"
                width={50}
                height={50}
                style={{
                  border: '1px solid',
                  borderRadius: '8px',
                  backgroundColor: theme.baseTheme.palette.surfaces.lavender100,
                  padding: theme.spacing(0.625),
                }}
              />
            ) : (
              <Box
                bgcolor={theme.baseTheme.palette.typography.shades100}
                display={'flex'}
                justifyContent={'center'}
                alignItems="center"
                borderRadius="8px">
                <DefaultUseCase />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={0.5}
            sx={{ position: 'relative' }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between">
              <Text variant="bodyBold" py={1}>
                {useCaseItem.label}
              </Text>
              {showFreeTrialBanner ? (
                <Box
                  bgcolor={
                    theme.baseTheme.palette.surfaces.blackBackgroundColor
                  }
                  padding={1.25}
                  display="flex"
                  alignItems={'center'}
                  borderRadius={5}
                  height="70%">
                  <Text
                    variant="tinyBold"
                    color={theme.baseTheme.palette.typography.neutrals200}>
                    🌟 Free 100 Credits*
                  </Text>
                </Box>
              ) : (
                ''
              )}
            </Box>
            <Text variant="captionRegular">{useCaseItem.description}</Text>
          </Box>
        </Box>
      </Card>
    </Grid>
  );
};
