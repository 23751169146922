import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import advanced from 'dayjs/plugin/advancedFormat';
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
dayjs.extend(relativeTime);
dayjs.extend(calendar);

export const checkIfDateIsValid = (date: string | Date) => {
  return date && dayjs(date).isValid();
};

export const parseDate = (dateStr: string | Date) => {
  if (checkIfDateIsValid(dateStr)) {
    return dayjs(dateStr).toDate();
  } else {
    return 'Invalid Date';
  }
};

export const getFormattedDate = (date: Date, dateFormat: string): string => {
  if (checkIfDateIsValid(date)) {
    return dayjs(date).format(dateFormat);
  } else {
    return 'Invalid Date';
  }
};

export const subtractTime = (
  date: Date,
  time: number,
  unit: dayjs.ManipulateType
) => {
  if (checkIfDateIsValid(date)) {
    return dayjs(date).subtract(time, unit).toDate();
  } else {
    return 'Invalid Date';
  }
};

export const invalidDateCheck = (date: Date | string) => {
  if (date !== 'Invalid Date') {
    return date as Date;
  } else {
    return undefined;
  }
};

export const getRespectiveTimezoneDateTime = (
  dateTimeObject: string | number | Date,
  timezone: string
) => {
  const utcDateTime = dayjs.utc(dateTimeObject).tz(timezone);

  //? We wanted `IST` but dayjs only supports `Indian Standard Time` or `GMT+5:30` as of now. [Github issue](https://github.com/iamkun/dayjs/issues/1154)
  //? So fixed it using [INTL object](https://github.com/iamkun/dayjs/issues/1154#issuecomment-1164017560) for now
  const locale = 'en-IN';
  const shortTimezone = new Intl.DateTimeFormat(locale, {
    timeZone: timezone,
    timeZoneName: 'short',
  })
    .format(Date.now())
    .split(' ')[1];

  return `${utcDateTime.format('MMM DD YYYY hh:mm A')} ${shortTimezone}`;
};

// Format - Mar 15, 2021 from ISO format
export const formatCampaignCreatedAtDate = (date: string) => {
  const formattedDate = dayjs(date).format('MMM DD, YYYY');
  return formattedDate;
};

export const getRespectiveDateTime = (
  dateTimeObject: string | number | Date,
  timezone: string
) => {
  const utcDateTime = dayjs.utc(dateTimeObject).tz(timezone);
  return `${utcDateTime.format('MMM DD YYYY hh:mm A')}`;
};

/*
 * To convert date str to a format satisfying below conditions:
 * - 10 s ago (for seconds - till 59 s and for single digit as 4 s)
 * - 10 m ago (for minutes - till 59 m and for single digit as 4 m)
 * - 23 h ago (for hours - till 23 h and for single digit as 4 h)
 * - yesterday at 5:25 AM/PM (time variants - 5:05 PM, 12:05 AM)
 * - on 13 March at 12:04 AM/PM (date variants - 6 for single digits and full name of month)
 * - on 13 March 2022 at 12:04 AM/PM (full year when it is not current year)
 */

export const getLastUpdatedDate = (dateStr: string) => {
  const date = dayjs(dateStr);
  if (date.isValid()) {
    const currentYear = dayjs().year();
    const actualYear = dayjs(date).year();

    const formatDateBeforeYesterday =
      actualYear === currentYear
        ? '[on] D MMMM [at] h[:]mm A'
        : '[on] D MMMM YYYY [at] h[:]mm A';

    const formattedDate = dayjs(date).calendar(null, {
      lastDay: '[yesterday at] h[:]mm A',
      sameDay: function () {
        const secondsElapsed = dayjs().diff(date, 'seconds');
        const minutesElapsed = dayjs().diff(date, 'minutes');
        const hoursElapsed = dayjs().diff(date, 'hours');

        if (secondsElapsed < 60) {
          return `${secondsElapsed} s ago`;
        } else if (minutesElapsed < 60) {
          return `${minutesElapsed} m ago`;
        } else {
          return `${hoursElapsed} h ago`;
        }
      },
      lastWeek: formatDateBeforeYesterday,
      sameElse: formatDateBeforeYesterday,
    });
    return formattedDate;
  }
  return '';
};

export const getCurrentTime = () => {
  return dayjs().format('hh:mm A');
};

export const getCurrentYear = () => {
  return dayjs().year();
};
