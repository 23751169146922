import { APIService } from './ApiService';

export class NotificationService {
  static getNotifications() {
    return APIService.createGetRequest(
      `voice/dashboard/notifications/v3/notifications/`,
      {
        getQueryParams: (queryParamObj: unknown) => {
          const obj = queryParamObj as {
            page: number;
            type: string;
          };
          return {
            page: obj.page,
            type: obj.type,
          };
        },
      }
    );
  }
}
