export const baseAPIUrl =
  'https://pr14977-5bi9gbffy2zmdfo5rdeger9wr1qkxoqk.tugboatqa.com/voice/dashboard';

export const validEmailRegex =
  /^(([^<>()[\]\\.,;:$^*\s@"]+(\.[^<>()[\]\\.,;:$^*\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g;

export enum LogUserDownloadAction {
  DOWNLOAD_LEADS = 'download_leads',
  DOWNLOAD_FAILED_LEADS = 'download_failed_leads',
  DOWNLOAD_LJR = 'download_ljr',
  DOWNLOAD_BILLING_REPORT = 'download_billing_report',
  DOWNLOAD_LOGS_REPORT = 'download_logs_report',
  DOWNLOAD_INTEL_ASSIST_REPORT = 'download_intel_assist_report',
}

export type LogUserDownload = {
  action: string;
  documentId: string;
};
