import { Box, Button, Text } from '@/lib/components';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useAuthenticatedSWRMutation } from '@/lib/network';
import { ResourcesService } from '@/lib/apis/ResourcesService';
import toast from 'react-hot-toast';

export const ResourcesItemHeader = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { id, title } = router.query;

  const { isMutating, trigger } = useAuthenticatedSWRMutation({
    ...ResourcesService.postPermission(id?.toString()),
    onSuccess: () => {
      router.reload();
    },
    onError: (error) => {
      const message = error?.data?.error || t('somethingWentWrong');
      toast.error(message);
    },
  });

  const handleButtonClick = () => {
    trigger({ permission_type: 'public' });
  };

  return (
    <Box>
      <Text variant="captionSemibold" marginRight={1}>
        {title}
      </Text>
      <Button
        onClick={handleButtonClick}
        loadingOptions={{
          status: isMutating,
          showLoader: true,
          size: 20,
        }}>
        {t('resources.buttonCTA')}
      </Button>
    </Box>
  );
};
