import { Box, IconButton, Text, Chip } from '@/lib/components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CloseIcon } from '@/lib/icons';
import { NotificationCard } from './components/NotificationCard';
import { InfiniteList } from '@/lib/components/core/InfiniteList';
import { ActivityCard } from './components/ActivityCard';
import { ReportCard } from './components/ReportCard';
import { RightSideDrawer } from '@/lib/components/core/RightSideDrawer';
import {
  Filter,
  NotificationHistoryData,
  NotificationHistoryProps,
} from './types';
import { NotificationService } from '@/lib/apis/NotificationService';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { filters } from './constants';
import isEqual from 'lodash/isEqual';
import { NoNotificationData } from './components/NoNotificationData';
import { useAuthenticatedSWRMutation } from '@/lib/network';
import { useAuthContext } from '@/lib/contexts';

const NotificationHistory = ({
  isDrawerOpen,
  handleLeadDrawer,
}: NotificationHistoryProps) => {
  const { t } = useTranslation();
  const { state } = useAuthContext();
  const [selectedFilter, setSelectedFilter] = useState<Filter>(filters[0]);
  const currentPage = useRef(1);
  const [hasMore, setHasMore] = useState(false);
  const [notificationHistoryData, setNotificationHistoryData] = useState<
    NotificationHistoryData[]
  >([]);
  const [timeZone, setTimeZone] = useState('Asia/Kolkata');

  const {
    trigger: triggerGetNotifications,
    isMutating: isNotificationHistoryLoading,
  } = useAuthenticatedSWRMutation({
    ...NotificationService.getNotifications(),
    onSuccess: (data) => {
      setHasMore(data.results.next);
      const notifications = data.results.data;
      if (currentPage.current === 1) {
        setTimeZone(data.custom_timezone);
        setNotificationHistoryData(notifications);
      } else {
        setNotificationHistoryData((prevData: NotificationHistoryData[]) => [
          ...prevData,
          ...notifications,
        ]);
      }
    },
    onError: () => {
      toast.error(t('somethingWentWrong'));
    },
  });

  useEffect(() => {
    if (state?.user?.is_business_user) {
      triggerGetNotifications({ page: 1, type: filters[0].value });
    }
  }, [state?.user?.is_business_user, triggerGetNotifications]);

  const handleFilterChange = useCallback(
    (filter: Filter) => {
      currentPage.current = 1;
      setSelectedFilter(filter);
      setNotificationHistoryData([]);
      triggerGetNotifications({ page: 1, type: filter.value });
    },
    [triggerGetNotifications]
  );

  const handleClose = () => {
    handleLeadDrawer(false);
  };

  const onEndReached = useCallback(() => {
    if (hasMore) {
      const newPage = currentPage.current + 1;
      currentPage.current = newPage;
      triggerGetNotifications({ page: newPage, type: selectedFilter.value });
    }
  }, [hasMore, selectedFilter.value, triggerGetNotifications]);

  const renderCard = (rowData: unknown, index: number): JSX.Element => {
    const data: NotificationHistoryData = rowData as NotificationHistoryData;
    if (data.notification_type === 'Notification') {
      return (
        <NotificationCard
          key={index}
          date={data.sent_at}
          message={data.message}
          timeZone={timeZone}
        />
      );
    } else if (data.notification_type === 'Activity') {
      return (
        <ActivityCard
          key={index}
          date={data.sent_at}
          message={data.message}
          timeZone={timeZone}
        />
      );
    } else if (data.notification_type === 'Report') {
      return (
        <ReportCard
          key={index}
          date={data.sent_at}
          message={data.message}
          timeZone={timeZone}
        />
      );
    }
    return <Box />;
  };

  const renderNoNotificationData = () => {
    return <NoNotificationData selectedFilter={selectedFilter} />;
  };

  return (
    <RightSideDrawer open={isDrawerOpen} onClose={handleClose}>
      <Box
        sx={{
          width: '400px',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}>
        <Box sx={{ padding: '16px', height: 110 }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}>
            <Text variant="bodySemibold">{t('notificationHistory.title')}</Text>
            <IconButton color="inherit" edge="start" onClick={handleClose}>
              <CloseIcon
                height="12px"
                width="12px"
                style={{
                  position: 'relative',
                  flexShrink: '0',
                }}
              />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 1,
              justifyContent: 'flex-start',
              marginTop: 2,
            }}>
            {filters.map((filter, index) => (
              <Chip
                selected={isEqual(selectedFilter, filter)}
                label={filter.label}
                key={index}
                onClick={() => handleFilterChange(filter)}
                sx={{ cursor: 'pointer' }}
              />
            ))}
          </Box>
        </Box>
        <InfiniteList
          height={'calc(100vh - 110px)'}
          data={notificationHistoryData}
          renderItem={renderCard}
          onEndReached={onEndReached}
          hasMore={hasMore}
          isLoading={isNotificationHistoryLoading}
          noDataComponent={renderNoNotificationData}
        />
      </Box>
    </RightSideDrawer>
  );
};
export default NotificationHistory;
