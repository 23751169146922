import { default as useNextSWRMutation } from 'swr/mutation';
import { swrFetcher } from '@/lib/network/networkCall';
import { HttpMethods, SWRMutationConfig } from '@/lib/network/types';
import { BadAuthentication, TooManyRequests } from '@/lib/network/errors';
import { useAuthContext } from '@/lib/contexts';
import { SignOutTypes } from '@/lib/contexts/AuthContext/types';
import toast from 'react-hot-toast';
import { translate } from '../transalation';

const getUrlWithPathParams = (
  requestUrl: string,
  args: unknown,
  getPathParams?: (...args: unknown[]) => Record<string, unknown>
) => {
  let url = requestUrl;
  if (getPathParams) {
    url = Object.keys(getPathParams && getPathParams(args)).reduce(
      (_url, key: string) => {
        const value: string =
          (getPathParams && (getPathParams(args)[key] as string)) ?? '';
        _url = _url.replace(key, value);
        return _url;
      },
      url
    );
  }
  return url;
};

const getQueryParams = (
  args: unknown,
  getQueryParams?: (...args: unknown[]) => Record<string, unknown>
) => {
  let nonEmptyParams = '';
  if (getQueryParams) {
    const params = getQueryParams(args);
    nonEmptyParams = Object.keys(params)
      .reduce((res, key: string) => {
        if (
          key in params &&
          params[key] !== undefined &&
          params[key] !== null &&
          params[key] !== ''
        ) {
          if (Array.isArray(params[key])) {
            (params[key] as string[]).forEach((value: string) =>
              res.append(key, value)
            );
          } else {
            res.append(key, params[key] as string);
          }
        }
        return res;
      }, new URLSearchParams())
      ?.toString();
  }
  return nonEmptyParams;
};

export const useAuthenticatedSWRMutation = (config: SWRMutationConfig) => {
  const { signOut } = useAuthContext();
  const handleLogout = () => {
    signOut(SignOutTypes.BAD_AUTH_API_ERR);
  };

  let requestUrl: string = config.url;
  let requestBody;
  const fetcher = (args: unknown) => {
    //#region form request body and URL
    requestUrl = getUrlWithPathParams(config.url, args, config.getPathParams);
    const queryParams = getQueryParams(args, config.getQueryParams);
    requestUrl += queryParams ? `?${queryParams}` : '';
    requestBody =
      config.method === HttpMethods.GET
        ? undefined
        : config.getData
        ? config.getData(args) instanceof FormData
          ? config.getData(args)
          : JSON.stringify(config.getData(args))
        : '';
    //#endregion

    return swrFetcher(
      {
        url: requestUrl,
        method: config.method,
        body: requestBody,
      },
      { ...config, isAuthenticated: true }
    );
  };

  return useNextSWRMutation(
    requestUrl,
    (url, { arg }) => {
      return fetcher(arg);
    },
    {
      ...config,
      onError: (e) => {
        if (e instanceof BadAuthentication) {
          handleLogout();
        } else if (e instanceof TooManyRequests) {
          toast.error(translate('tooManyRequests'));
        } else if (config?.onError) {
          config?.onError(e);
        } else {
          toast.error(`Something went wrong! Please try again after sometime.`);
        }
      },
    }
  );
};

export const useSWRMutation = (config: SWRMutationConfig) => {
  const fetcher = (args: unknown) =>
    swrFetcher(
      {
        url: config.url,
        method: config.method,
        body:
          config.method === HttpMethods.GET
            ? undefined
            : config.getData
            ? JSON.stringify(config.getData(args))
            : '',
      },
      { ...config, isAuthenticated: false }
    );

  return useNextSWRMutation(
    config.url,
    (url, { arg }) => {
      return fetcher(arg);
    },
    {
      ...config,
      onError: (e) => {
        if (e instanceof TooManyRequests) {
          toast.error(translate('tooManyRequests'));
        } else if (config?.onError) {
          config.onError(e);
        } else {
          toast.error(`Something went wrong! Please try again after sometime.`);
        }
      },
      throwOnError: false,
    }
  );
};
