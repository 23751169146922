import { datadogRum } from '@datadog/browser-rum';
import {
  BaseMonitoringInterface,
  DatadogConfig,
  MonitoringUserProperties,
} from '../MonitoringService/types';
import { serviceNames } from '@/lib/monitoring/MonitoringService/constants';

export class DatadogMonitoringService implements BaseMonitoringInterface {
  name: string;

  constructor(config: DatadogConfig) {
    this.init(config);
    this.name = serviceNames.datadog;
  }

  init(config: DatadogConfig) {
    datadogRum.init(config);
  }

  setUser(userId: string, userProperties?: MonitoringUserProperties): void {
    datadogRum.setUser({
      id: userId,
      name: userProperties?.userName,
    });
  }

  clearUser() {
    datadogRum.clearUser();
  }
}
