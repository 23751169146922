import { Box, Text } from '@/lib/components';
import { useTheme } from '@mui/material';
import Image from 'next/image';
import { Filter } from '../types';

type NoNotificationDataProps = {
  selectedFilter: Filter;
};

export const NoNotificationData: React.FC<NoNotificationDataProps> = ({
  selectedFilter,
}) => {
  const theme = useTheme();
  const Icon = selectedFilter.icon;
  return (
    <Box
      display="flex"
      alignItems="center"
      marginTop="25%"
      height="100%"
      flexDirection="column"
      gap={theme.spacing(2.625)}>
      <Image src={Icon} alt="No Data" width={144} height={144} />
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        gap={theme.spacing(1)}>
        <Text variant="h4Bold">{selectedFilter.noDataTitle}</Text>
        <Text
          variant="captionRegular"
          mx={theme.spacing(4)}
          textAlign="center"
          sx={{ color: theme.baseTheme.palette.typography.neutrals600 }}>
          {selectedFilter.noDataSubtitle}
        </Text>
      </Box>
    </Box>
  );
};
