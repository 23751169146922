import { CampaignRoutes } from '@/pages/routes/routes';

export const CampaignTabsConfig = [
  {
    index: 0,
    label: 'All Campaigns',
    route: CampaignRoutes.CAMPAIGNS,
  },
  {
    index: 1,
    label: 'Leads',
    route: CampaignRoutes.LEADS,
  },
  {
    index: 2,
    label: 'Import History',
    route: CampaignRoutes.IMPORT_HISTORY,
  },
];
