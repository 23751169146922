import { useMediaQuery, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import { useSidebarContext } from '../Sidebar/SidebarContext';
import { AppBar } from '@/lib/components';

export const TopBar: React.FC<PropsWithChildren & { showSidebar: boolean }> = ({
  children,
  showSidebar,
}) => {
  const { sidebarWidth } = useSidebarContext();
  const theme = useTheme();

  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <AppBar
      position="fixed"
      sx={{
        width:
          matchUpSm && showSidebar ? `calc(100vw - ${sidebarWidth}px)` : '100%',
        height: theme.baseTheme.defaultValues.APPBAR_HEIGHT,
      }}>
      {children}
    </AppBar>
  );
};
