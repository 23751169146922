import Script from 'next/script';

type GoogleAnalyticsProps = {
  measurementId: string;
  enable: boolean;
};

export const GoogleAnalytics = ({
  measurementId,
  enable,
}: GoogleAnalyticsProps) => {
  return (
    <>
      {enable ? (
        <>
          {/* Google tag (gtag.js) */}
          <Script
            strategy="afterInteractive"
            src={`https://www.googletagmanager.com/gtag/js?id=${measurementId}`}
          />

          <Script id="google-analytics" strategy="afterInteractive">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${measurementId}');
        `}
          </Script>
        </>
      ) : null}
    </>
  );
};
