import { Box } from '@/lib/components';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { CampaignTabsConfig } from './constants';
import { CampaignRoutes } from '@/pages/routes/routes';
import { StyledTab, StyledTabs } from '@/lib/components/core/StyledTabs';
import { AnalyticsService } from '@/lib/analytics';

export const CampaignTabs = () => {
  const { route, push } = useRouter();

  const calculateInitialTabValue = useCallback((newRoute: string) => {
    if (newRoute === CampaignRoutes.LEAD_JOURNEY) {
      return 1;
    }
    const routeConfig = CampaignTabsConfig.find((o) => o.route === newRoute);
    return routeConfig?.index;
  }, []);

  const [value, setValue] = useState(calculateInitialTabValue(route) || 0);

  useEffect(() => {
    setValue(calculateInitialTabValue(route) || 0);
  }, [route, calculateInitialTabValue]);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    const updatedValue = newValue === 1 ? value : newValue;
    setValue(updatedValue);
    const routeConfig = CampaignTabsConfig.find((o) => o.index === newValue);

    AnalyticsService.trackEvent(`User clicks on ${routeConfig?.label} tab`, {
      actor: 'User',
      verb: 'clicks',
      direct_object: `on ${routeConfig?.label} tab`,
      context: 'in the navbar',
      context_metadata: 'of customer dashboard',
    });

    if (routeConfig?.route) {
      push(routeConfig.route);
    }
  };

  return (
    <Box sx={{ paddingY: '4px' }}>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example">
          {CampaignTabsConfig &&
            CampaignTabsConfig.length &&
            CampaignTabsConfig.map((o, key) => (
              <StyledTab key={key} label={o.label} />
            ))}
        </StyledTabs>
      </Box>
    </Box>
  );
};
