import { Box, TableTab } from '@/lib/components';
import { TabVariant } from '@/lib/components/core/Tabs/TableTab';
import {
  IntelAssistTabLabels,
  IntelAssistTabOptions,
} from '@/pages/intel-assist/constants';
import { useIntelAssistTabsStore } from '@/pages/intel-assist/store';
import { SyntheticEvent } from 'react';

export const IntelAssistHeader = () => {
  const { selectedTab, setSelectedTab } = useIntelAssistTabsStore();

  const handleTabChange = (
    event: SyntheticEvent<Element, Event>,
    newValue: IntelAssistTabLabels
  ) => {
    const newTab = IntelAssistTabOptions.find((tab) => tab.value === newValue);
    if (newTab) setSelectedTab(newTab);
  };
  return (
    <Box
      sx={{
        marginTop: 2,
        paddingX: 1,
      }}>
      <TableTab
        curTab={selectedTab}
        tabs={IntelAssistTabOptions}
        handleChange={handleTabChange}
        variant={TabVariant.secondary}
      />
    </Box>
  );
};
