export const en_auth = {
  sso: {
    callback: {
      loading:
        'Please wait a moment while we are authenticating your request...',
      success: 'Welcome to the platform! Redirecting you to the dashboard...',
      failure: 'Failed to Authenticate your request!',

      dashboard: 'Go to Dashboard',
      login: 'Login',
    },
  },
};
