import { Drawer } from '@mui/material';
import React from 'react';

interface IRightSideDrawer {
  children: React.ReactNode;
  open: boolean;
  onClose: () => void;
}

export const RightSideDrawer: React.FC<IRightSideDrawer> = ({
  children,
  open,
  onClose,
}) => {
  return (
    <Drawer anchor={'right'} open={open} onClose={onClose}>
      {children}
    </Drawer>
  );
};
