import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { PropsWithChildren } from 'react';
import { Box, Fab, Drawer, List } from '@/lib/components';

import { CollapseIcon } from '@/lib/icons';
import { LogoSection } from './LogoSection';
import { useSidebarContext } from './SidebarContext';

const Sidebar = ({ children }: PropsWithChildren) => {
  const theme = useTheme();
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const {
    slimProfile,
    toggleSlimProfile,
    sidebarWidth,
    sidebarOpened: open,
    handleSidebarToggle: onToggle,
  } = useSidebarContext();

  const handleSidebarToggle = () => {
    if (!matchUpSm) {
      onToggle();
    } else {
      toggleSlimProfile();
    }
  };

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { sm: 0 },
        width: matchUpSm ? sidebarWidth : 'auto',
      }}>
      <Drawer
        variant={matchUpSm ? 'persistent' : 'temporary'}
        anchor="left"
        open={matchUpSm ? true : open}
        onClose={handleSidebarToggle}
        sx={{
          '& .MuiDrawer-paper': {
            width: sidebarWidth,
            background: theme.baseTheme.palette.surfaces.sidebarBackground,
            color: theme.baseTheme.palette.typography.contrastTextColor,
            overflow: 'visible',
          },
        }}
        ModalProps={{ keepMounted: true }}>
        <Box sx={{ display: 'block' }}>
          <Box sx={{ display: 'flex', p: 2, mx: 'auto', my: 'auto' }}>
            <LogoSection />
            <Fab
              onClick={handleSidebarToggle}
              sx={{
                position: 'absolute',
                minHeight: 24,
                height: 24,
                width: 24,
                my: 1.25,
                right: -10,
                transition: 'transform 0.3s',
                transform: slimProfile ? 'rotate(180deg)' : '',
                backgroundColor:
                  theme.baseTheme.palette.surfaces.surfaceBackground,
              }}>
              <CollapseIcon />
            </Fab>
          </Box>
        </Box>
        <List disablePadding sx={{ height: '100%' }}>
          {children}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
