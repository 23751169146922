import { FunctionComponent } from 'react';
import { CircularLoaderProps } from './type';
import { CircularProgress } from '@mui/material';

export const CircularLoader: FunctionComponent<CircularLoaderProps> = (
  props
) => {
  const { size, thickness, disableShrink, sx, ...resProps } = props;

  return (
    <CircularProgress
      size={size}
      thickness={thickness}
      disableShrink={disableShrink}
      sx={sx}
      {...resProps}
    />
  );
};

CircularLoader.defaultProps = {
  size: 40,
  thickness: 3.6,
  disableShrink: false,
  sx: {},
};
