export const setCustomMicrosoftClarityTags = (
  userType: string,
  userId: number
) => {
  const scriptAlreadyPresent = document.getElementById(
    'microsoft-clarity-custom-tags'
  );

  if (!scriptAlreadyPresent) {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('id', 'microsoft-clarity-custom-tags');
    script.setAttribute('defer', '');
    const code = `
        window.clarity('identify', '${userId}');
        window.clarity('set', 'user_type', '${userType}');
        window.clarity('set', 'user_id', '${userId}');
        `;
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);
  }
};

export const removeCustomMicrosoftClarityTags = () => {
  const script = document.getElementById('microsoft-clarity-custom-tags');
  if (script) {
    script.remove();
  }
};
