import { CircularProgress } from '@mui/material';
import { SxProps } from '@mui/system';

interface Props {
  color?: 'inherit' | 'primary' | 'secondary' | 'error' | 'warning';
  size?: number;
  thickness?: number;
  disableShrink?: boolean;
  sx?: SxProps;
}

export const Loader: React.FC<Props> = ({ color, size, sx = {} }) => {
  return <CircularProgress size={size} color={color} sx={{ ...sx }} />;
};

Loader.defaultProps = {
  size: 40,
  thickness: 3.6,
  disableShrink: false,
  color: 'primary',
};
