import { ButtonBase, Chip as MuiChip } from '@mui/material';
import React from 'react';
import { SxProps } from '@mui/system';

export type ChipProps = {
  selected?: boolean;
  label: string;
  onClick?: () => void;
  variant?: 'default' | 'selected' | 'error' | 'success' | 'warning';
  sx?: SxProps;
  onDelete?: () => void;
  deleteIcon?: React.ReactElement;
};

export const Chip = ({
  selected = false,
  label,
  onClick,
  variant = 'default',
  sx = {},
  onDelete,
  ...props
}: ChipProps) => {
  const muiVariant = selected ? 'selected' : variant;

  return (
    <ButtonBase onClick={onClick}>
      <MuiChip
        {...props}
        label={label}
        variant={muiVariant}
        sx={{ ...sx }}
        onDelete={onDelete}
        clickable={!!onClick}
      />
    </ButtonBase>
  );
};
