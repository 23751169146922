import { en_auth } from './en_pages';

const en = {
  translation: {
    welcome: 'Welcome here',
    somethingWentWrong: 'Something went wrong',
    pleaseTryAgain: 'Please try again after sometime.',
    tooManyRequests:
      'You have made too many requests in a short period, please try again after sometime!',
    confirm: 'Confirm Action',
    infiniteList: {
      defaultNoDataComponent: 'No Data Available',
    },
    user: 'User',
    copyright: '©️ {{year}} SquadStack',
    rupeeSymbol: '₹',
    dollarSymbol: '$',
    notAllowedToAccessPage: 'You are not allowed to access this page',
    audioPlayer: {
      playbackSpeed: 'Playback Speed',
      speedpoint25x: '0.25x',
      speedpoint5x: '0.5x',
      speedpoint75x: '0.75x',
      speed1x: '1x',
      speed1point25x: '1.25x',
      speed1point5x: '1.5x',
      speed1point75x: '1.75x',
      speed2x: '2x',
    },
    multiSelectFilter: {
      selected: '{{noOfOptions}} Selected',
    },
    selectBusiness: {
      businessSelectionHeading:
        'We have found {{businessesLength}} businesses to join from your organization',
      accessRequestedHeading:
        'Your request to join {{businessName}} business is pending on admin',
      accessRequestedSubHeading: 'Please contact your admin for approval',
      accessRejectedHeading:
        'Your request to join {{businessName}} business has been rejected by the admin.',
      accessRejectedSubHeading: 'Please connect with the admin for details.',
      requestAccess: 'Request Access',
      memberCount: '{{memberCount}} {{memberLabel}}',
      supportMsg:
        'If you face any issue, please contact at support@squadstack.com',
      mainAPIErrorMsg:
        'Something went wrong . Please try refreshing the page or contact at support@squadstack.com',
      createNewBusiness: {
        newBusinesstitle: 'Create a New Business',
        businessNameLabel: 'Business Name',
        textFieldPlaceHolder: 'Type in your business name...',
        createButton: 'Create',
        cancelButton: 'Cancel',
        createYourOwnBusiness: 'Create your own Business',
      },
    },
    campaigns: {
      allCampaigns: {
        toasts: {
          filtersApplied: 'Filters applied successfully',
          filtersReset: 'Filters reset successfully',
          campaignStatusUpdatedSuccessfully:
            'Campaign status updated successfully',
          campaignUnderReview: 'Campaign Under Review',
        },
        campaignStatus: {
          all: 'All',
          drafting: 'Drafting',
          published: 'Published',
          paused: 'Paused',
          underReview: 'Under Review',
          template: 'Template',
          underSetup: 'Under Setup',
          completed: 'Completed',
          custom: 'Custom',
        },
        integrationStatusOption: {
          all: 'All',
          integrated: 'Integrated',
          notIntegrated: 'Not Integrated',
        },
        campaignCardMenu: {
          editCampaign: 'Edit Campaign',
          view: 'View Leads',
          import: 'Import',
          analytics: 'Analytics',
          pause: 'Pause',
          resume: 'Resume',
          viewScript: 'View Script',
          viewFAQs: 'View FAQs',
        },
        newCampaignButton: {
          title: 'New Campaign',
        },
        noCampaignsFound: {
          title: 'No Campaigns Found',
          subTitle: 'Try changing the filters for your desired campaign info',
          somethingWentWrong:
            'Something went wrong. Please refresh the page or try again later.',
        },
        campaignCard: {
          campaignId: 'Campaign ID: ',
          sampleCallRecording: 'Sample Call Recording',
          leadJourneyReportNotAvailable:
            'Lead Journey Report Sample Not Available',
          importData: 'Import data',
          analytics: 'Analytics',
          leadJourneyReport: 'Lead Journey Report',
          leadProcessed: 'Leads Processed',
          conversion: 'Conversion',
          qualityScore: 'Quality Score',
          useTemplateButton: 'Use Template',
          useCase: 'Use Case',
          campaignObjective: 'Select Campaign Objective',
          campaignUseCase: 'Select Campaign Use Case',
          crmId: 'CRM ID: ',
          copied: 'Copied',
          clickToCopy: 'Click to copy',
        },
      },
      createCampaign: {
        title: 'Create Campaign',
        saveCloseButtonLabel: 'Save & Close',
        campaignInfoSavedSuccessfully: 'Campaign info saved successfully',
        scriptCreationError:
          'Something went wrong while generating script. Please try again in some time or reachout to support.',
        faqCreationError:
          'Something went wrong while generating FAQs. Please try again in some time or reachout to support.',
        generatingScript: 'Generating script…',
        scriptGeneratedSuccessfully: 'Script generated successfully.',
        generatingFaq: 'Generating FAQs…',
        faqGeneratedSuccessfully: 'FAQs generated successfully.',
        cloningCampaign: 'Sit tight! We are setting up your campaign! 🚀',
        navigatorTabs: {
          callerSetup: 'Caller setup',
          scriptSetup: 'Script setup',
          faqSetup: 'FAQ setup',
          contractReview: 'Contract Review',
          continueButton: 'Continue',
          submitForReviewButton: 'Submit for Review',
          submitForReviewButtonSME: 'Checkout',
          makePayment: 'Complete Purchase',
          modifyButton: 'Modify',
          review: 'Review',
          generateScript: 'Generate Script',
          goBackToDashboard: 'Go Back To Dashboard',
          approveScript: 'Approve Script',
          resources: 'Resources',
          unsavedQuestions: '{{unsavedQuestionCount}} Unsaved Questions',
          disabledBtnTooltipText:
            'Missing company information! Please validate the script and save all variables to proceed',
        },
        callerSetupTab: {
          heading: 'Campaign Setup',
        },
        scriptSetupTab: {
          heading: 'Script Setup',
          question: 'Question',
          L3AndAboveCaseInfo:
            'No Script necessary. For the use case selected, we cater it by training agents on pitch. Continue to estimate invoice & submit for review for our training teams to get in touch with you.',
        },
        gDocEmbed: {
          copyLink: 'Copy Link',
          edit: 'Edit ',
          copied: 'Copied',
          fallbackTitle: ' document with sample ',
          updated: 'updated',
        },
        faqSetupTab: {
          heading: 'FAQ Setup',
          header: 'FAQs',
          addNewFaq: 'Add New FAQ',
          noDataMessage:
            'No FAQs added yet, press the Add New FAQ button to add one',
          noDataMessageViewOnlyMode:
            'No FAQs added during campaign creation journey',
          faqQuestionPlaceholder: 'Enter question here',
          faqAnswerLabel: 'Answer',
          faqAnswerPlaceholder:
            'Enter text. You can use the toolbar to format your text and more.',
          faqSaved: 'FAQ saved successfully.',
          faqSaveFailed: 'Failed to save FAQ. Please try again.',
          faqDeleted: 'FAQ deleted successfully.',
          faqDeleteFailed: 'Failed to delete FAQ. Please try again.',
          faqSETrainingRequired: 'Should caller be trained on this FAQ',
        },
        modifyCampaignModal: {
          heading: 'Modify Campaign',
          description:
            'Do you want to change your caller setup details? All changes in script creation will be lost.',
          secondaryButtonText: 'Cancel',
          primaryButtonText: 'Yes, Proceed',
        },
      },
      editCampaign: {
        title: 'Edit Campaign',
      },
      viewCampaignScript: {
        title: 'Campaign Script',
        forbiddenAccessMessage: 'You are not authorized to access this page',
        inReview: 'In Review',
        accepted: 'Accepted',
        unsavedQuestions: 'Unsaved Questions',
        validateButtonText: 'Validate',
        continueButtonText: 'Continue',
        modifyButtonText: 'Modify',
      },
      viewCampaignFAQs: {
        title: 'Campaign FAQs',
      },
      importHistory: {
        nullStateText: 'No Import History',
        leadId: 'Lead ID: {{leadId}}',
        table: {
          id: 'Id',
          title: 'Title',
          campaign: 'Campaign',
          status: 'Status',
          actions: 'Actions',
          comments: 'Comments',
          remarks: 'Remarks',
          viewComments: 'View Comments',
          viewRemarks: 'View Remarks',
          type: 'Type',
          download: 'Download',
          uploadedOn: 'Updated on',
          downloadLeads: 'Download Leads',
          downloadFailedImports: 'Download Failed Imports',
          internalUser: 'Internal users have no access to download the reports',
        },
      },
      workflow: {
        workflowSetup: 'Workflow Setup',
        workflowName: 'Workflow Name',
        selectWorkflowTemplate: 'Select Workflow Template',
        cloneExistingWorkflow: 'Clone from Existing Workflow',
        workflowNameRequired: 'Workflow Name Required',
        workflowTemplateCard: {
          channels: 'Channels',
          select: 'Select',
          selectEdit: 'Select & Edit',
        },
        availableBlocks: 'Available Blocks',
        sms: 'SMS',
        call: 'Call',
        ivr: 'IVR',
        whatsapp: 'WhatsApp',
        outcome: 'Outcome',
        webhook: 'Webhook',
        email: 'Email',
        internal_leads_operations: 'Internal Leads Operations',
        delayMinutes: 'Delay(mins.) to start executing this block',
        type: 'Type',
        senderId: 'Sender ID',
        contentTemplateId: 'Content Template ID',
        dltId: 'DLT Entity ID',
        textMessage: 'Text Message',
        importDelayFromLeadData: 'Import Delay from template variable',
        jumpTo: 'Jump to',
        addCondition: 'Add Condition',
        add: 'Add',
        condition: 'Condition {{index}}',
        insertVariable: 'Insert campaign template variable',
        noOfFollowUps: 'Number of Follow-Ups',
        followUpType: 'Follow-up Type',
        delayBetweenFollowUps: 'Delay Between Follow-Ups (mins.)',
        variableDelayBetweenFollowUps:
          'Delay Between {{followUpNumber}} Follow-Up (mins.)',
        voiceMissionName: 'Voice Mission Name',
        ivrScript: 'IVR Script',
        ivrAppId: 'IVR App ID',
        multiLevelIvr: 'Multi level IVR',
        internalTitle: 'Internal Title',
        skipOnFailMiss: 'Skip on fail miss',
        validKeyPress: 'Valid Key Press',
        textToSpeak: '{{key}} text to Speak',
        headers: 'Headers',
        payloadTemplate: 'Payload Template',
        endpoint: 'Endpoint',
        delete: 'Delete',
        cancel: 'Cancel',
        discard: 'Discard',
        save: 'Save',
        from: 'From',
        to: 'To',
        cc: 'CC',
        subject: 'Subject',
        body: 'Body',
        deleteBlockModal: 'Are you sure you want to delete this block?',
        discardBlockModal:
          'You have unsaved changes. Are you sure you want to discard them?',
        errorMessages: 'Error Message(s)',
        status: 'Status',
        publishWorkflow: 'Publish Workflow',
        authenticateEmail: 'Authenticate Email',
        workflowPublishedSuccessfully: 'Workflow published successfully',
        addSenderId: 'Add Sender ID',
        markSenderIdAsDefault: 'Mark Sender ID as Default',
        addIvrAppId: 'Add IVR App ID',
        changesSavedSuccessfully: 'Changes saved successfully',
        defaultWebhookEndpointValue: 'https://www.squadvoice.in/',
        selectWorkflowFromExistingCampaign:
          'Select workflow from existing campaigns',
        addKeyPressText: 'Add Key Press Text',
        setupVm: 'Setup VM',
        vmHelperText:
          'Please refresh the page if you have created the VM recently and it does not appear in the drop down',
        blockClonedSuccessfully: 'Block cloned successfully',
        saveBlockModal:
          'This workflow is in {{state}} state. Are you sure you want to make changes?',
        campaignBusinessName:
          'Campaign - {{campaignName}} | Business - {{businessName}}',
        campaignWorkflowName:
          'Campaign - {{campaignName}} | Workflow - {{workflowName}}',
        selectTemplate: 'Select Template',
        search: 'Search',
        extra_data: 'Extra Data',
        select_campaign: 'Select Campaign',
        operation_type: 'Operation Type',
        select_operation_type: 'Select Operation Type',
        paste_json_here: 'Paste your JSON here...',
        leadSkipDailyCap: 'Lead Skip Daily Cap',
        enterLeadSkipDailyCap: 'Enter lead skip daily cap',
        leadSkipPercentage: 'Lead Skip Percentage',
        enterLeadSkipPercentage: 'Enter lead skip percentage',
      },
      addCampaignName: {
        firstCampaigntitle: 'Create your first Campaign',
        newCampaigntitle: 'Create a New Campaign',
        campaignNameLabel: 'Campaign Name',
        textFieldPlaceHolder: 'Type in your campaign name...',
        createButton: 'Create',
        errorMessage: 'name already exists.',
      },
      addCustomScript: {
        newCustomScriptTitle: 'Create a Custom Calling Script Campaign',
        campaignNameLabel: 'Campaign Name',
        textFieldPlaceHolder: 'Type in your campaign name...',
        createButton: 'Use Custom Script',
        errorMessage: 'name already exists.',
      },
      useCaseSelectionModal: {
        leadAutomationEnabledTemplatesHeading:
          'Select your telecalling objective',
        leadAutomationEnabledTemplatesSubheading:
          'Use cases tailor-made for your business needs',
        leadAutomationEnabledTemplatesDisclaimer:
          'Lead sharing from Shopify are enabled for these use cases',
        leadAutomationDisabledTemplatesHeading: 'Other',
        leadAutomationDisabledTemplatesSubheading:
          'Explore the complete capability of our solution',
        leadAutomationDisabledTemplatesDisclaimer:
          'Note: Leads will have to be imported or automated using our APIs',
        selectUseCaseBtn: 'Select Use Case',
      },
      sme: {
        navigatorTabs: {
          campaignSetup: 'Campaign Setup',
          saveAndContinue: 'Save & Continue',
          scriptPreview: '{{scriptPreview}}',
          reviewAndSubmit: 'Review',
          checkout: 'Checkout',
          continue: 'Continue',
        },
        selectOption: 'Select Option',
        toasts: {
          scriptApproved: 'Script is Approved Successfully',
        },
        callerSetupTab: {
          subHeading:
            "Help us understand the tele-calling campaign you are looking to setup using SquadStack's Plug-&-Play solution.",
          objectiveLabel: 'Select the objective of your telecalling campaign',
          callingTypeLabel:
            'Select the option that best describes the nature of your telecalling campaign?',
          outcomeLabel:
            'What is the final action to be taken on calls for interested leads?',
          preferredLanguageLabel:
            'Select the preferred language for your campaign',
          leadSourceLabel: 'Please share your lead source',
        },
        scriptSetupTab: {
          subHeading:
            'Answer a few questions for our script generator to curate the tele-calling script for your campaign. Click here to view our script templates.',
          uploadScriptHeading:
            "Already have a telecalling script? Share it with us and let our script generator do it's magic",
          companyDescHeading: 'About your company & products/services',
          companyDescSubheading:
            'Please add a brief introduction about your company and list down the products/services the leads need to be informed about during the call',
          promotionHeading:
            'Are there any promotions or discounts you want to share with your leads?',
          promotionSubheading:
            'Please add all promotions/discounts/offers you want to share with your leads on the call. If there are none, answer with NA.',
          questionHeader:
            'What questions need to be asked of your leads during the call?',
          questionSubheading:
            'Please list out all the questions you want to be asked on the call.',
          questionRequirement1: 'Minimum number of questions needed: 1',
          questionRequirement2: 'Maximum number of questions allowed: 5',
          questionExample:
            'Example: 1) How many hours of content do you stream per day? 2) What genre of content do you watch the most? 3) Would you like to subscribe to Netflix?',
          objectiveLabel: 'Select Campaign Objective',
          globalVariablesHeaderLabel: 'VARIABLES OVERVIEW',
          registeredBusinessNameLabel: 'Registered Business Name',
          registeredBusinessNameInfo: 'Enter Registered Business Name',
          brandNameLabel: 'Brand Name',
          brandNameInfo: 'Enter Brand Name',
          supportNumberLabel: 'Support Number',
          supportNumberInfo: 'Enter Support Number',
          supportEmailLabel: 'Support Email',
          supportEmailInfo: 'Enter Support Email Address',
          globalVariablesUpdateSuccess:
            'Script Variables Updated Successfully!',
          globalVariablesNotFilledError: 'Please fill all script variables!',
        },
        scriptPreview: {
          heading: '{{scriptPreviewHeading}}',
          scriptPreviewCustomCampaignHeading: 'Script Builder',
          scriptPreviewTemplateCampaignHeading: 'Script Editor',
          scriptGenerationError:
            'Your requirement is under review and script will be generated shortly.',
        },
        reviewTab: {
          header: 'Additional Information',
          view: {
            supportEmailLabel: 'Support Email: ',
            supportNumberLabel: 'Support Number: ',
            refMaterialLabel: 'Marketing / Sales Reference Material: ',
            faqDocLabel: 'FAQ Document: ',
          },
          edit: {
            supportEmailLabel: "Share your organization's support email",
            supportEmailInfo: "Add your org's support email address",
            supportNumberLabel: "Share your organization's support number",
            supportNumberInfo: "Add your org's support number",
            supportEmailLabelSME: 'Support Email ID',
            supportEmailInfoSME: 'Enter Support Email address',
            supportNumberLabelSME: 'Support Phone Number',
            supportNumberInfoSME: 'Enter Support Phone Number',
            refMaterialLabel:
              'Upload any marketing/sales collateral our sales experts can use for reference on the call',
            refMaterialInfo:
              'The marketing/sales collateral will be used in the training program created for our telecallers to set more context about your company and/or products/services.',
            faqDocLabel: 'Upload FAQ document for your telecalling campaign',
            faqDocInfo1:
              'The FAQ document should consist of at max 8 most common questions you expect your leads to ask.',
            faqDocInfo2:
              'DISCLAIMER: The inputs are added into an automated system; adding more will lead to errors in script design',
          },
        },
      },
    },
    notificationHistory: {
      title: 'Notifications',
      filterTypes: {
        all: {
          label: 'All',
          noDataTitle: 'No Notifications',
          noDataSubTitle: 'You currently have no notifications',
        },
        notifications: {
          label: 'Notification',
          noDataTitle: 'No Notifications',
          noDataSubTitle: 'You currently have no notifications',
        },
        activity: {
          label: 'Activity',
          noDataTitle: 'No Activity',
          noDataSubTitle: 'You currently have no activity generated',
        },
        report: {
          label: 'Report',
          noDataTitle: 'No Reports',
          noDataSubTitle: 'You currently have no reports generated',
        },
      },
      notificationCard: {
        title: 'Action Required',
      },
      activityCard: {
        title: 'Activity',
      },
      reportCard: {
        title: 'Report',
        download: 'Download',
      },
    },
    auth: {
      ...en_auth,

      signup: {
        hello: 'Hello',
        headerText: 'Set up your SquadStack Account',
        industry: 'Industry',
        workEmail: 'Work email',
        firstName: 'First name',
        lastName: 'Last name',
        mobile: 'Mobile Number',
        companyName: 'Company Name',
        setPassword: 'Set password',
        confirmPassword: 'Confirm password',
        submitBtn: 'Sign Up',
        tnc: 'By continuing, you are consenting to sign-up and agree to our terms and conditions and privacy policy',
        successMsg: 'Successfully created your account!',
        passwordReq: {
          title: 'Password must have :',
          upperCase: 'At least one uppercase letter',
          digit: 'At least one digit',
          specialChar:
            'At least one of the special characters from @  \\  -  _  $  or #',
          minLength: 'A minimum length of 8 characters',
        },
        errors: {
          firstNameRequired: 'Please enter your first name',
          lastNameRequired: 'Please enter your last name',
          mobileRequired: 'Please enter your valid mobile number',
          companyNameRequired: "Please enter your company's name",
          validEmail: 'Please enter your valid email',
          validPassword: "Password doesn't match the below requirements.",
          confirmPassword: 'Passwords must match',
          invitationLinkError: 'Invalid Invitation Link',
        },
      },
      login: {
        headerText: 'Login to SquadStack',
        workEmail: 'Work Email',
        workEmailAndPhoneNumber: 'Work Email or phone number (+91)',
        password: 'Password',
        otp: 'OTP',
        resendOtp: 'Resend OTP',
        forgotPassword: 'Forgot Password',
        submitBtn: 'Login',
        continueBtn: 'Continue',
        ssoContinueBtn: 'Continue with SSO login',
        editBtn: 'Edit',
        orDivider: 'or',
        loginWithOtpBtn: 'Login via OTP',
        loginWithPasswordBtn: 'Login via Password',
        incorrectCredentials: 'Please enter your valid credentials!',
        invalidOtp: 'Please enter a valid OTP',
        countryCode: '+91',
      },
      forgotPassword: {
        headerText: 'Forgot Password',
        workEmail: 'Work Email',
        submitBtn: 'Reset Password',
        bottomText: 'Remember your password? Login to your account.',
      },
      resetPassword: {
        headerText: 'Set New Password',
        workEmail: 'Work Email',
        setPassword: 'Set Password',
        confirmPassword: 'Confirm Password',
        submitBtn: 'Set Password',
      },
      sentLink: {
        headerText: 'Reset Link Sent',
        description: 'We’ve sent a link to reset your password on',
        submitBtn: 'Resend Password Reset Link',
        bottomText: 'Did not receive an email? chat with our support team',
      },
      verification: {
        headerText: 'Verify Your Email Address',
        description: 'We’ve sent a verification email to',
        submitBtn: 'Resend Verification Link',
        bottomText: 'Did not receive an email? chat with our support team',
      },
      verify: {
        headerText: 'Email Verification',
      },
    },
    leadUpload: {
      title: 'Import contacts for ',
      downloadSampleSheet: 'Download Sample Sheet',
      fileTitle: 'Title',
      comments: 'Comments',
      internalReview: 'Ask internal team to review',
      dragAndDrop: 'Drag & Drop your file',
      invalidFileType: 'Invalid file type. Only .csv format is accepted.',
      browseUpload: 'Browse to Upload',
      requiredColumnsTitle: 'Following columns are required in the sheet',
      uploadSuccessful: 'Lead uploaded successfully',
      upload: 'Upload',
    },
    callerSelection: {
      cefr: 'CEFR Certified',
      scriptAdherence: 'Script Adherence',
      conveyProductInfo: 'Convey Product Information',
      pitchHandling: 'Pitch & Objection Handling',
      numOfFaqs: '# of FAQs trained on',
      minCommitment: 'Minimum Commitment',
      monthlyBilling: 'Monthly Billing',
      numOfMins: '# of minutes',
      callerGoLive: 'Caller Go-Live',
      sampleCallRecording: 'Sample Call Recording:',
      selectCallerType: 'Select Caller Type',
      helpMeSelectTeleCaller: 'Help me select a tele-caller',
      tooltipCallerGoLive:
        'Caller Go Live includes training in script & FAQs, getting assessed & ensure callers are ready fo Live calls. This is followed by 1 - 2+ weeks of OJT depending on Caller type. On Job Training (OJT) is the feedback & training as required to achieve consistent Efficiency from Caller. ',
      tooltipCEFR:
        'The CEFR is an international standard for describing English language ability based on speaking, listening, understanding & writing',
      tooltipCEFRLevel1: 'Level C1-C2: Proficient Language ability',
      tooltipCEFRLevel2: 'Level B1-B2: Fluent Language ability',
      tooltipCEFRLevel3: 'Level A1-A2: Basic Language ability',
      bestFor: 'Best for use-cases with:',
      workingDay: '{{days}} working day',
      workingDays: '{{days}} working days',
      onJobTrainingDay: '{{days}} day of on-job training',
      onJobTrainingDays: '{{days}} days of on-job training',
    },
    pricing: {
      leadVolume: 'Lead Volume',
      pricingPackage: 'Pricing Package',
      twelveMonth: '12 Months',
      connectedMinutes: 'Connected Minutes (Monthly)',
      pricePerMinute: 'Price per min.',
      oneTimeSetupFee: 'One-time setup fee',
      subtotal: 'Sub-total',
      total: 'Total',
      gst: 'GST',
      discount: 'Discount',
      campaign: 'Campaign',
      campaigns: 'Campaigns',
      numOfEdits: 'No. of edits per month',
      freeEmailCredits: 'Free Email Credit(s)',
      freeSMSCredits: 'Free SMS Credit(s)',
      platform: 'Platform',
      campaignManagement: 'Campaign Management',
      qualityMonitoring: 'Quality Monitoring',
      analyticsDashboard: 'Analytics Dashboard',
      enterpriseSecurity: 'Enterprise-Grade Data Security',
      integration: 'Integration',
      support: 'Support',
      emailSupport: 'Email Support',
      onCallSupport: 'Priority On-Call Support',
      recurringMonth: 'Recurring Month',
      firstMonth: 'First Month',
      min: 'min.',
      rs: 'Rs',
      avgCallDuration: 'Average Call Duration (mins.)',
      minutes: 'minutes',
      noLeadVolumeError: 'Please enter lead volume',
      leadVolumeLessError: 'Lead volume should be greater than {{lowerbound}}',
      tooltipOneTimeProjectConnectedMinutes:
        'Connected minutes = Lead volume * {{connectivity}} (Avg {{connectivityPercentage}}% connectivity rate across our platform) * Average call duration',
      tooltipRecurringProjectConnectedMinutes:
        'Connected minutes = Lead volume/day * 30 days * {{connectivity}} (Avg {{connectivityPercentage}}% connectivity rate across our platform) * Average call duration',
      tooltipOneTimeSetupFee:
        "50% of the first month's billing will be utilized as one-time platform setup & caller training fee.",
      minConnectedMinutes:
        'You’ll need {{currentConnectedMinutes}} connected minutes per month as per the inputs shared above. But, the minimum pack for this caller is {{lowerLimit}} mins/month.',
      twelveMonthDiscount: 'Save {{discount}}%',
      quarterlyUpfront: 'Quarterly Upfront',
      monthlyUpfront: 'Monthly Upfront',
    },
    analytics: {
      title: 'Analytics',
      filters: {
        campaignLabel: 'CAMPAIGN',
        campaignPlaceholder: 'Choose Campaign',
        selectCampaignBtn: 'Update',
      },
      chooseCampaignMsg: 'Please select a campaign to view Analytics',
      switchToOldDashboard: 'Switch to old Dashboard',
      switchToNewDashboard: 'Switch to new Dashboard',
      switchToOldDashboardMessage:
        'Squadstack bringing a better Analytics Dashboard Central for you.',
      switchToNewDashboardMessage:
        'We would phase out this dashboard after your feedback.',
      liveAgentsStatus: {
        title: 'Live Agents Status',
        agentName: 'Agent Name',
        agentType: 'Agent Type',
        lastUpdatedAt: 'Last updated at ',
        noDataMsg: 'No agents doing {{type}} call',
        errMsg: 'Something went wrong, please try again later!',
        totalAgentsOnCall: 'Total agents On call: ',
        agentsDoingOutboundCall: 'Agents doing outbound call',
        agentsDoingInboundCall: 'Agents doing inbound call',
        noAgentsOnCall: 'No agents on call at this moment',
      },
    },
    viewLeads: {
      title: 'Leads',
      viewLeads: 'View Leads',
      filters: {
        campaignLabel: 'Campaign',
        campaignPlaceholder: 'Select Campaign',
        dateRangeLabel: 'Date Range',
        dateRangePlaceholder: 'Select Date Range',
        leadStatusLabel: 'Lead Status',
        leadStatusPlaceholder: 'Select Lead Status',
        finalOutcomeLabel: 'Final Outcome',
        searchTextLabel: 'Contact Name / Number / Lead ID',
        applyButtonLabel: 'Apply',
      },
      table: {
        title: 'All Leads',
        leadId: 'Lead ID',
        leadName: 'Lead Name',
        phoneNumber: 'Phone Number',
        campaign: 'Campaign',
        status: 'Status',
        finalOutcome: 'Final Outcome',
        bucketToOutcomeMappingTable: 'Bucket to Outcome Mapping Table',
        outcomes: 'Outcomes',
        bucket: 'Bucket',
        outcomeMappingTableInfoTooltip:
          'Click to see Outcome to Bucket Mapping',
        noLeadsFoundMessage: 'No leads Found',
        noLeadsFoundSubMessage:
          'Try expanding the date range for your desired lead info.',
        searchPlaceholder: 'Search by Contact Name / Number / Lead ID',
      },
      tableCTA: {
        addToBlacklistBtn: {
          label: 'Add to Blacklist',
          tooltip: 'Uploaded numbers will be added to the blacklist',
        },
        removeFromBlacklistBtn: {
          label: 'Remove from Blacklist',
          tooltip: 'Uploaded numbers will be removed from the blacklist',
        },
        showHistoryBtn: {
          label: 'Show History',
          tooltip:
            'Show history for both additions to & removals from blacklist',
        },
      },
      blackListModal: {
        tooltipMessage:
          'Copy and paste your list of numbers into the box below (comma separated and without gaps), then press "Enter" and then click " Add to Blacklist " button.',
        importantNote: 'Important Note:',
        importantNoteText:
          'Blacklisting of leads is non-reversible. You can remove numbers from your DNC blacklist anytime to add leads back to campaigns.',
        infoPoint1:
          'DNC Blacklist disables any leads with a pending reach-out from any channel in any campaigns under your business account.',
        infoPoint2:
          'It disables the future leads instantly at the time of creation in any campaigns under your business account.',
        infoPoint3:
          'The DNC blocked leads can be seen in the report of the campaign if it was disabled within the duration of the report you generate.',
        note: 'Note:',
        noteText: 'How to reach out to a blacklisted lead',
        noteTextPoint1: 'Remove the numbers from Blacklist here.',
        noteTextPoint2:
          'Reimport those leads to a campaign at least 24 hours after removing them from Blacklist.',
        label: 'Paste numbers here',
        confirmMsg:
          'This is a non-reversible action. Are you sure you want to continue?',
        errorMsg:
          'Please enter 10 digits comma separated numbers without space',
        placeholder: 'Paste your numbers here',
        validNumberError:
          'Please enter 10 digits comma separated numbers without space',
        count: 'Count:',
        cancel: 'Cancel',
        add: {
          heading: 'Add to Blacklist',
          subheading: 'Block reach-outs for better customer experience',
          button: 'Add to Blacklist',
          confirmBlacklistMsg:
            'This is a non-reversible action are you sure you want to continue?',
        },
        remove: {
          heading: 'Remove from Blacklist',
          subheading: 'Uploaded numbers will be removed from the blacklist',
          button: 'Remove from Blacklist',
        },
        formErrorMessages: {
          required: 'Please fill this detail.',
          mobileNumbersGroup:
            'Please Enter 10 digits comma separated numbers without space',
          mobileNumber: 'Enter valid phone number',
        },
        successMessage: 'Request processed successfully',
      },
    },
    leadJourney: {
      title: 'Lead Activity',
      errorText:
        'Something went wrong. Please check the url or try again later.',
      noLeadJourneyEventsText:
        'No lead journey events to show. Please contact support or try again in sometime!',
      leadDetails: {
        title: 'Lead Details',
        hiddenDetailsLabel: 'Details are hidden',
        leadIDLabel: 'Lead ID: ',
        noLeadDetailsText: 'No lead details to show',
      },
      tatMetrics: {
        title: 'TAT Metrics',
        actualTATLabel: 'Actual TAT: ',
        logicalTATLabel: 'Logical TAT: ',
      },
      callEventTemplate: {
        activityLabel: 'Activity:',
      },
      emailEventTemplate: {
        emailToLabel: 'To',
        emailSubjectLabel: 'Subject',
        emailStatusLabel: 'Status',
      },
      leadCreatedEventTemplate: {
        heading: 'Lead Created',
      },
      leadOutboundIVREventTemplate: {
        contentLabel: 'Content',
        IVRInteractionLabel: 'IVR Interaction',
      },
      leadOutcomeEventTemplate: {
        eventHeadingPrefix: 'Outcome: ',
        dispositionLabel: 'Disposition',
        statusLabel: 'Status',
      },
      leadUnjudgedEventTemplate: {
        heading: 'Lead Unjudged',
        unjudgeReasonLabel: 'Unjudge Reason',
        oldOutcomeLabel: 'Old Outcome',
      },
      outboundCallEventTemplate: {
        dispositionLabel: 'Disposition',
        callRedialsLabel: 'Call Redials',
        callDurationLabel: 'Call Duration',
        totalRingTimeLabel: 'Total Ring Time',
        totalTalkTimeLabel: 'Total Talk Time',
        callRecordingHeader: 'Recorded Call',
        callRecordingLabel: 'Call Recording URL',
        callResponsesLabel: 'Call Responses:',
      },
      smsEventTemplate: {
        statusLabel: 'Status',
        messageLabel: 'Message',
      },
      whatsappEventTemplate: {
        statusLabel: 'Status',
        messageLabel: 'Message',
      },
    },
    script: {
      responseType: 'Response Type',
      addSecondaryLanguage: 'Add Secondary Language',
      removeSecondaryLanguage: 'Remove Secondary Language',
      addDescription: 'Add Description',
      removeDescription: 'Remove Description',
      validate: 'Validate',
      confirmLastQuestionDelete:
        'Deleting this question will lead to cloning all questions from the base VM. Are you sure you want to delete this question?',
      questionElement: {
        questionOrder: 'Question Order',
        questionPrimaryLanguage: 'Question (Primary Language)',
        questionSecondaryLanguage: 'Question (Secondary Language)',
        description: 'Description shown to SE',
        customerResponse: 'Customer Response',
        inputLabel: 'Answer header (shown to SE)',
        inputPlaceholder: 'Type In...',
        reportKey: 'Report Key',
        validator: 'Validator',
        addToCustomerReport: 'Add to Customer Report',
        sendForCallContext: 'Send for Call Context',
        maskResponse: 'Delete answer if lead is deleted',
        jumpTo: 'Jump To',
        responseFields: 'Response Fields',
        insertVariable: 'Insert Variable',
        addValidator: 'Add New Validator',
        addAnswerType: 'Add Answer Type',
        charLimitExceededError:
          'Character Limit Exceeded! Please remove {{charsRemaining}} characters to save your question',
      },
      questionTypes: {
        textAnswer: 'Text Answer',
        singleChoice: 'Single Choice',
        multipleChoice: 'Multiple Choice',
        dateTime: 'Date & Time',
        address: 'Address',
        number: 'Number',
        questionGroup: 'Question Group',
        conditionalRoute: 'Conditional Route',
        closingSME: 'Closing',
        cascadingDropdown: 'Cascading Dropdown',
        dropdown: 'Dropdown',
      },
      addressQuestionElement: {
        addressLine1: 'Address Line 1',
        addressLine2: 'Address Line 2',
        pincode: 'Pincode',
        city: 'City',
        state: 'State',
      },
      dateTimeQuestionElement: {
        date: 'Date',
        time: 'Time',
        useForRescheduling: 'Use compliance time',
        rescheduleWithinDays: 'Re-schedule within (days)',
      },
      choiceQuestionElement: {
        addNestedSingleChoice: 'Add Nested Single Choice',
        addNestedMultipleChoice: 'Add Nested Multiple Choice',
        addNestedChoice: 'Add Nested Choice',
        addChoice: 'Add Choice',
        choices: 'Choices',
        jumpTo: 'Jump To',
      },
      cascadingDropdownElement: {
        selectLabel: 'Please select label',
        addLabelType: 'Insert Label Type',
      },
    },
    paymentContract: {
      review: 'Review',
      businessName: 'Registered Business Name',
      gstin: 'GSTIN',
      address: 'Registered Address',
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      state: 'State',
      city: 'City',
      pincode: 'Pincode',
      businessDetails: 'Business Details',
      save: 'Save',
      edit: 'Edit',
      callerSelection: 'Use-case & Caller Selection',
      useCase: 'Use-Case',
      callerSelected: 'Caller Selected',
      frequency: 'Frequency',
      leadVolume: 'Lead Volume',
      script: 'Script',
      faqs: 'FAQs',
      inReview: 'In Review',
      open: 'Open',
      pricing: 'Pricing',
      connectedMins: 'Connected Minutes',
      pricePerMin: 'Price per min',
      total: 'Total',
      proforma: 'Proforma Invoice',
      firstPayment: 'First Time Payment',
      firstPaymentPercent: 'of Total Required',
      copyLink: 'Copy Link',
      download: 'Download',
      sendEmail: 'Send Email',
      tnc: 'Accept the Terms',
      tncAgreement:
        'By checking this box, I acknowledge that I have read, understood and agree to the terms and conditions governing the use of the services and confirm the aforesaid & any recurring payment specified herein.',
      verify: 'Verify GSTIN',
      gstError: 'Please enter a valid GSTIN number',
      noScriptMessage:
        'Script is not necessary for the selected use case. We will cater it by training agents on pitch.',
      businessDetailsUpdated: 'Your Business Details have updated successfully',
      submitForReviewSuccess: 'Your campaign has been submitted for review',
      businessNameError: 'Registered Business Name is required',
      resources: {
        title: 'Resources',
      },
      additionalInfoError: 'Please enter valid support email and number',
    },
    settings: {
      tabs: {
        account: 'Account',
        integrations: 'Integrations',
        manageSubscriptions: 'Manage Subscriptions',
        teams: 'Teams',
      },
      account: {
        profileDetails: {
          title: 'Profile Details',
          business: 'Business',
          email: 'Email',
          timeZone: 'Time Zone',
          mobileNumber: 'Mobile Number',
          countryCode: '+91 (India)',
          mobileNumberPlaceholder: 'Enter Mobile Number',
          mobileNumberError: 'Enter a valid phone number',
          save: 'Save',
          timeZoneSuccessToast: 'Time Zone Updated Successfully',
          mobileNumberSuccessToast: 'Mobile Number Updated Successfully',
        },
        changePassword: {
          title: 'Change Password',
          oldPassword: 'Old Password',
          newPassword: 'New Password',
          confirmPassword: 'Confirm New Password',
          cancel: 'Cancel',
          save: 'Save',
          passwordMismatch: 'Passwords do not match',
          oldPasswordError: 'Old Password is incorrect',
          success: 'Password changed successfully',
        },
        businessDetails: {
          title: 'Business Details',
          businessName: 'Business Name',
          registeredBusinessName: 'Registered Business Name',
          gstNumber: 'GST Number',
          industry: 'Industry',
          businessAddress: 'Business Address',
          save: 'Save',
          supportEmail:
            'If you need any changes, please contact support@squadstack.com',
          successToast: 'Business Updated Successfully',
        },
      },
      integrations: {
        google: 'Google',
        googleApps: 'Google Apps',
        microsoft: 'Microsoft',
        account: 'Account',
        cancel: 'Cancel',
        revoke: 'Revoke',
        needHelp: 'Need Help?',
        authorize: 'Authorize',
        authorizeMicrosoftModal: {
          heading: 'Authorize Microsoft Office 365 Account',
        },
        authorizeGoogleModal: {
          heading: 'Authorize Google Account',
        },
        apiEndpoints: {
          heading: 'API Endpoints',
          subHeading:
            'Click on the below links to access the relevant API reference document.',
          viewAll: 'View All',
          createLeads: 'Create Leads',
          updateLeads: 'Update Leads',
          convertLeads: 'Convert Leads',
          disableLeads: 'Disable Leads',
          deleteLeads: 'Delete Leads',
          upsertLeads: 'Upsert Leads',
        },
        revokeAccessModal: {
          heading: 'Are you sure you want to revoke access?',
          subHeading:
            'Revoking access will disallow SquadStack from accessing your {{type}} account. To remove full access, visit your ',
          settings: 'settings.',
        },
        accountsIntegration: {
          googleTitle: 'Integrate Google Apps',
          googleSubTitle:
            'Connect your Google account to send emails from this email address',
          microsoftTitle: 'Integrate Microsoft Office 365',
          microsoftSubTitle:
            'Connect your Microsoft Office 365 account to send emails from this email address',
          addAccount: 'Add Account',
          authorizedAccounts: 'Authorized Accounts',
          emailId: 'Email ID',
          permissionsGiven: 'Permissions Given',
        },
        apiCredentials: {
          heading: 'API Credentials',
          tokenSubHeadingPart1: 'Authentication to our APIs is performed via ',
          tokenSubHeadingPart2:
            ' For authentication in our APIs, you have to provide this Access token as a HTTP header in every request as',
          httpBearerAuth: 'HTTP Bearer Auth.',
          requestAccessSubHeading:
            "If you have already placed a request, kindly allow us 24hrs to process it. If you don't hear back from us within 24hrs, you may either re-raise the request or write to us at ",
          accessRequested: 'Access Requested',
          requestAccess: 'Request Access',
          lastRequestedAt: 'Last requested at:',
          accessToken: 'Access Token',
          authBearerHeader: 'Authorization: Bearer',
          accessTokenCopied: 'Access token copied',
        },
        apiTokens: {
          production: 'Production',
          staging: 'Staging',
          activeTokens: 'Active Tokens',
          environmentTabs: {
            noAPICredentials: 'No API Credentials Generated',
            generateUptoTwo:
              'You can generate up to two active tokens simultaneously for each environment.',
            generateToken: 'Generate Token',
            accessToken: 'Access Token',
            dialogTitle: 'Are you sure you want to delete this token?',
            dialogContent:
              'This action is irreversible –  once deactivated, the token becomes permanently unusable.',
            cancel: 'Cancel',
            delete: 'Delete',
            expiresOn: 'Expires On',
            addToken: 'Add Token',
            accessTokenCopied: 'Access token copied',
            tokenDeleted: 'API token deleted successfully',
            tokenDeleteFailed: 'Error deleting token',
            generateUptoTwoActiveTokens:
              'You can generate up to two active tokens.',
          },
        },
      },
      manageSubscriptions: {
        title: 'Manage Subscriptions',
        channels: {
          title: 'Channels',
          channel: 'Channel',
          channelValue: 'Channel Value',
          status: 'Status',
          default: 'Default',
          verified: 'Verified',
          verificationLinkSent: 'Verification Link Sent',
          verificationOTPSent: 'Verification OTP Sent',
          resend: 'Resend',
          setAsDefault: 'Set as Default',
          removeDefault: 'Remove Default',
          addChannel: {
            button: '+ Add Channel',
            title: 'Add New Channel',
            channelType: {
              title: 'Channel Type',
              email: 'Email',
              sms: 'SMS',
              webhook: 'Webhook',
              cancel: 'Cancel',
              submit: 'Submit',
              channelValuePlaceholder: 'Enter Channel Value',
            },
            toast: 'Channel added successfully',
            emailErrorMsg: 'Please enter a valid email address',
            smsErrorMsg: 'Please enter a valid phone number',
          },
          deleteChannel: {
            title: 'Are you sure you want to Delete this Channel?',
            delete: 'Delete Channel',
            cancel: 'Cancel',
            toast: 'Channel deleted successfully',
          },
          channelVerification: 'Channel Verification',
        },
        subscriptions: {
          title: 'Subscriptions',
          subtitle:
            'Please find below a list of items you can subscribe to. If you do not require the service, please unsubscribe.',
          notificationType: 'Notification Type',
          channels: 'Channels',
          subscribe: 'Subscribe',
          unsubscribe: 'Unsubscribe',
          manageChannels: {
            title: 'Manage Channels',
            channel: 'Channel',
            channelValue: 'Channel Value',
            default: 'Default',
            add: '+ Add',
          },
        },
      },
      teams: {
        inviteUsersButton: 'Invite Users',
        notAuthorized: 'You are not authorized to access this page',
        currentUsers: {
          title: 'Current Users',
          name: 'Name',
          role: 'Role',
          email: 'Email',
          total: 'Total Current Users: ',
          noCurrentUsers: 'No Current Users found',
          deleteUser: {
            title: 'Are you sure you want to delete this User?',
            cancel: 'Cancel',
            delete: 'Delete',
          },
        },
        invitedUsers: {
          title: 'Invited Users',
          email: 'Email',
          resendInvite: 'Resend Invite',
          total: 'Total Invited Users: ',
          successToast: 'Invitation resent successfully.',
          noInvitedUsers: 'No Invited Users found',
        },
        pendingRequests: {
          title: 'Pending Requests',
          email: 'Email',
          accept: 'Accept',
          decline: 'Decline',
          total: 'Total Pending Requests: ',
          acceptedRequestToast: 'Request accepted',
          rejectedRequestToast: 'Request declined',
          noPendingRequests: 'No pending requests found',
        },
        inviteUser: {
          title: 'Invite User',
          email: 'Email',
          placeholder: 'Enter email id of user',
          cancel: 'Cancel',
          invite: 'Invite',
          successToast: 'Invitation sent successfully.',
          invalidEmailError: 'Please enter a valid email',
          incorrectDomainError:
            'Email from other domains is not allowed. Please contact your admin.',
        },
      },
    },
    downloadLeadReports: {
      reportDownloadButton: 'Download Reports (Last 30 Days)',
    },
    billing: {
      title: 'Billing',
      billingCycle: {
        title: 'Billing Cycle',
        startDate: 'Start Date',
        endDate: 'End Date',
      },
      billingReport: {
        campaignName: 'Campaign Name',
        campaignRefId: 'Campaign Ref ID',
        downloadReport: 'Download Report',
        billingMonth: 'Billing Month',
      },
      addCredits: {
        title: 'Add Credits',
        subtitle:
          'Amount cannot be less than ₹ {{ minCredits }} (excluding GST)',
        minCredits: 'Min: ₹ {{minCredits}} + GST ({{gst}}%)',
        availableBalance: 'Available Balance',
        amountToRecharge: 'Amount to Recharge',
        makePayment: 'Make Payment',
        cancel: 'Cancel',
        rupeeIcon: '₹',
        amountRechargeError:
          'Amount to recharge cannot be less than ₹ {{minCredits}}',
        comingSoon: 'Coming Soon',
      },
      monthlyUsage: {
        title: 'Monthly Usage',
        startDate: 'Start Date',
        endDate: 'End Date',
        usageAmount: 'Usage Amount',
        downloadBill: 'Download Bill',
      },
      invoices: {
        title: 'Invoices',
        invoiceId: 'Invoice ID',
        raisedOn: 'Raised On',
        dueOn: 'Due On',
        amount: 'Amount',
        downloadInvoice: 'Download Invoice',
      },
      payments: {
        title: 'Payments',
        paymentDate: 'Payment Date',
        squadCredits: 'Squad Credits',
        taxes: 'Taxes',
        totalPaidAmount: 'Total Paid Amount',
        status: 'Status',
      },
      additionalCosts: {
        title: 'Additional Costs',
        startDate: 'Start Date',
        endDate: 'End Date',
        amount: 'Amount',
        comment: 'Comment',
      },
      noDataMsg: 'No Billing History Found',
    },
    helpAndSupport: {
      faq: {
        title: 'FAQs',
        heading: 'Help & Support',
        subHeading:
          'Everything you need to know about the product and billing. Can’t find the answer you’re looking for? please have a chat built into this page. Our support team will reach out in case your query is not resolved.',
        subHeadingSME:
          'Everything you need to know about the product and billing. Can’t find the answer you’re looking for? Reach out to our support team on portal@squadstack.com and we will help you out!',
        searchPlaceholder: 'Search FAQs (Type at least 3 characters)',
        noFaqsFound: 'No FAQs Available',
        noFaqsFoundSearch: 'No FAQs found related to your search',
      },
      getStarted: {
        title: 'Explore Squadstack',
        heading: 'Your Guide to Getting Started',
        subHeading1:
          "Check out our byte-sized videos to familiarize yourself with our platform! From exploring the demo account to launching your first telecalling campaign, we've got you covered!",
        subHeading2:
          "Need additional help? No worries! Just drop us an email at portal@squadstack.com, and we'll be there to assist you every step of the way!",
      },
    },
    customerFeedback: {
      feedbackModal: {
        modalHeader: 'Rate Call Quality & Share Feedback',
        ratingLabel: 'Call Quality',
        feedbackLabel: 'Describe your experience',
        optionalLabel: ' (optional)',
      },
      rating: {
        boxHeader: 'Rate Call Quality',
        feedbackHeader: 'Describe your experience',
      },
      buttons: {
        skipButton: 'Skip',
        submitButton: 'Submit',
      },
      uploadSuccessful: 'Feedback uploaded successfully',
    },
    logs: {
      title: 'API Logs',
      outbound: 'Outbound',
      inbound: 'Inbound',
      newReport: 'Fetch Logs',
      campaignName: 'Campaign Name',
      requestedOn: 'Requested On',
      dateRange: 'Date Range',
      httpStatus: 'HTTP Status',
      createReport: 'Create Report',
      reportType: 'Report Type',
      newReportCreated: 'Your Report has been created successfully',
      noLogsFound: 'No Logs Found',
      noLogsGenerated: 'You currently have no logs generated',
      select: 'Select',
      reportTypeRequired: 'Report Type is required',
      campaignRequired: 'Campaign is required',
      httpStatusCodeRequired: 'HTTP Status Code is required',
      referenceDocumentation: 'Reference Documentation',
      clickToAccessDocs:
        'Click on the below links to access the relevant API reference document.',
      apiDocs: 'API Documentation',
      errorDocs: 'Errors Documentation',
      internalUserNotAllowedToFetchLogs:
        'Internal Users are not allowed to Fetch Logs for Customer Business.',
    },
    leadJourneyReport: {
      reports: 'Reports',
      pastReports: 'Past Reports',
      scheduledReports: 'Scheduled Reports',
      scheduledReport: {
        editButtonLabel: 'Edit',
        updateStatusSuccessMessage: 'Status updated successfully',
        successMessage: 'Schedule Created',
        errorMessage: 'Something went wrong. Please try again later.',
        active: 'Active',
        scheduleName: 'Schedule Name',
        campaignName: 'Campaign Name',
        nextExecution: 'Next Execution',
      },
      pastReport: {
        reportType: 'Report Type',
        campaignName: 'Campaign Name',
        requestedOn: 'Requested On',
        reportDateRange: 'Report Date Range',
      },
      reportFormModal: {
        title: 'New Report',
        createReportButtonLabel: 'Create Report',
        scheduleReportButtonLabel: 'Schedule Report',
        reportCreatedSuccessMessage: 'Report created successfully',
        reportCreatedErrorMessage:
          'Something went wrong. Please try again later.',
        reportScheduledSuccessMessage: 'Report scheduled successfully',
        reportScheduleUpdatedSuccessMessage: 'Report schedule updated',
        reportScheduleUpdatedErrorMessage:
          'Something went wrong. Please try again later.',
        addCCBCCEmail: 'Add CC/BCC Email',
        dateRangeError:
          'Selected date range should not be greater than 31 days',
        invalidDateRangeError: 'Please select valid date range',
      },
    },
    profile: {
      activityLogs: 'Activity Logs',
      logout: 'Logout',
      noActivityLogsFound: 'No Activity Logs Found',
    },
    multiSelectSearchFilter: {
      selectAll: 'Select All',
    },
    downloads: {
      downloadStarted: 'Download Started',
      downloadFailed: 'Download Failed',
      downloadFailedDescription:
        'Please try again in sometime or reach out to us at',
      downloadStartedDescPart1:
        'Your download should start automatically in a few seconds. If not,',
      clickHere: 'click here',
      downloadStartedDescPart2: 'to download instead.',
      notAuthorisedError: 'You are not authorised to access this report!',
    },
    blacklist: {
      importedAt: 'Imported At',
      type: 'Type',
      status: 'Status',
      success: 'Successful',
      failed: 'Failed',
      viewImportData: 'View Import Data',
      importDataToBlacklist: 'Import Data - Added to Blacklist',
      copiedToClipboard:
        'Your blacklist import data has been copied to clipboard',
      importing: 'Importing',
      addType: 'Added to Blacklist',
      removeType: 'Removed from Blacklist',
      noLeadsGenerated: 'You currently have no leads generated',
      noLeadsFound: 'No Leads Found',
    },
    resources: {
      title: 'Resources',
      buttonCTA: 'Having issues, Click here!',
      inReview: 'In Review',
      start: 'Start',
      view: 'View',
      emptyViewHeader: 'No Resources Found',
      emptyViewSubHeader: "You'll see all the resources shared with you here.",
    },
    intelAssist: {
      title: 'IntelAssist',
      intelAssistTab: 'Intel Assist',
      feedbackTrackerTab: 'Feedback Tracker',
      report: {
        reportGenerationSuccess: 'Report is being generated',
      },
      filters: {
        search: 'Keyword Search',
        searchPlaceholder: 'Type in keywords to filter transcript',
        dateRange: 'Date Range',
        campaign: 'Campaign',
        campaignPlaceholder: 'Select Campaign',
        dateRangePlaceholder: 'Select Date Range',
        callQualityBucket: 'Call Quality Bucket',
        callQualityBucketPlaceholder: 'Select Call Quality Bucket',
        filterButton: 'Filter',
        leadOutcome: 'Lead Outcome',
        telecallers: 'Tele-Callers',
        callOutcome: 'Call Outcome',
        showMore: 'Show More',
        showLess: 'Show Less',
        acpt: 'ACPT',
        selectOptions: 'Select Options',
        qna: 'Q&A',
        acptqna: 'ACPT , Q&A',
        audits: 'Audit Type',
        callId: 'Call Id',
        enterCommaSeperatedValues: 'Enter Comma Seperated Values',
      },
      callsList: {
        callId: 'Call ID',
        callOutcome: 'Call Outcome',
        qualityBucket: 'Quality Bucket',
        telecaller: 'Tele-Caller',
        callRecording: 'Call Recording',
        viewBtn: 'View',
        insightsAvailable: 'Insights Available',
      },
      callTabs: {
        callDetails: 'Call Details',
        transcript: 'Transcript',
        auditFindings: 'Audit Findings',
        feedback: 'Feedback',
        acpt: 'ACPT',
        qna: 'Q&As',
      },
      callDetails: {
        callDate: 'Call Date',
        callOutcome: 'Call Outcome',
        leadOutcome: 'Lead Outcome',
        leadJourney: 'Lead Journey',
        telecallerEmail: 'Tele-Caller Email',
        leadJourneyLink: 'View',
      },
      transcript: {
        leadTranscript: 'Lead Transcript',
        seTranscript: 'SE Transcript',
        noTranscriptionText: 'Oops! No transcription available for this call',
      },
      auditFindings: {
        score: 'Score %',
        performance: 'Performance:',
        remarks: 'Remarks:',
        dimension: 'Dimension',
        noAuditFindings: 'Oops! No Audit Findings available for this call',
      },
      feedback: {
        agreement: 'Do you agree with the assessment?',
        yes: 'Yes',
        no: 'No',
        feedbackCategory: 'Feedback Category',
        submitBtn: 'Submit Feedback',
        feedbackPlaceholder: 'Type in your feedback here...',
        feedbackSubmissionSuccess: 'Feedback submitted successfully',
        parameterCategory: 'Parameters Category',
        select: 'Select',
        required: 'Required',
        removeFeedback: 'Remove Feedback',
      },
      feedbackHistory: {
        title: 'Feedback History',
      },
      callSideBar: {
        callRecording: 'Call Recording',
        callId: 'Call ID',
        callIdCopied: 'Call ID Copied',
        copyTooltip: 'Click to copy Call ID',
      },
      acpt: {
        acptTopics: 'ACPT Topics',
        topic: 'Topic',
        category: 'Category',
      },
      qna: {
        qnaTitle: 'Question & Answers',
      },
      feedbackTracker: {
        date: 'Date',
        callId: 'Call ID',
        campaign: 'Campaign',
        category: 'Category',
        feedback: 'Feedback',
        status: 'Status',
      },
      noPageAccess: 'You are not allowed to access this page.',
    },
    resourceUpload: {
      title: 'Resources',
      subtitle:
        'We need these resources to ensure top-notch quality from our sales experts.',
      sidebar: {
        title: 'Upload',
        uploadLinkTitle: 'Share Drive Link',
        uploadLinkSubtitle: '(with appropriate permissions)',
        uploadLinkBtn: 'Add',
        uploadLinkPlaceholder: 'Paste drive link here',
        uploadedFiles: 'Uploaded Files',
        errorMsg: 'Please enter a valid link',
        noUploadedFiles: 'No files uploaded yet',
        deleteSuccess: 'Resource deleted successfully',
      },
      resourceItem: {
        callRecordingSubtitle:
          'A.I. uses call recordings to train tele-callers & bridge knowledge',
        productWalkthroughSubtitle:
          'Share an end-to-end product walkthrough to train tele-callers for clear communication.',
        productPresentationSubtitle:
          'Share a presentation outlining the key process to train and assist tele-callers on live-calls.',
        bulkUploadBtn: 'Bulk Upload',
        noFilesUploaded: 'No Files Uploaded',
        filesUploadedMultiple: 'File(s) Uploaded',
        fileFormat: 'File Format:',
      },
    },
    agents: {
      title: 'Agents',
      apply: 'Apply',
      allCampaign: 'All Campaign',
      selectCampaign: 'Select Campaign',
      agentDetailsFetchedSuccessfully: 'Filter Applied Successfully',
      noAgentsFound: 'No Agents Found',
      confirmationDialogText: 'Are you sure you want to accept this agent?',
      noAgentsFoundSubtext:
        'Once our team shortlists agents for you, their profiles will appear in this section.',
      accept: 'Accept',
      discard: 'Discard',
      status: {
        shortlisted: 'Shortlisted',
        accepted: 'Accepted',
        in_review: 'In Review',
        rejected: 'Rejected',
      },
      detailsForm: {
        reviewDetails: 'Review Candidate',
        teleCallingExp: 'Tele-calling Exp.',
        education: 'Education',
        campaign: 'Campaign',
        certification: 'Certification',
        proofOfWork: 'PROOF OF WORK',
        PIRecording: 'Personal Interview Recording',
        cefrCallRecording: 'CEFR Call Recording',
        remarks: 'Remarks',
        discussReview: 'Discuss & Review',
        accept: 'Accept',
        faultyParamsError:
          'Please select parameters that do not match your expectations',
        refer: 'Refer',
        campaignAgentPersona: "Campaign's Agent Persona",
        verdict: 'Your Verdict',
        approveCandidate: 'Approve Candidate',
        discuss: 'Discuss & Review',
        remarksPlaceholder: 'Please add your remarks here',
        select: 'Select',
        faultyParamsTitle: 'Parameters that do not match your expectations',
        submit: 'Submit',
      },
      tableHeader: {
        candidate: 'Candidate',
        experience: 'Experience',
        campaign: 'Campaign',
        education: 'Education',
        certification: 'Certification',
        agentProfile: 'Agent Profile',
        reviewProfile: 'Review Profile',
      },
      agentPersona: {
        salesExp: 'Sales Experience',
        education: 'Education',
        city: 'City',
        workMode: 'Work Mode',
        certification: 'Certification',
        versantEquivalent: 'Versant {{level}} Equivalent',
        cefrCertified: `CEFR ({{speechScore}}) Certified`,
        industry: 'Industry',
      },
      reviewCandidateDialog: {
        title: 'Review Candidate',
        candidateTab: 'Candidate',
        approvedPersonaTab: 'Approved Persona',
      },
    },
    sme: {
      apiIntegration: {
        title: 'API Integration',
        integrationNotEnabled: 'API Integration is not enabled',
        contactSupport:
          'To enable integration please contact our internal support',
        apiDocs: 'View API documentation',
        referenceId: 'Reference Id: ',
        inboundEvents: 'Inbound Events',
        outboundEvents: 'Outbound Events',
        noConfig: 'No configration has been created yet',
        invalidURL: 'Invalid URL',
        eventSection: {
          callbackUrl: 'Callback URL',
          authToken: 'Auth Token',
        },
      },
      campaign: {
        campaignView: {
          navTab: {
            templates: 'Templates',
            campaigns: 'Campaigns',
          },
          campaignFilters: {
            applyFilterCTA: 'Apply Filters',
            filtersApplied: 'Filters applied:',
            useCase: 'Use-Case',
            status: 'Status',
          },
          campaignFilterModal: {
            title: 'Apply Filter',
            applyBtn: 'Apply',
            statusLabel: 'Status',
            statusPlaceholder: 'Select Campaign Status',
            useCaseLabel: 'Campaign Use-Case',
            useCasePlaceholder: 'Select Campaign Use-Case',
          },
          customScript: {
            title: 'Create Custom Calling Script',
            subTitle: `Can't find a script that fits your needs? Create a script tailored to your needs with our script builder`,
            createCustomScriptCTA: 'Create Custom Campaign',
          },
          campaignCard: {
            leadJourneyReport: 'Lead Journey Report',
            connectivity: 'Connectivity %',
            conversion: 'Conversion %',
            qualityScore: 'Quality Score %',
            sampleCallRecording: 'Sample Call Recording',
            viewScript: 'View Script',
            editScript: 'Edit Script',
            importDataDraftModePopup:
              'For our magic ✨ to work we need your help! \n\n✅ Complete your campaign setup and approve your calling script now!',
            importDataReviewModePopup:
              "We're brewing magic ✨. \n✅ Check back soon to add your leads and watch our product in action.",
            analyticsDraftModePopup:
              'Analytics will be available once calling begins.',
            analyticsReviewModePopup:
              'Check back in few moments and be ready to add your leads! \n\n🤌 Cause we are preparing our A game to give you the best analytics for your lead campaign.',
            leadJourneyReportDraftModePopup:
              'Your portal to lead journey will be enabled once calling begins 🤯. \n\n✅ Complete your campaign request to unlock lead journeys!',
            leadJourneyReportReviewModePopup:
              'Your portal to lead journey will be unlocked once we finish sprinkling our magic ✨. \n\n✅ Be prepared with your leads to unlock our values.',
            apiIntegration: 'API Integration',
          },
          paymentContract: {
            tncDetails: `By clicking on Checkout, you are consenting to Accept our `,
            tnc: 'Terms & Conditions',
          },
          paymentDetails: {
            paymentPlanTitle: 'Choose a payment plan',
            leadCreditsLabel: `{{leadCredits}} lead Credits`,
            validityLabel: `{{validity}} days Validity`,
            billingAddressDetailsTitle: 'Billing Address Details',
            streetAddressLabel: 'Enter your street address',
            cityLabel: 'Enter your city',
            pincodeLabel: 'Enter your Pincode',
            countryLabel: 'Enter your Country',
            stateLabel: 'Enter your State',
            gstinLabel: `Enter your GSTIN (if no GSTIN enter 'NA')`,
            verifyBtnLabel: 'Verify',
            verifyGSTINSuccessMsg: 'GSTIN verified',
            updateBtnLabel: 'Save',
            updateBillingAddressSuccessMsg:
              'Billing Address Details Updated Successfuly',
            paymentProcessingTopLabelShopify:
              'We are redirecting you to the payment gateway!',
            paymentProcessingTopLabelShiprocket:
              'Please hold while we complete your purchase!',
            paymentProcessingBottomLabel: 'Please do not close this window',
          },
        },
        createCampaignModal: {
          useCasePlaceholder: 'Select your use case',
          campaignListPlaceholder: 'Select your Campaign use case',
          customCampaignHelpText:
            'Select A Use-Case to start creating your custom script',
        },
        paymentSuccess: {
          thankYouMsg: 'Thank you for using Squadstack',
          thankYouMsgDetails:
            'Our team is currently processing your telecalling campaign request. Your campaign will be live within 48 hours',
          failureMsgDetails:
            'Your payment request has failed. Please try again after some time',
        },
        activeSubscriptionTab: {
          plan: 'Plan',
          status: 'Status',
          startDate: 'Start Date',
          expirationDate: 'Expiration Date',
          remainingCredits: 'Remaining Credits',
          totalCredits: 'Total Credits',
        },
        topupPlanSelectionTab: {
          plan: 'Topup Plan',
          credits: 'Topup Credits',
          priceINR: 'Price(INR)',
          priceUSD: 'Price(USD)',
          validity: 'Validity',
        },
        topupPlanConfirmationModal: {
          title: 'Buy Additional Lead Credits',
          subTitle:
            'Top-up your active subscription with additional lead credits to continue your campaigns without any interruptions',
          confirmBtn: 'Confirm',
          cancelBtn: 'Cancel',
        },
      },
    },
  },
};

export default en;
