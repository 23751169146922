import { UserInfoResponse } from '@/lib/layout/SidebarLayout/components/UserInfo';

export type Action = {
  type: 'LOGIN' | 'LOGOUT' | 'USER' | 'SIGNOUT_TYPE';
  data?: unknown;
};

export type Dispatch = (action: Action) => void;

export type State = {
  isAuthenticated: boolean | null;
  user: UserInfoResponse | null;
  signOutType: SignOutTypes | null;
};

export type AuthContextProps = { children: React.ReactNode };

export type AuthContextValueConfig = {
  state: State;
  signIn: (csrfToken: string) => void;
  signOut: (type: SignOutTypes) => void;
  checkAuthentication: () => boolean;
  setAuthenticationState: (isUserAuthenticated: boolean) => void;
  setUserInfo: (user: UserInfoResponse) => void;
  updateUserInfo: (userInfo: UserInfoResponse) => void;
};

export enum SignOutTypes {
  BAD_AUTH_API_ERR = 'BAD_AUTH_API_ERR',
  SIGN_OUT_BUTTON = 'SIGN_OUT_BUTTON',
}
