import {
  CampaignRoutes,
  DownloadRoutes,
  IntelAssistRoutes,
  ScriptRoutes,
  PlugNPlayCampaignRoutes,
  AnalyticsRoutes,
} from './routes';

type RoutesConfig = {
  [key: string]: {
    showSidebar?: boolean;
    showNavbar?: boolean;
    topBarDark?: boolean;
    showBuildingIcon?: boolean;
    showBellIcon?: boolean;
  };
};

//? All Auth Routes are handled separately only.
//? Add config here only if you want to hide navbar or sidebar. By default both will be shown.
export const routesConfig: RoutesConfig = {
  [CampaignRoutes.CREATE_CAMPAIGN]: {
    showNavbar: false,
  },
  [CampaignRoutes.EDIT_CAMPAIGN]: {
    showNavbar: false,
  },
  [CampaignRoutes.WORKFLOW_TEMPLATE]: {
    showSidebar: false,
    showNavbar: false,
  },
  [CampaignRoutes.CREATE_WORKFLOW]: {
    showSidebar: false,
    showNavbar: false,
  },
  [ScriptRoutes.SCRIPT]: {
    showSidebar: false,
    showNavbar: false,
  },
  [CampaignRoutes.VIEW_CAMPAIGN_SCRIPT]: {
    showSidebar: false,
    showNavbar: false,
  },
  [CampaignRoutes.VIEW_CAMPAIGN_FAQS]: {
    showSidebar: false,
    showNavbar: false,
  },
  [DownloadRoutes.DOWNLOADS]: {
    showSidebar: false,
    showNavbar: false,
  },
  [IntelAssistRoutes.HOME]: {
    showSidebar: true,
    showNavbar: true,
    topBarDark: false,
    showBuildingIcon: false,
    showBellIcon: true,
  },
  [PlugNPlayCampaignRoutes.PAYMENT_SUCCESS]: {
    showSidebar: true,
    showNavbar: true,
    topBarDark: false,
    showBuildingIcon: false,
  },
  [AnalyticsRoutes.ANALYTICS]: {
    showNavbar: false,
  },
};
