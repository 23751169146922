import i18n from 'i18next';

/**
 * Translates text.
 *
 * @param key The i18n key.
 */
export const translate = (key: string, options = {}) => {
  return i18n.t(key, options);
};
