import { create } from 'zustand';
import { TranscriptStore } from '../types';
import { IntelAssistTabOptions, IntelAssistTabsStoreType } from '../constants';

export const useTranscriptStore = create<TranscriptStore>((set, _get) => ({
  transcriptData: null,
  setTranscriptData: (transcriptData) => set({ transcriptData }),
}));

export const useIntelAssistTabsStore = create<IntelAssistTabsStoreType>(
  (set) => ({
    selectedTab: IntelAssistTabOptions[0],
    setSelectedTab: (selectedTab) => set({ selectedTab }),
  })
);
