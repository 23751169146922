import { useAuthContext } from '@/lib/contexts';
import Script from 'next/script';
import { useEffect, useState } from 'react';

type UsetifulProps = {
  enable: boolean;
  token: string;
};

declare global {
  interface Window {
    usetifulTags: { [key: string]: string };
  }
}

export const Usetiful = ({ enable, token }: UsetifulProps) => {
  const [renderScript, setRenderScript] = useState(false);
  const { state } = useAuthContext();
  useEffect(() => {
    window.usetifulTags = {
      first_user_login: `${state.user?.first_user_login}`,
    };
    setRenderScript(true);
  }, [state.user?.first_user_login]);

  return (
    <>
      {enable && renderScript ? (
        <>
          <Script
            id="usetifulScript"
            strategy="afterInteractive"
            src="https://www.usetiful.com/dist/usetiful.js"
            async={true}
            data-token={token}
          />
        </>
      ) : null}
    </>
  );
};
