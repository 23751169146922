import { Box, IconButton, Toolbar, Modal } from '@/lib/components';
import { useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { BellIcon } from '@/lib/icons';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import NotificationHistory from '@/lib/components/layout/NotificationHistory/NotificationHistory';
import { useAuthContext } from '@/lib/contexts';
import { useSidebarContext } from '@/lib/components/layout/Sidebar/SidebarContext';
import { AddCampaignName } from '@/pages/campaigns/components/AddCampaignName';
import { NewCampaignButton } from '@/pages/campaigns/components/NewCampaignButton';
import {
  CampaignRoutes,
  PlugNPlayCampaignRoutes,
  SelectBusinessRoutes,
} from '@/pages/routes/routes';
import { UserBusinessInfo } from '../UserInfo';
import { useFeatureFlag } from '@/lib/utils/FeatureFlags';
import { TopBarHeader } from '../TopBarHeader/TopBarHeader';
import { AnalyticsService } from '@/lib/analytics';
import { AddCampaignName as AddCampaignNameSme } from '@/pages/plug-n-play/campaigns/components/CampaignView/components';
import { AlllowedIntegrationSourcesForNonPabblySMEFlow } from '@/pages/plug-n-play/campaigns/components/CampaignCreationWorkflow/constants';
import { UseCaseSelectionModal } from '@/pages/plug-n-play/campaigns/components/CampaignView/components/UseCaseSelectionModal';

export const TopBarLayout = ({
  showSidebar,
  topBarDark,
  showBuildingIcon,
  showBellIcon,
}: {
  showSidebar: boolean;
  topBarDark?: boolean;
  showBuildingIcon?: boolean;
  showBellIcon?: boolean;
}) => {
  const theme = useTheme();

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { route } = useRouter();
  const { state } = useAuthContext();
  const { handleSidebarToggle } = useSidebarContext();
  const [openAddCampaignModal, setOpenAddCampaignModal] = useState(false);
  const featureFlags = useFeatureFlag();

  useEffect(() => {
    if (
      AlllowedIntegrationSourcesForNonPabblySMEFlow.includes(
        state?.user?.business_integration_source ?? ''
      ) &&
      state?.user?.first_user_login
    )
      <UseCaseSelectionModal handleClose={closeAddCampaignModal} />;
  }, [
    state?.user?.business_integration_source,
    state?.user?.first_user_login,
    state?.user?.business_name,
  ]);

  const toggleLeadDrawer = (status: boolean) => {
    setIsDrawerOpen(status);
  };

  const handleBellIconClick = () => {
    AnalyticsService.trackEvent('User clicks on notification bell icon', {
      actor: 'User',
      verb: 'clicks',
      direct_object: 'on notification bell icon',
      direct_object_info: 'to view notificaton history',
      context: 'from navbar',
      context_metadata: 'of customer dashboard',
    });

    toggleLeadDrawer(true);
  };

  const toggleAddCampaignModal = (status: boolean) => {
    setOpenAddCampaignModal(status);
  };

  const closeAddCampaignModal = () => {
    setOpenAddCampaignModal(false);
  };

  return (
    <>
      <Toolbar
        sx={{
          '@media (min-width: 600px)': {
            minHeight: '56px',
          },
          backgroundColor: topBarDark
            ? theme.baseTheme.palette.surfaces.sidebarBackground
            : theme.baseTheme.palette.surfaces.primaryBackground,
          padding: theme.spacing(1, 3),
        }}>
        {state.user ? (
          <>
            {showSidebar ? (
              <IconButton
                color="inherit"
                edge="start"
                sx={{ mr: 2, display: { sm: 'none' } }}
                onClick={handleSidebarToggle}>
                <MenuIcon />
              </IconButton>
            ) : null}
            <TopBarHeader />
          </>
        ) : null}
        <Box sx={{ flexGrow: 1 }} />

        {state.user?.has_campaign || state.user?.is_sme ? (
          route === CampaignRoutes.CAMPAIGNS ? (
            <NewCampaignButton
              toggleAddCampaignModal={toggleAddCampaignModal}
            />
          ) : undefined
        ) : null}
        {route !== PlugNPlayCampaignRoutes.PAYMENT_SUCCESS ? (
          <UserBusinessInfo
            showBuildingIcon={showBuildingIcon}
            topBarDark={topBarDark}
          />
        ) : null}

        {showBellIcon && featureFlags.show_notification ? (
          <Box
            sx={{
              padding: 1,
              height: 40,
              display: 'flex',
            }}>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleBellIconClick}
              sx={{ padding: 0.2 }}
              disabled={
                state.user?.feature_flags_list.enable_invite_user_feature &&
                route === SelectBusinessRoutes.SELECT_BUSINESS
              }>
              <BellIcon />
            </IconButton>
          </Box>
        ) : null}
        <NotificationHistory
          isDrawerOpen={isDrawerOpen}
          handleLeadDrawer={toggleLeadDrawer}
        />
      </Toolbar>

      <Modal
        open={openAddCampaignModal}
        onClose={() => closeAddCampaignModal()}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {featureFlags.show_new_campaign_flow ? (
          AlllowedIntegrationSourcesForNonPabblySMEFlow.includes(
            state?.user?.business_integration_source ?? ''
          ) ? (
            <UseCaseSelectionModal handleClose={closeAddCampaignModal} />
          ) : (
            <AddCampaignNameSme handleClose={closeAddCampaignModal} />
          )
        ) : (
          <AddCampaignName
            hasCampaigns={true}
            handleClose={closeAddCampaignModal}
          />
        )}
      </Modal>
    </>
  );
};
