import { defaultIntegrationStatusValue } from '@/pages/campaigns/components/CampaignsFilter/constants';

export enum TabNameIndexMapping {
  CALLER_SETUP = 0,
  SCRIPT_PREVIEW = 1,
  CONTRACT_REVIEW = 2,
  PAYMENT_DETAILS = 3,
}

export const TabNameIndexMapperBackend = {
  [TabNameIndexMapping.CALLER_SETUP]: 0,
  [TabNameIndexMapping.SCRIPT_PREVIEW]: 1,
  [TabNameIndexMapping.CONTRACT_REVIEW]: 2,
  [TabNameIndexMapping.PAYMENT_DETAILS]: 3,
};

export const TabNames = [
  'Campaign setup',
  'Calling Script',
  'Script Review',
  'Checkout',
];

export enum ScriptStatusMapping {
  DRAFTING = 0,
  DRAFTED = 1,
  MANUAL_CREATION = 2,
  IN_REVIEW = 3,
  APPROVED = 4,
}

export enum DiyScriptStatus {
  inReview = 0,
  accepted = 1,
  rejected = 2,
}

export enum DiyQuestionPermissionsType {
  none = 'None',
  partial = 'Partial',
  complete = 'Complete',
}

export const campaignFiltersAfterSaveAndClose = {
  campaign_id: [],
  campaign_use_case: [],
  years: [],
  campaign_status: 'drafting',
  integration_status: defaultIntegrationStatusValue,
};

export const campaignFiltersAfterSubmitForReview = {
  campaign_id: [],
  campaign_use_case: [],
  years: [],
  campaign_status: 'underReview',
  integration_status: defaultIntegrationStatusValue,
};

export const CampaignSource = {
  customCampaignSource: 'custom_campaign',
  templateCampaignSource: 'template_campaign',
};

export enum BusinessIntegration {
  shopify = 'shopify',
  shiprocket = 'shiprocket',
}

export const AlllowedIntegrationSourcesForNonPabblySMEFlow = [
  BusinessIntegration.shiprocket as string,
  BusinessIntegration.shopify as string,
];

export const supportEmail = 'support@squadstack.com';

export const additionalWorkflowCredits = 1999.0;

export const freeTrialSubscriptionPlanId = '65af5f5ba49eea0f6af8c365';

export const shiprocketLoginURL = 'https://app.shiprocket.in/login';

export enum PlanType {
  ivr = 'ivr',
  call = 'call',
}

export enum PaymentType {
  subscription = 'subscription',
  topup = 'topup',
}
