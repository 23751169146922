import mixpanel from 'mixpanel-browser';
import { BaseAnalyticsInterface, EventProperties } from '../AnalyticsService';
import { serviceNames } from '../AnalyticsService/constants';
import { UserProperties } from '../AnalyticsService/types';

class MixpanelService implements BaseAnalyticsInterface {
  name: string;

  constructor() {
    if (process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN as string);
      if (!(process.env.NODE_ENV === 'production')) {
        mixpanel.disable();
      }
    }
    this.name = serviceNames.mixpanel;
  }

  identify(userId: string, userProperties?: UserProperties): void {
    //? Identify a user with unique id
    mixpanel.identify(userId);
    if (userProperties) {
      const { userType, businessName, firstName, lastName } = userProperties;
      //? Set super properties (https://developer.mixpanel.com/docs/javascript#super-properties)
      mixpanel.register({
        user_type: userType,
        business_name: businessName,
        ...(firstName
          ? {
              name: `${firstName}${lastName ? ` ${lastName}` : ''}`,
            }
          : {}),
      });
      //? Set properties on user profile
      mixpanel.people.set({
        ...(firstName
          ? {
              $name: `${firstName}${lastName ? ` ${lastName}` : ''}`,
            }
          : {}),
        $email: businessName,
        user_type: userType,
        user_id: userId,
      });
    }
  }

  trackEvent(name: string, properties?: EventProperties): void {
    mixpanel.track(name, properties);
  }

  page(name: string, properties?: Partial<EventProperties>): void {
    mixpanel.track(`User lands on ${name} page`, {
      actor: 'User',
      verb: 'lands',
      direct_object: `on ${name} page`,
      context: 'of customer dashboard',
      ...properties,
    });
  }

  timeEvent(eventName: string): void {
    mixpanel.time_event(eventName);
  }

  reset(): void {
    mixpanel.reset();
  }
}

export { MixpanelService };
