import { Box, CircularLoader, Text } from '@/lib/components';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CSSProperties } from 'react';
import { theme } from '@/lib/theme';
import { translate } from '@/lib/transalation';

type Data<T> = Array<{ id: number | string } | T>;

export type InfiniteListProps<T> = {
  data: Data<T>;
  height?: number | string;
  renderItem: (data: unknown, index: number) => JSX.Element;
  onEndReached?: () => void;
  hasMore: boolean;
  isLoading: boolean;
  loader?: () => JSX.Element;
  noDataComponent?: () => JSX.Element;
  style?: CSSProperties;
};

const DefaultLoaderComponent = () => {
  return (
    <Box display={'flex'} justifyContent="center" alignItems="center">
      <CircularLoader />
    </Box>
  );
};

const DefaultNoDataComponent = () => {
  return (
    <Box flex={1} display="flex" justifyContent="center" alignItems="center">
      <Text
        variant="bodyRegular"
        sx={{ color: theme.baseTheme.palette.typography.primaryTextColor }}>
        {translate('infiniteList.defaultNoDataComponent')}
      </Text>
    </Box>
  );
};

export const InfiniteList = <T,>({
  data,
  height,
  renderItem,
  onEndReached,
  hasMore,
  isLoading,
  loader = DefaultLoaderComponent,
  noDataComponent = DefaultNoDataComponent,
  style = {},
}: InfiniteListProps<T>) => {
  const loadMoreItems = !isLoading && onEndReached ? onEndReached : () => {};

  if (data.length === 0 && !isLoading) {
    return noDataComponent();
  } else if (data.length === 0 && isLoading) {
    return loader();
  } else {
    return (
      <InfiniteScroll
        height={height}
        dataLength={data.length}
        next={loadMoreItems}
        hasMore={hasMore}
        loader={loader()}
        style={style}>
        {data.map((i, index) => renderItem(data[index], index))}
      </InfiniteScroll>
    );
  }
};
