import { AnalyticsService } from '@/lib/analytics';
import { Button, Text } from '@/lib/components';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NewCampaignButton = (props: {
  toggleAddCampaignModal: (status: boolean) => void;
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const handleButtonClick = () => {
    AnalyticsService.trackEvent('User clicks on New campaign CTA', {
      actor: 'User',
      verb: 'clicks',
      direct_object: 'on New campaign CTA',
      context: 'in navbar of All Campaigns page',
      context_metadata: 'of customer dashboard',
    });
    props.toggleAddCampaignModal(true);
  };

  return (
    <Button
      onClick={handleButtonClick}
      sx={{
        backgroundColor:
          theme.baseTheme.palette.components.button.backgroundColor,
        borderRadius: '4px',
        py: '4px',
        px: '8px',
      }}>
      <Text
        width="100%"
        color={theme.baseTheme.palette.typography.contrastTextColor}
        variant="captionBold">
        {t('campaigns.allCampaigns.newCampaignButton.title')}
      </Text>
    </Button>
  );
};
