import { APIService } from './ApiService';

export class ResourcesService {
  static getResources() {
    return APIService.createGetRequest('voice/dashboard/embed-doc/');
  }

  static postPermission(id?: string) {
    return APIService.createPostRequest(`voice/dashboard/embed-doc/${id}/`, {
      getData: ({ permission_type }) => {
        return {
          permission_type: permission_type,
        };
      },
    });
  }
}
