import { APIService } from './ApiService';

export type FAQElement = {
  id: number;
  question: string;
  answer: string;
  answer_type: string;
  media_urls: string[];
  answer_rich: string;
  add_to_training: boolean;
  is_new?: boolean;
};

export class CampaignService {
  static getFilterOptions(type: 'campaign' | 'campaign-use-case' | 'year') {
    return APIService.createGetRequest(
      `voice/dashboard/campaigns/get-filter-options/${type}/`,
      {
        isAuthenticated: true,
      }
    );
  }

  static campaignsList(page: number) {
    return APIService.createPostRequest(
      `voice/dashboard/campaigns/v2/?page=${page}`,
      {
        isAuthenticated: true,
        getData: ({ filters }) => {
          return {
            filters,
          };
        },
      }
    );
  }

  static changeCampaignStatus() {
    return APIService.createPostRequest(
      `voice/dashboard/campaigns/change-campaign-status/`,
      {
        getData: ({ campaignId }) => {
          return {
            id: campaignId,
          };
        },
      }
    );
  }

  static createCampaign() {
    return APIService.createPostRequest(
      `voice/dashboard/campaigns/api/create-campaign/`,
      {
        getData: ({ campaignName }) => {
          return {
            name: campaignName,
          };
        },
      }
    );
  }

  static getImportHistory(page: number) {
    return APIService.createGetRequest(
      `voice/dashboard/import/v2/history/?page=${page}`
    );
  }

  static getCampaignInfo(campaignId: number) {
    return APIService.createGetRequest(
      `voice/dashboard/campaigns/api/campaign/${campaignId}`
    );
  }

  static getCampaignInfoV2(campaignId: number) {
    return APIService.createGetRequest(
      `voice/dashboard/campaigns/api/campaign/v2/${campaignId}`
    );
  }

  static getCallerTypes(industry: string) {
    return APIService.createGetRequest(
      `voice/dashboard/campaigns/caller-types/v2/?industry=${industry}`
    );
  }

  static getCallerSetupFilters(campaignId: number) {
    return APIService.createGetRequest(
      `voice/dashboard/campaigns/${campaignId}/caller-setup/filters/v2`
    );
  }

  static updateCampaignInfo() {
    return APIService.createPatchRequest(
      `voice/dashboard/campaigns/api/caller-setup/`,
      {
        getData: (data) => {
          return {
            ...data,
          };
        },
      }
    );
  }

  static updateAdditionalInfo(campaignId: number) {
    return APIService.createPostRequest(
      `voice/dashboard/campaigns/api/additional-info/${campaignId}/`,
      {
        getData: (data) => {
          return data;
        },
      }
    );
  }

  static updateDiyCampaignInfo() {
    return APIService.createPatchRequest(
      `voice/dashboard/campaigns/diy-caller-setup/`,
      {
        getData: (data) => {
          return {
            ...data,
          };
        },
      }
    );
  }

  //#region Plug n play (previously SquadGo)
  // TODO: Move this into separate service, since API names are quite similar
  static getCampaignsList(page: number, tabSelected: string) {
    return APIService.createGetRequest(
      `voice/dashboard/plug-n-play/campaign-list?page=${page}&tab_selected=${tabSelected}`
    );
  }

  static getFilteredCampaignsList() {
    return APIService.createPostRequest(
      `voice/dashboard/plug-n-play/campaign-list/filter/`,
      {
        getData: (filters) => {
          return {
            ...filters,
          };
        },
      }
    );
  }

  static getCampaignFilterOptions(type: 'campaign-use-case') {
    return APIService.createGetRequest(
      `voice/dashboard/plug-n-play/campaign-list/get-filter-options/${type}/`,
      {
        isAuthenticated: true,
      }
    );
  }

  static getCustomCampaignFilterOptions(type: 'custom-campaign-use-case') {
    return APIService.createGetRequest(
      `voice/dashboard/plug-n-play/campaign-list/get-custom-campaign-filter-options/${type}/`,
      {
        isAuthenticated: true,
      }
    );
  }

  static approveCampaignScript(campaignId: number) {
    return APIService.createPatchRequest(
      `voice/dashboard/campaigns/approve_script/${campaignId}/`,
      {
        getData: (data) => {
          return {
            ...data,
          };
        },
      }
    );
  }

  static approveCampaignScriptV2(campaignId: number) {
    return APIService.createPatchRequest(
      `voice/dashboard/plug-n-play/v2/approve-diy-script/${campaignId}/`,
      {
        getData: (data) => {
          return {
            ...data,
          };
        },
      }
    );
  }

  static submitCampaignForReview(campaignId: number) {
    //TODO: Fix name to submitCampaignScriptForReview
    return APIService.createPostRequest(
      `voice/dashboard/plug-n-play/${campaignId}/submit-campaign-for-review/`
    );
  }

  static updateSmeCampaignInfo() {
    return APIService.createPatchRequest(
      `voice/dashboard/plug-n-play/api/campaign-setup/`,
      {
        getData: (data) => {
          return {
            ...data,
          };
        },
      }
    );
  }
  //end region

  //#region DIY builder
  static getCampaignFaqList(scriptId: number) {
    return APIService.createGetRequest(
      `campaign_setup/v1/diy-script/${scriptId}/diy_faq/`
    );
  }

  static createCampaignFaq(scriptId: number) {
    return APIService.createPostRequest(
      `campaign_setup/v1/diy-script/${scriptId}/diy_faq/`,
      {
        getData: (data) => {
          return {
            ...data,
          };
        },
      }
    );
  }

  static updateCampaignFaq(scriptId: number) {
    return APIService.createPutRequest(
      `campaign_setup/v1/diy-script/${scriptId}/diy_faq/id/`,
      {
        getData: (data) => {
          return {
            ...data,
          };
        },
        getPathParams: (data) => {
          return {
            id: (data as FAQElement).id,
          };
        },
      }
    );
  }

  static deleteCampaignFaq(scriptId: number, isExternal: boolean) {
    return APIService.createDeleteRequest(
      `campaign_setup/v1/diy-script/${scriptId}/diy_faq/id/`,
      {
        getPathParams: (data) => {
          return {
            id: (data as FAQElement).id,
          };
        },
        getQueryParams: () => {
          return {
            is_external: isExternal,
          };
        },
      }
    );
  }

  static updateFeCheckpoint(campaignId: number) {
    return APIService.createPostRequest(
      `voice/dashboard/campaigns/${campaignId}/fe-checkpoint/`,
      {
        getData: ({ checkpoint }) => {
          return {
            last_fe_checkpoint: checkpoint,
          };
        },
      }
    );
  }

  static generateScript(campaignId: number) {
    return APIService.createPatchRequest(
      `voice/dashboard/campaigns/${campaignId}/generate-script/`,
      {
        getData: ({ checkpoint, generateScript }) => {
          return {
            last_fe_checkpoint: checkpoint,
            generate_script: generateScript,
          };
        },
      }
    );
  }
  //#endregion
}
