import { useTheme } from '@mui/material';
import { Box, Card } from '../components';
import {
  SquadstackLoaderMP4,
  SquadstackLoaderWebM,
} from '@/public/assets/videos';

export const SquadstackLoader = ({
  height,
  width,
}: {
  height?: string;
  width?: string;
}) => {
  const theme = useTheme();
  return (
    <Card
      style={{
        height: height ? 'auto' : '100%',
        width: width ? 'auto' : '100%',
      }}>
      <Box
        display="flex"
        color={theme.baseTheme.palette.components.circularLoader.color}
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        border="none"
        height={height ? height : '100%'}
        width={width ? width : '100%'}>
        <video width={200} height={200} autoPlay loop muted playsInline>
          <source src={SquadstackLoaderWebM} type="video/webm" />
          <source src={SquadstackLoaderMP4} type="video/mp4" />
        </video>
      </Box>
    </Card>
  );
};
