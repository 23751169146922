type AppHeaders = {
  'x-csrftoken': string;
};

export type SWRMutationConfig = {
  url: string;
  method: HttpMethods;
  isAuthenticated?: boolean;
  optimisticData?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData?: (...args: any[]) => any;
  getQueryParams?: (...args: unknown[]) => Record<string, unknown>;
  getPathParams?: (...args: unknown[]) => Record<string, unknown>;
  revalidate?: boolean;
  populateCache?: boolean;
  rollbackOnError?: boolean;
  throwOnError?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSuccess?: (data: any) => void;
  onError?: (err: {
    status: number;
    displayMessage: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any;
  }) => void;

  errorRetryInterval?: number;
  errorRetryCount?: number;
  loadingTimeout?: number;
  focusThrottleInterval?: number;
  dedupingInterval?: number;
  refreshInterval?: number;
  refreshWhenHidden?: boolean;
  refreshWhenOffline?: boolean;
  revalidateOnFocus?: boolean;
  revalidateOnReconnect?: boolean;
  revalidateOnMount?: boolean;
  revalidateIfStale?: boolean;
  shouldRetryOnError?: boolean | ((err: Error) => boolean);
  keepPreviousData?: boolean;
  suspense?: boolean;
};

export interface HttpRequest {
  url: string;
  method: HttpMethods;
  body?: string | FormData;
  headers?: Headers | AppHeaders;
}

export interface HttpResponse {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  body: any;
  status: number;
}

export interface HttpError {
  request: HttpRequest;
  data: Record<string, unknown>;
  status: number;
  message: string;
}

export enum HttpMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  PATCH = 'PATCH',
  DELETE = 'DELETE',
}
