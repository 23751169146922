import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  useTheme,
} from '@mui/material';
import { Loader } from '../Loader';
import React from 'react';
import { Box } from '@/lib/components';

type BaseButtonPops = Omit<MuiButtonProps, 'variant' | 'size' | 'disabled'>;

type ThemeButtonProps = {
  variant?: 'standard' | 'outlined';
  disabled?: boolean;
  loadingOptions?: {
    status: boolean;
    showLoader: boolean;
    size?: number;
  };
};

export type ButtonProps = BaseButtonPops & ThemeButtonProps;

export const Button = ({
  variant = 'standard',
  disabled = false,
  loadingOptions = {
    status: false,
    showLoader: true,
    size: 20,
  },
  ...props
}: ButtonProps) => {
  const theme = useTheme();
  const muiVariant = variant === 'standard' ? 'contained' : variant;
  const sxProps = props?.sx as React.CSSProperties;
  const backgroundColor =
    sxProps && sxProps.backgroundColor
      ? sxProps.backgroundColor
      : theme.baseTheme.palette.components.button.backgroundColor;

  return (
    <MuiButton
      {...props}
      sx={{
        '&:hover, &.Mui-focusVisible': {
          backgroundColor: backgroundColor,
          color: theme.baseTheme.palette.typography.contrastTextColor,
        },
        ...props.sx,
      }}
      variant={muiVariant}
      size="small"
      disabled={disabled || loadingOptions?.status}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}>
        {props.children}
        {loadingOptions?.status && loadingOptions?.showLoader ? (
          <Loader
            size={loadingOptions?.size}
            sx={{ marginLeft: 1 }}
            color="secondary"
            thickness={6}
          />
        ) : null}
      </Box>
    </MuiButton>
  );
};
