import { Card, Text } from '@/lib/components';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRespectiveDateTime } from '@/lib/utils/DateUtils';
import { theme } from '@/lib/theme';

type ActivityCardProps = {
  date: string;
  message: string;
  timeZone: string;
};

export const ActivityCard: React.FC<ActivityCardProps> = ({
  date,
  message,
  timeZone,
}) => {
  const { t } = useTranslation();
  return (
    <Card sx={{ pt: 1, pb: 2, my: 2, ml: 1, mr: 2 }}>
      <Box
        sx={{
          px: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Text
          variant="captionSemibold"
          sx={{
            color: theme.baseTheme.palette.typography.warning600,
            padding: theme.spacing(0.75, 1),
          }}>
          {t('notificationHistory.activityCard.title')}
        </Text>
        <Text
          variant="tinyRegular"
          sx={{
            color: theme.baseTheme.palette.typography.neutrals300,
            padding: 1,
          }}>
          {getRespectiveDateTime(date, timeZone)}
        </Text>
      </Box>
      <Box sx={{ px: 2 }}>
        <Text variant="captionRegular" sx={{ textAlign: 'justify' }}>
          {message}
        </Text>
      </Box>
    </Card>
  );
};
