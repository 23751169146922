import { useAuthContext } from '@/lib/contexts';
import { useCallback, useEffect } from 'react';

export const useZohoChatBotScript = (url?: string, widgetCode?: string) => {
  const { state } = useAuthContext();

  const initializeZohoChat = useCallback(
    (name: string, email: string) => {
      const script = document.createElement('script');
      script.setAttribute('type', 'text/javascript');

      const code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || 
    {widgetcode: "${widgetCode}", values:{}, ready:function(){
        $zoho.salesiq.floatbutton.position("bottomright");
        $zoho.salesiq.visitor.name("${name}");
        $zoho.salesiq.visitor.email("${email}");
    }};
    var d=document;s=d.createElement("script");
    s.type="text/javascript";
    s.id="zsiqscript";
    s.defer=true;
    s.src="${url}";
    t=d.getElementsByTagName("script")[0];
    t.parentNode.insertBefore(s,t);`;

      script.appendChild(document.createTextNode(code));
      document.body.appendChild(script);
      return script;
    },
    [url, widgetCode]
  );

  useEffect(() => {
    let script: HTMLScriptElement | null = null;
    let username = 'User';
    if (state.user) {
      if (state.user.first_name) {
        username = state.user.first_name;
      }
      script = initializeZohoChat(username, state.user.email);
    }
    return () => {
      script && document.body.removeChild(script);
      const customcss = document.getElementById('zsiqcustomcss');
      customcss && customcss.remove();

      const salesiqElem = document.querySelector('[data-id="zsalesiq"]');
      salesiqElem && salesiqElem.remove();

      const salesiqScript = document.getElementById('zsiqscript');
      salesiqScript && salesiqScript.remove();

      const siqEmbed = document.querySelector('.siqembed');
      siqEmbed && siqEmbed.remove();

      const embedScript = document.getElementById('siq-embed-script');
      embedScript && embedScript.remove();

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const siqHandler = (window as any)?.$ZSIQWidgetUI;
      siqHandler &&
        window.removeEventListener('resize', siqHandler.handleResizeEvents);

      [
        '$zoho',
        '$ZSIQLSDB',
        '$ZSIQCookie',
        '$zsalobj',
        '$zsalobjrestricted',
        '$ZSIQUtil',
        '$ZSIQLicence',
        '$UTSHandler',
        '$ZSIQUTS',
        '$ZSIQUTSAction',
        '$ZSIQChat',
        '$ZSIQAnalytics',
        '$ZSIQAutopick',
        '$zohosq',
        '$zcb',
        '$zv',
        '$zlm',
        '$zlch',
        '$zla',
        '$ZSIQ_UTSinitialized',
        '$ZSisThresholdExceeded',
        '$ZDestroyFloatData',
        '$ZNotifyTracking',
        '$ZShandleEvent',
        '$ZSIQChatWindow',
        '$ZSIQTemplate',
        '$ZSIQWidgetUI',
        '$ZSIQWidget',
      ].forEach((key) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any)[key] = undefined;
      });
    };
  }, [state.user, initializeZohoChat]);
};
