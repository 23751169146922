import {
  AnalyticsRoutes,
  BillingRoutes,
  CampaignRoutes,
  DeveloperLogRoutes,
  HelpAndSupportRoutes,
  IntelAssistRoutes,
  ProfileRoutes,
  ReportsRoutes,
  ResourcesRoutes,
  SettingsRoutes,
  PlugNPlayCampaignRoutes,
  Agents,
  ApiIntegration,
} from '@/pages/routes/routes';
import { CustomHeaderTitle } from '../CustomHeaderTitle';
import { CampaignTabs } from '../CampaignsTabs';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useAuthContext } from '@/lib/contexts';
import { SettingsTabs } from '../SettingsTabs';
import { ResourcesItemHeader } from '../ResourcesItemHeader';
import { IntelAssistHeader } from '../IntelAssistHeader';
import { PaymentSuccessHeader } from '../PaymentSuccessHeader';

export const TopBarHeader = () => {
  const { route } = useRouter();
  const { state } = useAuthContext();
  const { t } = useTranslation();

  if (state.user) {
    if (
      (route === CampaignRoutes.CAMPAIGNS && state.user.has_campaign) ||
      route === CampaignRoutes.LEADS ||
      route === CampaignRoutes.IMPORT_HISTORY ||
      route === CampaignRoutes.LEAD_JOURNEY
    ) {
      return <CampaignTabs />;
    } else if (route === AnalyticsRoutes.ANALYTICS) {
      return <CustomHeaderTitle title={t('analytics.title')} />;
    } else if (route === BillingRoutes.BILLING) {
      return <CustomHeaderTitle title={t('billing.title')} />;
    } else if (route === HelpAndSupportRoutes.FAQS) {
      return <CustomHeaderTitle title={t('helpAndSupport.faq.title')} />;
    } else if (
      route === SettingsRoutes.ACCOUNT ||
      route === SettingsRoutes.INTEGRATIONS ||
      route === SettingsRoutes.SUBSCRIPTIONS ||
      route === SettingsRoutes.TEAMS
    ) {
      return <SettingsTabs />;
    } else if (route === ProfileRoutes.ACTIVITY_LOGS) {
      return <CustomHeaderTitle title={t('profile.activityLogs')} />;
    } else if (route === ReportsRoutes.LEAD_JOURNEY_REPORTS) {
      return <CustomHeaderTitle title={t('leadJourneyReport.reports')} />;
    } else if (route === DeveloperLogRoutes.LOGS) {
      return <CustomHeaderTitle title={t('logs.title')} />;
    } else if (route === ResourcesRoutes.Resources) {
      return <CustomHeaderTitle title={t('resources.title')} />;
    } else if (route === ResourcesRoutes.Resources_ITEM) {
      return <ResourcesItemHeader />;
    } else if (route === IntelAssistRoutes.HOME) {
      return <IntelAssistHeader />;
    } else if (route === Agents.AGENTS) {
      return <CustomHeaderTitle title={t('agents.title')} />;
    } else if (route === PlugNPlayCampaignRoutes.PAYMENT_SUCCESS) {
      return <PaymentSuccessHeader />;
    } else if (route === ApiIntegration.API_INTEGRATION) {
      return <CustomHeaderTitle title={t('sme.apiIntegration.title')} />;
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
