import { AuthContext } from '@/lib/contexts';
import { createEmotionCache, inter, theme } from '@/lib/theme';
import { CacheProvider, EmotionCache } from '@emotion/react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Toaster } from 'react-hot-toast';
import './i18nConfig';
import 'react-h5-audio-player/lib/styles.css';
import '@/styles/audio_player_styles.css';
import { GoogleAnalytics, MicrosoftClarity } from '@/lib/analytics';
import { useNextRouterAnalytics } from '@/lib/analytics';
import { Logger } from '@/lib/logger';
import { Monitoring } from '@/lib/monitoring/MonitoringService';

const clientSideEmotionCache = createEmotionCache();

const __DEV__ = process.env.NODE_ENV === 'development';
const DATADOG_SERVICE = 'customer-portal';
const DATADOG_SITE = 'datadoghq.com';

Logger.init({
  datadog: {
    enable: !__DEV__,
    service: DATADOG_SERVICE,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
    site: DATADOG_SITE,
    env: process.env.NODE_ENV,
    forwardErrorsToLogs: false,
    sessionSampleRate: 100,
  },
});

Monitoring.init({
  datadog: {
    enable: !__DEV__,
    applicationId: process.env.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID as string,
    clientToken: process.env.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN as string,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: process.env.NODE_ENV,
    sessionSampleRate: 5,
    sessionReplaySampleRate: 0,
    trackResources: true,
    trackLongTasks: true,
    trackUserInteractions: true,
    trackFrustrations: true,
  },
});

export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

export default function MyApp(props: MyAppProps) {
  const {
    Component,
    emotionCache = clientSideEmotionCache,
    router: _router,
    pageProps,
  } = props;

  useNextRouterAnalytics();

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
        <title>SquadStack</title>
      </Head>
      <main className={inter.className}>
        <GoogleAnalytics
          measurementId={`${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_KEY}`}
          enable={process.env.NEXT_PUBLIC_ENABLE_GOOGLE_ANALYTICS === 'true'}
        />
        <GoogleAnalytics
          measurementId={`${process.env.NEXT_PUBLIC_PNP_GOOGLE_ANALYTICS_KEY}`}
          enable={
            process.env.NEXT_PUBLIC_PNP_ENABLE_GOOGLE_ANALYTICS === 'true'
          }
        />
        <MicrosoftClarity
          projectId={`${process.env.NEXT_PUBLIC_MICROSOFT_CLARITY_KEY}`}
          enable={process.env.NEXT_PUBLIC_ENABLE_MICROSOFT_CLARITY === 'true'}
        />
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <AuthContext>
            <Component {...pageProps} />
            <Toaster
              containerStyle={{
                marginTop: theme.baseTheme.defaultValues.APPBAR_HEIGHT,
              }}
              position="top-right"
            />
          </AuthContext>
        </ThemeProvider>
      </main>
    </CacheProvider>
  );
}
