import { StatusBadgeVariant } from '@/lib/components';
import { Tab } from '@/lib/components/core/Tabs/TableTab';
import { translate } from '@/lib/transalation';
import { subtractTime } from '@/lib/utils/DateUtils';

export enum CallTabOptionValues {
  callDetails = 'callDetails',
  transcript = 'transcript',
  auditFindings = 'auditFindings',
  acpt = 'acpt',
  feedback = 'feedback',
  qna = 'qna',
}

export const CallTabOptions: Tab<CallTabOptionValues>[] = [
  {
    value: CallTabOptionValues.callDetails,
    label: translate('intelAssist.callTabs.callDetails'),
  },
  {
    value: CallTabOptionValues.transcript,
    label: translate('intelAssist.callTabs.transcript'),
  },
  {
    value: CallTabOptionValues.auditFindings,
    label: translate('intelAssist.callTabs.auditFindings'),
  },
  {
    value: CallTabOptionValues.acpt,
    label: translate('intelAssist.callTabs.acpt'),
  },
  {
    value: CallTabOptionValues.qna,
    label: translate('intelAssist.callTabs.qna'),
  },
  {
    value: CallTabOptionValues.feedback,
    label: translate('intelAssist.callTabs.feedback'),
  },
];

export const DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_START_DATE = subtractTime(new Date(), 21, 'day') as Date;
export const DEFAULT_END_DATE = subtractTime(new Date(), 7, 'day') as Date;

// Feedback Tracker
export enum FeedbackStatus {
  OPEN = 'Open',
  WIP = 'WIP',
  CLOSED = 'Closed',
  BLOCKED = 'Blocked',
  NOT_ACTIONABLE = 'Not Actionable',
}

export const getFeedbackStatusColor = (
  status: FeedbackStatus
): StatusBadgeVariant => {
  switch (status) {
    case FeedbackStatus.OPEN:
      return StatusBadgeVariant.BLUE;
    case FeedbackStatus.WIP:
      return StatusBadgeVariant.YELLOW;
    case FeedbackStatus.CLOSED:
      return StatusBadgeVariant.GRAY;
    case FeedbackStatus.BLOCKED:
      return StatusBadgeVariant.RED;
    case FeedbackStatus.NOT_ACTIONABLE:
      return StatusBadgeVariant.GRAY;
    default:
      return StatusBadgeVariant.GRAY;
  }
};

export enum IntelAssistTabLabels {
  INTEL_ASSIST = 'Intel Assist',
  FEEDBACK_TRACKER = 'Feedback Tracker',
  REPORT = 'Report',
}

export const IntelAssistTabOptions: Tab<IntelAssistTabLabels>[] = [
  {
    value: IntelAssistTabLabels.INTEL_ASSIST,
    label: translate('intelAssist.intelAssistTab'),
  },
  {
    value: IntelAssistTabLabels.FEEDBACK_TRACKER,
    label: translate('intelAssist.feedbackTrackerTab'),
  },
  {
    value: IntelAssistTabLabels.REPORT,
    label: 'Report',
  },
];

export type IntelAssistTabsStoreType = {
  selectedTab: Tab<IntelAssistTabLabels>;
  setSelectedTab: (tab: Tab<IntelAssistTabLabels>) => void;
};

export const estimatedRowHeight = 56;
