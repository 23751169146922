import { AuthenticationService } from '@/lib/apis/AuthenticationService';
import { useAuthenticatedSWRMutation } from '@/lib/network';
import {
  deleteCookieValueByName,
  getCookieValueByName,
} from '@/lib/utils/cookie';
import { ProtectedRoute } from '@/pages/routes';
import { useRouter } from 'next/router';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import toast from 'react-hot-toast';
import type {
  Action,
  AuthContextProps,
  AuthContextValueConfig,
  State,
} from './types';
import { SignOutTypes } from './types';
import { useTranslation } from 'react-i18next';
import {
  AUTH_CSRF_HEADER,
  NEXT_AUTH_HEADER,
  IS_SME,
} from '@/lib/constants/auth';
import {
  UserInfoResponse,
  UserTypes,
} from '@/lib/layout/SidebarLayout/components/UserInfo/types';
import {
  removeGoogleAnalyticsUserProperties,
  removeCustomMicrosoftClarityTags,
  AnalyticsService,
} from '@/lib/analytics';
import {
  clearLocalStorageExceptForKeys,
  getStoredFilterKeys,
} from '@/lib/utils/Utils';
import * as Sentry from '@sentry/nextjs';
import { Logger } from '@/lib/logger';
import { Monitoring } from '@/lib/monitoring/MonitoringService';
import { identifyUsersOnThirdPartyServices } from '@/lib/layout/SidebarLayout/utils';

enum Actions {
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  USER = 'USER',
  SIGNOUT_TYPE = 'SIGNOUT_TYPE',
}

const AuthContextConfig = createContext<AuthContextValueConfig>({
  state: { isAuthenticated: null, user: null, signOutType: null },
  signIn: () => null,
  signOut: () => null,
  checkAuthentication: () => false,
  setAuthenticationState: () => null,
  setUserInfo: () => null,
  updateUserInfo: () => null,
});

const authReducer = (prevState: State, action: Action): State => {
  switch (action.type) {
    case Actions.LOGIN: {
      return { ...prevState, isAuthenticated: true };
    }
    case Actions.LOGOUT: {
      return { ...prevState, isAuthenticated: false, user: null };
    }
    case Actions.USER: {
      return {
        ...prevState,
        user: action.data as UserInfoResponse,
      };
    }
    case Actions.SIGNOUT_TYPE: {
      return { ...prevState, signOutType: action.data as SignOutTypes };
    }
    default: {
      return prevState;
    }
  }
};

const unIdentifyUsersOnThirdPartyServices = () => {
  if (process.env.NEXT_PUBLIC_ENABLE_GOOGLE_ANALYTICS === 'true') {
    removeGoogleAnalyticsUserProperties();
  }
  if (process.env.NEXT_PUBLIC_PNP_ENABLE_GOOGLE_ANALYTICS === 'true') {
    removeGoogleAnalyticsUserProperties(UserTypes.SME_CUSTOMER);
  }
  if (process.env.NEXT_PUBLIC_ENABLE_MICROSOFT_CLARITY === 'true') {
    removeCustomMicrosoftClarityTags();
  }
  if (process.env.NEXT_PUBLIC_ENABLE_MIXPANEL === 'true') {
    AnalyticsService.reset();
  }
  if (process.env.NEXT_PUBLIC_SENTRY_ENABLED === 'true') {
    Sentry.setUser(null);
  }
  if (process.env.NEXT_PUBLIC_ENABLE_DATADOG_LOGGER === 'true') {
    Logger.clearUser();
  }
  if (process.env.NEXT_PUBLIC_ENABLE_DATADOG_RUM === 'true') {
    Monitoring.clearUser();
  }
};

const AuthContext = ({ children }: AuthContextProps) => {
  const [state, dispatch] = useReducer(authReducer, {
    isAuthenticated: null,
    user: null,
    signOutType: null,
  });

  const router = useRouter();
  const { t } = useTranslation();

  const { trigger: triggerLogout } = useAuthenticatedSWRMutation({
    ...AuthenticationService.logout(),
    onSuccess: () => {
      handleLogout();
    },
    onError: () => {
      toast.error(t('somethingWentWrong'));
    },
  });

  const handleLogout = useCallback(() => {
    clearLocalStorageExceptForKeys(getStoredFilterKeys());
    deleteCookieValueByName(NEXT_AUTH_HEADER);
    deleteCookieValueByName(AUTH_CSRF_HEADER);
    unIdentifyUsersOnThirdPartyServices();
    dispatch({ type: Actions.LOGOUT });
  }, []);

  const contextValue = useMemo(
    () => ({
      signIn: (csrftoken: string) => {
        localStorage.setItem(AUTH_CSRF_HEADER, csrftoken);
        dispatch({ type: Actions.LOGIN });
      },
      signOut: (type: SignOutTypes) => {
        dispatch({ type: Actions.SIGNOUT_TYPE, data: type });
        if (type === SignOutTypes.SIGN_OUT_BUTTON) {
          triggerLogout();
        } else if (type === SignOutTypes.BAD_AUTH_API_ERR) {
          handleLogout();
        }
      },
      checkAuthentication: () => {
        const authCookie = getCookieValueByName(NEXT_AUTH_HEADER);
        return Boolean(authCookie);
      },
      setAuthenticationState: (isUserAuthenticated: boolean) => {
        if (isUserAuthenticated) {
          dispatch({ type: Actions.LOGIN });
        } else {
          dispatch({ type: Actions.LOGOUT });
        }
      },
      setUserInfo: (user: UserInfoResponse) => {
        if (user) {
          localStorage.setItem(IS_SME, `${user?.is_sme}`);
          localStorage.setItem(AUTH_CSRF_HEADER, user.token);
          dispatch({ type: Actions.USER, data: user });
          identifyUsersOnThirdPartyServices(user);
        }
      },
      updateUserInfo: (userInfo: UserInfoResponse) => {
        dispatch({ type: Actions.USER, data: userInfo });
      },
    }),
    [handleLogout, triggerLogout]
  );

  useEffect(() => {
    const checkAuth = () => {
      const isUserAuthenticated = contextValue.checkAuthentication();
      contextValue.setAuthenticationState(isUserAuthenticated);
    };
    checkAuth();
  }, [contextValue]);

  return (
    <AuthContextConfig.Provider value={{ state, ...contextValue }}>
      <ProtectedRoute router={router}>{children}</ProtectedRoute>
    </AuthContextConfig.Provider>
  );
};

const useAuthContext = () => useContext(AuthContextConfig);

export { AuthContext, useAuthContext };
