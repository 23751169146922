import {
  ButtonBase as MuiButtonBase,
  ButtonBaseProps as MuiButtonBaseProps,
} from '@mui/material';
import React from 'react';
import { LinkProps } from 'next/dist/client/link';

interface BaseButtonProps extends MuiButtonBaseProps {
  disabledCursor?: boolean;
  href?: string;
  target?: string;
  component?: React.ForwardRefExoticComponent<
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
      LinkProps & {
        children?: React.ReactNode;
      } & React.RefAttributes<HTMLAnchorElement>
  >;
}

export const ButtonBase = ({
  children,
  disabledCursor = false,
  sx = {},
  ...props
}: BaseButtonProps) => {
  return (
    <MuiButtonBase
      {...props}
      sx={{
        ...sx,
        ...(disabledCursor && {
          '&:disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
          },
        }),
      }}>
      {children}
    </MuiButtonBase>
  );
};
