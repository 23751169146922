import { theme } from '@/lib/theme';
import { useMediaQuery } from '@mui/material';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

const DRAWER_WIDTH_EXPANDED = 204;
const DRAWER_WIDTH_CLOSED = 68;

const SidebarContext = createContext({
  slimProfile: false,
  toggleSlimProfile: () => {},
  setSlimProfileState: (_val: boolean) => {},
  sidebarOpened: false,
  handleSidebarToggle: () => {},
  sidebarWidth: DRAWER_WIDTH_EXPANDED,
});

export const SidebarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const matchUpSm = useMediaQuery(theme.breakpoints.up('sm'));
  const [slimProfile, setSlimProfile] = useState(false);
  const toggleSlimProfile = useCallback(() => {
    setSlimProfile((prevValue) => !prevValue);
  }, []);

  const setSlimProfileState = useCallback((val: boolean) => {
    setSlimProfile(val);
  }, []);

  const [sidebarOpened, setSidebarOpened] = useState(false);
  const handleSidebarToggle = useCallback(() => {
    setSidebarOpened(!sidebarOpened);
  }, [sidebarOpened]);

  const sidebarWidth =
    matchUpSm && slimProfile ? DRAWER_WIDTH_CLOSED : DRAWER_WIDTH_EXPANDED;

  return (
    <SidebarContext.Provider
      value={{
        slimProfile,
        toggleSlimProfile,
        sidebarOpened,
        handleSidebarToggle,
        sidebarWidth,
        setSlimProfileState,
      }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
