import { datadogLogs } from '@datadog/browser-logs';
import {
  BaseLoggerInterface,
  DatadogConfig,
  LoggerUserProperties,
} from '../LoggerService/types';
import { serviceNames } from '@/lib/logger/LoggerService/constants';

export class DatadogLoggerService implements BaseLoggerInterface {
  name: string;

  constructor(config: DatadogConfig) {
    this.init(config);
    this.name = serviceNames.datadog;
  }

  init(config: DatadogConfig) {
    datadogLogs.init(config);
  }

  error(exception: Error, extra: object = {}): void {
    datadogLogs.logger.error(
      exception?.message ?? 'No message provided',
      extra
    );
  }

  info(message: string, extra: object = {}): void {
    datadogLogs.logger.info(message ?? 'No message provided', extra);
  }

  warning(message: string, extra: object = {}): void {
    datadogLogs.logger.warn(message ?? 'No message provided', extra);
  }

  setUser(userId: string, userProperties?: LoggerUserProperties): void {
    datadogLogs.setUser({ userId, userName: userProperties?.userName });
  }

  clearUser() {
    datadogLogs.clearUser();
  }
}
