import { HttpMethods, SWRMutationConfig } from '@/lib/network/types';
import { swrFetcher } from '@/lib/network/networkCall';
import { default as useNextSWR, mutate } from 'swr';
import { useAuthContext } from '@/lib/contexts';
import { SignOutTypes } from '@/lib/contexts/AuthContext/types';
import { BadAuthentication, TooManyRequests } from '@/lib/network/errors';
import toast from 'react-hot-toast';
import { translate } from '../transalation';

const defaultSWRMutationConfig: SWRMutationConfig = {
  url: '',
  method: HttpMethods.GET,
  revalidateOnFocus: false,
};

export const useAuthenticatedSWR = (config: SWRMutationConfig) => {
  const { signOut } = useAuthContext();

  const handleLogout = () => {
    signOut(SignOutTypes.BAD_AUTH_API_ERR);
  };

  const fetcher = (args: unknown) =>
    swrFetcher(
      {
        url: config.url,
        method: config.method,
        body:
          config.method === HttpMethods.GET
            ? undefined
            : config.getData
            ? JSON.stringify(config.getData(args))
            : '',
      },
      { ...config, isAuthenticated: true }
    );

  return useNextSWR(
    config.url,
    (url: string, args: unknown) => {
      return fetcher(args);
    },
    {
      ...defaultSWRMutationConfig,
      ...config,

      onError: (e) => {
        if (e instanceof BadAuthentication) {
          handleLogout();
        } else if (e instanceof TooManyRequests) {
          toast.error(translate('tooManyRequests'));
        } else if (config?.onError) {
          config?.onError(e);
        } else {
          toast.error(`Something went wrong! Please try again after sometime.`);
        }
      },
    }
  );
};

export const useSWR = (config: SWRMutationConfig) => {
  const fetcher = (args: unknown) =>
    swrFetcher(
      {
        url: config.url,
        method: config.method,
        body:
          config.method === HttpMethods.GET
            ? undefined
            : config.getData
            ? JSON.stringify(config.getData(args))
            : '',
      },
      { ...config, isAuthenticated: false }
    );

  return useNextSWR(
    config.url,
    (url: string, args: unknown) => {
      return fetcher(args);
    },
    {
      ...defaultSWRMutationConfig,
      ...config,
      onError: (e) => {
        if (e instanceof TooManyRequests) {
          toast.error(translate('tooManyRequests'));
        } else if (config?.onError) {
          config.onError(e);
        } else {
          toast.error(`Something went wrong! Please try again after sometime.`);
        }
      },
    }
  );
};

export const useSWRList = (queryList: SWRMutationConfig[]) => {
  const mutations = queryList.map(
    (config) => () =>
      mutate(config.url, () =>
        swrFetcher(
          {
            url: config.url,
            method: config.method,
          },
          { ...config, isAuthenticated: true }
        )
      )
        .then((v) => v)
        .catch((e) => ({
          error: e,
          key: config.url,
        }))
  );

  return useNextSWR(
    queryList.map((config) => config.url),
    () => Promise.all(mutations.map((v) => v())),
    {
      dedupingInterval: 1,
    }
  );
};
