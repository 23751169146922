import { styled } from '@mui/material/styles';
import { Tab, Tabs } from '@/lib/components';

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

interface StyledTabProps {
  label: string;
  key: number;
}

interface TableTabsProps {
  children?: React.ReactNode;
  value: string;
  onChange: (event: React.SyntheticEvent, newValue: string) => void;
}

export const StyledTab = styled((props: StyledTabProps) => (
  <Tab disableRipple {...props} />
))(({ theme }) => ({
  textTransform: 'none',
  color: '#323232',
  fontWeight: '600',
  padding: theme.spacing(0.5, 1),
  fontSize: '14px',
  lineHeight: '24px',
  minHeight: '32px',
  borderRadius: '4px',
  minWidth: 'inherit',
  marginRight: '4px',
  '&.Mui-selected': {
    color: theme.baseTheme.palette.typography.contrastTextColor,
    backgroundColor:
      theme.baseTheme.palette.components.button.topBarBackgroundColor,
    '&:hover': {
      color: theme.baseTheme.palette.typography.contrastTextColor,
      backgroundColor:
        theme.baseTheme.palette.components.button.topBarBackgroundColor,
    },
  },
  '&.Mui-focusVisible': {
    color: theme.baseTheme.palette.typography.contrastTextColor,
    backgroundColor:
      theme.baseTheme.palette.components.button.topBarBackgroundColor,
  },
  '&:hover': {
    backgroundColor: theme.baseTheme.palette.surfaces.primaryBackground,
  },
}));

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} />
))({
  minHeight: 'inherit',
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  '& .MuiTabs-indicatorSpan': {
    display: 'none',
  },
});

export const TableTabs = styled((props: TableTabsProps) => <Tabs {...props} />)(
  ({ theme }) => ({
    '& .MuiTabs-flexContainer': {
      borderBottom: '2px solid ',
      borderColor: theme.baseTheme.palette.typography.neutrals300,
    },
    '& .MuiTab-root': {
      padding: '4px 16px',
      textTransform: 'none',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '24px',
      '&.Mui-selected': {
        fontWeight: '600',
        color: theme.baseTheme.palette.typography.shades900,
      },
    },
    '& .MuiTabs-indicator': {
      height: '2px',
      backgroundColor: theme.baseTheme.palette.typography.shades900,
    },
  })
);
