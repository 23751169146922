import { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '@mui/material';
import {
  Box,
  Text,
  Button,
  Grid,
  Tooltip,
  IconButton,
  CircularLoader,
  ButtonBase,
} from '@/lib/components';
import { useAuthenticatedSWRMutation } from '@/lib/network';
import { FilterOptionsDataType, UseCaseDataType } from '../../type';
import { CampaignService } from '@/lib/apis/CampaignService';
import { CampaignRoutes } from '@/pages/routes/routes';
import { useTranslation } from 'react-i18next';
import { UseCaseList } from './UseCaseList';
import { CrossIcon, InfoIcon } from '@/lib/icons';
import { useAuthContext } from '@/lib/contexts';

type UseCaseSelectionModalProps = {
  handleClose: () => void;
};

export const UseCaseSelectionModal: React.FC<UseCaseSelectionModalProps> = ({
  handleClose,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const router = useRouter();
  const [useCaseList, setUseCaseList] = useState<UseCaseDataType>({});
  const [useCase, setUseCase] = useState<string>('');
  const useCaseListView = useMemo(() => {
    return useCaseList;
  }, [useCaseList]);
  const { state } = useAuthContext();

  const {
    trigger: triggerCampaignUseCaseFilterOptions,
    isMutating: isCampaignUseCaseFilterLoading,
  } = useAuthenticatedSWRMutation({
    ...CampaignService.getCampaignFilterOptions('campaign-use-case'),

    onSuccess: (data: { results: FilterOptionsDataType[] }) => {
      if (data && data.results) {
        const updatedUseCaseList: UseCaseDataType = {};
        data.results.forEach((result) => {
          if (!updatedUseCaseList[result.label])
            updatedUseCaseList[result.label] = [result];
          else updatedUseCaseList[result.label].push(result);
        });
        setUseCaseList(updatedUseCaseList);
      }
    },
  });

  useEffect(() => {
    triggerCampaignUseCaseFilterOptions();
  }, [triggerCampaignUseCaseFilterOptions]);

  const handleUseCaseChange = (useCase: string) => {
    setUseCase(useCase);
    localStorage.setItem(
      'campaignsListForSelectedUseCase',
      JSON.stringify(useCaseListView[useCase])
    );
  };

  const openViewScriptPage = () => {
    const selectedUseCase = useCaseListView[useCase];
    if (selectedUseCase[0].campaign_id)
      router.push({
        pathname: CampaignRoutes.EDIT_CAMPAIGN,
        query: {
          id: selectedUseCase[0].campaign_id.toString(),
          viewScript: false,
          isTemplate: true,
        },
      });
  };

  const handleCreateButtonClick = () => {
    openViewScriptPage();
  };

  return (
    <>
      {isCampaignUseCaseFilterLoading ? (
        <Box display="flex" justifyContent="space-around" alignItems="center">
          <CircularLoader />
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="flex-start"
          width="70%"
          maxHeight="90%"
          sx={{
            background: theme.baseTheme.palette.typography.shades100,
            borderRadius: { md: 3, xs: 2 },
          }}>
          <Box
            width="100%"
            sx={{
              p: 2,
              mb: 2,
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: `1px solid ${theme.baseTheme.palette.typography.neutrals200}`,
            }}>
            <Box>
              <Text variant="bodySemibold">
                {t(
                  'campaigns.useCaseSelectionModal.leadAutomationEnabledTemplatesHeading'
                )}
              </Text>
              <Tooltip
                title={t(
                  'campaigns.useCaseSelectionModal.leadAutomationEnabledTemplatesDisclaimer'
                )}
                placement="top">
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Box>
              <ButtonBase
                onClick={handleClose}
                sx={{
                  border: `1px solid ${theme.baseTheme.palette.components.icon.greyColor}`,
                  borderRadius: 4,
                  padding: 0.5,
                }}>
                <CrossIcon
                  fill={theme.baseTheme.palette.components.icon.greyColor}
                />
              </ButtonBase>
            </Box>
          </Box>

          <Grid
            container
            spacing={2}
            sx={{
              overflow: 'auto',
              maxHeight: '40%',
              px: 2,
            }}>
            {Object.keys(useCaseListView).map(
              (key) =>
                Boolean(
                  useCaseListView[key][0].lead_import_automation_enabled
                ) && (
                  <UseCaseList
                    key={key}
                    useCase={useCase}
                    useCaseItem={useCaseListView[key][0]}
                    showFreeTrialBanner={
                      !state.user?.has_existing_subscription ?? false
                    }
                    handleUseCaseChange={handleUseCaseChange}
                  />
                )
            )}
            <Grid item>
              <Text
                color={theme.baseTheme.palette.typography.jadeite500}
                variant="captionBold">
                {state.user?.has_existing_subscription
                  ? `* Note: By clicking on 'Select Use Case', you can further set up more IVR or Calling Campaigns`
                  : '* Note: Setup Free Trial Campaign with IVR-enabled calling and reach out to first 100 leads for free across all campaigns'}
              </Text>
            </Grid>
          </Grid>

          <Button
            onClick={handleCreateButtonClick}
            disabled={!useCase}
            sx={{
              backgroundColor:
                theme.baseTheme.palette.components.button.backgroundColor,
              borderRadius: 0.5,
              m: 2,
              px: 4,
              py: 1,
            }}>
            <Text
              color={theme.baseTheme.palette.typography.contrastTextColor}
              variant="bodyBold">
              {t('campaigns.useCaseSelectionModal.selectUseCaseBtn')}
            </Text>
          </Button>
        </Box>
      )}
    </>
  );
};
