import Sidebar from '@/lib/components/layout/Sidebar/Sidebar';
import { TopBar } from '@/lib/components/layout/TopBar/TopBar';
import {
  AgentIcon,
  AnalyticsIcon,
  BillingIcon,
  CampaignsIcon,
  FileCodeIcon,
  HeadphoneIcon,
  HelpSupportIcon,
  ReportIcon,
  ResourcesIcon,
  RocketIcon,
  SettingsIcon,
} from '@/lib/icons';
import { theme } from '@/lib/theme';
import { Box, Paper, Text } from '@/lib/components';
import { PropsWithChildren, useCallback, useMemo } from 'react';
import {
  SidebarProvider,
  useSidebarContext,
} from '@/lib/components/layout/Sidebar/SidebarContext';
import { SidebarMenuItem } from '@/lib/components/layout/Sidebar/SidebarMenuItem';
import { TopBarLayout } from './components/TopBarLayout';
import { useRouter } from 'next/router';
import { Toast, ToastProvider } from '@/lib/components/layout/Toast';
import { AuthenticationService } from '@/lib/apis/AuthenticationService';
import toast from 'react-hot-toast';
import { useAuthContext } from '@/lib/contexts';
import { routesConfig } from '@/pages/routes/config';
import { useAuthenticatedSWR } from '@/lib/network/useAuthenticatedSWR';
import { useZohoChatBotScript } from '@/pages/useZohoChatBotScript';
import { useTheme } from '@mui/material';
import { SidebarItem, SidebarItemsVariant } from './type';
import { UserInfo } from './components/UserInfo';
import {
  CampaignRoutes,
  DeveloperLogRoutes,
  HelpAndSupportRoutes,
  ResourcesRoutes,
  SelectBusinessRoutes,
} from '@/pages/routes/routes';
import { useTranslation } from 'react-i18next';
import { useFeatureFlag } from '@/lib/utils/FeatureFlags';
import { Usetiful } from '@/lib/support';
import { AlllowedIntegrationSourcesForNonPabblySMEFlow } from '@/pages/plug-n-play/campaigns/components/CampaignCreationWorkflow/constants';
import { getCurrentYear } from '@/lib/utils/DateUtils';

const SidebarItems = [
  {
    label: SidebarItemsVariant.GET_STARTED_FAQS,
    icon: <RocketIcon />,
    link: HelpAndSupportRoutes.GET_STARTED_FAQS,
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.CAMPAIGN,
    icon: <CampaignsIcon />,
    baseRoute: '/campaigns',
    link: '/campaigns',
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.ANALYTICS,
    icon: <AnalyticsIcon />,
    baseRoute: '/analytics',
    link: '/analytics',
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.REPORTS,
    icon: <ReportIcon />,
    baseRoute: '/reports',
    link: '/reports',
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.BILLING,
    icon: <BillingIcon />,
    link: '/billing',
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.SETTINGS,
    icon: <SettingsIcon />,
    baseRoute: '/settings',
    link: `/settings/account`,
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.INTEL_ASSIST,
    icon: <HeadphoneIcon width="24" height="24" />,
    baseRoute: '/intel-assist',
    link: '/intel-assist',
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.API_LOGS,
    icon: <FileCodeIcon />,
    link: DeveloperLogRoutes.LOGS,
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.RESOURCES,
    icon: <ResourcesIcon />,
    link: ResourcesRoutes.Resources,
    baseRoute: ResourcesRoutes.Resources,
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.AGENTS,
    icon: <AgentIcon />,
    baseRoute: '/agents',
    link: '/agents',
    openInNewTab: false,
  },
  {
    label: SidebarItemsVariant.REALTIME_LOGS,
    icon: <FileCodeIcon />,
    baseRoute: '/realtime-logs',
    link: '/realtime-logs',
    openInNewTab: false,
  },
];

const SidebarBottomItems = [
  {
    label: SidebarItemsVariant.HELP_AND_SUPPORT,
    icon: <HelpSupportIcon />,
    link: '/helpAndSupport/FAQ',
    openInNewTab: false,
  },
];

const ZohoChatBotScriptWrapper = () => {
  useZohoChatBotScript(
    process.env.NEXT_PUBLIC_ZOHO_CHATBOT_WIDGET_URL,
    process.env.NEXT_PUBLIC_ZOHO_CHATBOT_WIDGET_CODE
  );

  return null;
};

const CopyRight = () => {
  const { t } = useTranslation();
  const { slimProfile } = useSidebarContext();

  return (
    <>
      {!slimProfile ? (
        <Text
          sx={{ textAlign: 'center', marginTop: theme.spacing(2) }}
          variant={'captionRegular'}
          color={theme.baseTheme.palette.typography.eclipse200}>
          {t('copyright', { year: getCurrentYear() })}
        </Text>
      ) : null}
    </>
  );
};

const ChatBotDisabledRoutes = [CampaignRoutes.CREATE_WORKFLOW];

export const SidebarLayout: React.FC<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const { route, pathname } = router;
  const theme = useTheme();
  const config = routesConfig[pathname] ?? {};
  const {
    showNavbar = true,
    showSidebar = true,
    topBarDark = false,
    showBuildingIcon = true,
    showBellIcon = true,
  } = config;
  const { state, setUserInfo } = useAuthContext();
  const featureFlags = useFeatureFlag();

  const { isLoading } = useAuthenticatedSWR({
    ...AuthenticationService.getUserInfo(),
    onSuccess: (data) => {
      setUserInfo(data);
      if (
        state.user?.feature_flags_list.enable_invite_user_feature &&
        data.is_business_user === false
      ) {
        router.push(SelectBusinessRoutes.SELECT_BUSINESS);
      }
    },
    onError: (_error) => {
      toast.error('Something went wrong!');
    },
  });

  const getSidebarLink = useCallback(
    (sidebar_item: SidebarItem) => {
      if (
        sidebar_item.label == SidebarItemsVariant.BILLING &&
        state.user?.access_url
      ) {
        return state.user.access_url;
      } else return sidebar_item.link;
    },
    [state.user?.access_url]
  );

  const showSidebarItems = useCallback(
    (sidebar_item: SidebarItem) => {
      if (sidebar_item.label == SidebarItemsVariant.BILLING) {
        /* Show billing in sidebar only if user is not SME or user is SME and has access_url */
        return (
          !state.user?.is_sme ||
          (Boolean(state.user?.is_sme && state.user?.access_url) &&
            !AlllowedIntegrationSourcesForNonPabblySMEFlow.includes(
              state.user?.business_integration_source ?? ''
            ))
        );
      } else if (sidebar_item.label == SidebarItemsVariant.RESOURCES) {
        return featureFlags.show_resources_in_sidebar;
      } else if (sidebar_item.label == SidebarItemsVariant.API_LOGS) {
        return featureFlags?.show_developer_logs_in_sidebar;
      } else if (sidebar_item.label == SidebarItemsVariant.INTEL_ASSIST) {
        return featureFlags.show_intel_assist;
      } else if (sidebar_item.label == SidebarItemsVariant.GET_STARTED_FAQS) {
        return AlllowedIntegrationSourcesForNonPabblySMEFlow.includes(
          state.user?.business_integration_source ?? ''
        );
      } else if (sidebar_item.label == SidebarItemsVariant.AGENTS) {
        return featureFlags.show_agents_in_sidebar;
      } else if (sidebar_item.label == SidebarItemsVariant.REALTIME_LOGS) {
        return featureFlags?.realtime_logs;
      } else return true;
    },
    [featureFlags, state.user]
  );

  const ModifiedSidebarItems = useMemo(() => {
    return SidebarItems.map((item: SidebarItem) => {
      item.link = getSidebarLink(item);

      const showItem = showSidebarItems(item);
      item.showItem = showItem ? showItem : false;

      return item;
    });
  }, [getSidebarLink, showSidebarItems]);

  const ModifiedSidebarBottomItems = useMemo(() => {
    return SidebarBottomItems.map((item: SidebarItem) => {
      const showItem = showSidebarItems(item);
      item.showItem = showItem ? showItem : false;
      return item;
    });
  }, [showSidebarItems]);

  const showChatBot = () => {
    const isChatBotDisabledRoute = ChatBotDisabledRoutes.includes(pathname);
    return (
      featureFlags.show_squadbot &&
      process.env.NEXT_PUBLIC_ENV === 'production' &&
      !isChatBotDisabledRoute
    );
  };

  return (
    <>
      <SidebarProvider>
        <ToastProvider>
          <Box display="flex">
            {showNavbar ? (
              <TopBar showSidebar={showSidebar}>
                <TopBarLayout
                  showSidebar={showSidebar}
                  topBarDark={topBarDark}
                  showBuildingIcon={showBuildingIcon}
                  showBellIcon={showBellIcon}
                />
              </TopBar>
            ) : null}
            {showSidebar ? (
              <Sidebar>
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  justifyContent="space-between">
                  <Box height="100%">
                    {ModifiedSidebarItems.map((item, index) =>
                      item.showItem ? (
                        <SidebarMenuItem
                          key={index}
                          itemSelected={route.includes(item.link)}
                          label={item.label}
                          icon={item.icon}
                          link={getSidebarLink(item)}
                          openInNewTab={item.openInNewTab}
                          disabled={
                            isLoading ||
                            (state.user?.feature_flags_list
                              .enable_invite_user_feature &&
                              route === SelectBusinessRoutes.SELECT_BUSINESS)
                          }
                        />
                      ) : null
                    )}
                  </Box>
                  <Box
                    height="100%"
                    display="flex"
                    justifyContent="flex-end"
                    flexDirection="column"
                    paddingBottom={1.5}>
                    {ModifiedSidebarBottomItems.map((item, index) =>
                      item.showItem ? (
                        <SidebarMenuItem
                          key={index}
                          itemSelected={route.includes(item.link)}
                          label={item.label}
                          icon={item.icon}
                          link={getSidebarLink(item as SidebarItem)}
                          openInNewTab={item.openInNewTab}
                          disabled={
                            isLoading ||
                            (state.user?.feature_flags_list
                              .enable_invite_user_feature &&
                              route === SelectBusinessRoutes.SELECT_BUSINESS)
                          }
                        />
                      ) : null
                    )}
                    <UserInfo />
                    <CopyRight />
                  </Box>
                </Box>
              </Sidebar>
            ) : null}
            <Paper
              elevation={0}
              sx={{
                top: showNavbar
                  ? theme.baseTheme.defaultValues.APPBAR_HEIGHT
                  : 0,
                position: 'relative',

                //TODO: fix bug here, on sidebarWidth changing component doesn't rerender
                // width: `calc(100vw - ${sidebarWidth}px)`,
                width: '100%',

                height: showNavbar
                  ? `calc(100vh - ${theme.baseTheme.defaultValues.APPBAR_HEIGHT}px )`
                  : `100vh`,
                ...(showNavbar && {
                  overflowY: 'auto',
                }),
                backgroundColor: '#F5F5F5',
                borderRadius: '0px',
              }}>
              {isLoading ? null : children}
            </Paper>
            <Toast />
          </Box>
        </ToastProvider>
      </SidebarProvider>

      {showChatBot() ? <ZohoChatBotScriptWrapper /> : null}
      <Usetiful
        enable={featureFlags.show_usetiful}
        token={`${process.env.NEXT_PUBLIC_USETIFUL_TOKEN}`}
      />
    </>
  );
};
