import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { analytics as AnalyticsService } from './Analytics';
import {
  AnalyticsRoutes,
  AuthRoutes,
  CampaignRoutes,
  DeveloperLogRoutes,
  ReportsRoutes,
  ResourcesRoutes,
  IntelAssistRoutes,
  BillingRoutes,
  PlugNPlayCampaignRoutes,
  SettingsRoutes,
  SelectBusinessRoutes,
} from '@/pages/routes/routes';
import { EVENT_LISTENERS } from '@/lib/constants';

const routesWithDuration = [
  AuthRoutes.SIGNUP,
  AuthRoutes.LOGIN,
  AuthRoutes.VERIFICATION_EMAIL,
  CampaignRoutes.CAMPAIGNS,
  CampaignRoutes.CREATE_CAMPAIGN,
  CampaignRoutes.EDIT_CAMPAIGN,
  AnalyticsRoutes.ANALYTICS,
  ResourcesRoutes.Resources,
  DeveloperLogRoutes.LOGS,
  ReportsRoutes.LEAD_JOURNEY_REPORTS,
  CampaignRoutes.LEADS,
  CampaignRoutes.LEAD_JOURNEY,
  CampaignRoutes.IMPORT_HISTORY,
  IntelAssistRoutes.HOME,
  BillingRoutes.BILLING,
  PlugNPlayCampaignRoutes.PAYMENT_SUCCESS,
  AuthRoutes.INVITE,
  SettingsRoutes.TEAMS,
  SelectBusinessRoutes.SELECT_BUSINESS,
];

const currentlyTrackingRoutes = new Set();

const routeNameMap: Record<string, string> = {
  [AuthRoutes.SIGNUP]: 'Signup',
  [AuthRoutes.LOGIN]: 'Login',
  [AuthRoutes.VERIFICATION_EMAIL]: 'Email Verification',
  [CampaignRoutes.CAMPAIGNS]: 'Campaigns',
  [CampaignRoutes.CREATE_CAMPAIGN]: 'Create Campaign',
  [CampaignRoutes.EDIT_CAMPAIGN]: 'Edit Campaign',
  [AnalyticsRoutes.ANALYTICS]: 'Analytics',
  [ResourcesRoutes.Resources]: 'Resources',
  [DeveloperLogRoutes.LOGS]: 'Developer Logs',
  [ReportsRoutes.LEAD_JOURNEY_REPORTS]: 'Reports',
  [CampaignRoutes.LEADS]: 'Leads',
  [CampaignRoutes.LEAD_JOURNEY]: 'Lead Journey',
  [CampaignRoutes.IMPORT_HISTORY]: 'Import History',
  [IntelAssistRoutes.HOME]: 'Intel Assist',
  [BillingRoutes.BILLING]: 'Billing',
  [PlugNPlayCampaignRoutes.PAYMENT_SUCCESS]: 'Payment Success',
  [AuthRoutes.INVITE]: 'Invite User Signup',
  [SettingsRoutes.TEAMS]: 'Teams',
  [SelectBusinessRoutes.SELECT_BUSINESS]: 'Select Business',
};

export const useNextRouterAnalytics = () => {
  const router = useRouter();
  const { pathname, query, events } = router;
  const [previousRoute, setPreviousRoute] = useState<string | null>(null);
  const [previousQueryParams, setPreviousQueryParams] = useState<typeof query>(
    {}
  );

  const addRouteParamsAsProperty = useCallback(
    (route: string, queryObject: typeof query) => {
      let propertiesObject = {};
      if (
        (route === CampaignRoutes.CREATE_CAMPAIGN ||
          route === CampaignRoutes.EDIT_CAMPAIGN) &&
        queryObject &&
        queryObject.id
      ) {
        propertiesObject = {
          direct_object_key: queryObject.id as string,
        };
      }
      return propertiesObject;
    },
    []
  );

  const firePageLandingEvent = useCallback(() => {
    if (routeNameMap[pathname]) {
      AnalyticsService.page(routeNameMap[pathname], {
        ...addRouteParamsAsProperty(pathname, query),
      });
    }
  }, [addRouteParamsAsProperty, pathname, query]);

  const fireTrackEventToLogTimeSpent = useCallback(
    (route: string, queryParams: typeof query) => {
      if (currentlyTrackingRoutes.has(route) && routeNameMap[route]) {
        AnalyticsService.trackEvent(
          `User time spent on ${routeNameMap[route]} page`,
          {
            actor: 'User',
            verb: 'time spent',
            direct_object: `on ${routeNameMap[route]} page`,
            context: 'of customer dashboard',
            ...addRouteParamsAsProperty(route, queryParams),
          }
        );
        currentlyTrackingRoutes.delete(route);
      }
    },
    [addRouteParamsAsProperty]
  );

  const fireTimeEventToStartTrackingTimeSpent = useCallback((route: string) => {
    const trackCurrentRoute = routesWithDuration.find(
      (routeWithDur) => route === routeWithDur
    );

    if (route !== '/' && trackCurrentRoute && routeNameMap[route]) {
      AnalyticsService.timeEvent(
        `User time spent on ${routeNameMap[route]} page`
      );
      currentlyTrackingRoutes.add(route);
    }
  }, []);

  useEffect(() => {
    const handleRouteChangeComplete = () => {
      firePageLandingEvent();

      fireTrackEventToLogTimeSpent(
        previousRoute as string,
        previousQueryParams
      );

      const currentRoute = pathname;
      fireTimeEventToStartTrackingTimeSpent(currentRoute);
    };
    events.on(EVENT_LISTENERS.ROUTE_CHANGE_COMPLETE, handleRouteChangeComplete);

    const handleRouteChangeStart = () => {
      setPreviousRoute(pathname);
      setPreviousQueryParams(query);
    };
    events.on(EVENT_LISTENERS.ROUTE_CHANGE_START, handleRouteChangeStart);

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        firePageLandingEvent();
        fireTimeEventToStartTrackingTimeSpent(pathname);
      } else {
        fireTrackEventToLogTimeSpent(pathname, query);
      }
    };
    document.addEventListener(
      EVENT_LISTENERS.VISIBILITY_CHANGE,
      handleVisibilityChange
    );

    return () => {
      events.off(
        EVENT_LISTENERS.ROUTE_CHANGE_COMPLETE,
        handleRouteChangeComplete
      );
      events.off(EVENT_LISTENERS.ROUTE_CHANGE_START, handleRouteChangeStart);
      document.removeEventListener(
        EVENT_LISTENERS.VISIBILITY_CHANGE,
        handleVisibilityChange
      );
    };
  }, [
    addRouteParamsAsProperty,
    events,
    firePageLandingEvent,
    fireTimeEventToStartTrackingTimeSpent,
    fireTrackEventToLogTimeSpent,
    pathname,
    previousQueryParams,
    previousRoute,
    query,
  ]);
};
