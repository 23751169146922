import { Box, ButtonBase, Skeleton, Text } from '@/lib/components';
import { useTheme } from '@mui/material';
import { useAuthContext } from '@/lib/contexts';
import { BuildingIcon } from '@/lib/icons';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { SelectBusinessRoutes, SettingsRoutes } from '@/pages/routes/routes';
import { BusinessIntegration } from '@/pages/plug-n-play/campaigns/components/CampaignCreationWorkflow/constants';

export const UserBusinessInfo = ({
  showBuildingIcon = true,
  topBarDark = false,
}: {
  showBuildingIcon?: boolean;
  topBarDark?: boolean;
}) => {
  const theme = useTheme();
  const { state } = useAuthContext();
  const { t } = useTranslation();
  const router = useRouter();
  const { route } = useRouter();

  return (
    <>
      <ButtonBase
        disabled={
          !state.user ||
          (state.user?.feature_flags_list.enable_invite_user_feature &&
            route === SelectBusinessRoutes.SELECT_BUSINESS)
        }
        onClick={() => {
          router.push(SettingsRoutes.ACCOUNT);
        }}>
        <Box
          sx={{
            padding: theme.spacing(1, 2),
            height: 40,
            display: 'flex',
            alignItems: 'center',
          }}>
          {!state.user ? (
            <Skeleton
              variant="rounded"
              sx={{
                width: '100px',
                height: '32px',
                borderRadius: '40px',
                bgcolor:
                  theme.baseTheme.palette.components.skeletonLoader
                    .backgroundColor,
              }}
            />
          ) : (
            <>
              {showBuildingIcon && (
                <Box
                  bgcolor={theme.baseTheme.palette.surfaces.primaryBackground}
                  borderRadius="50%"
                  padding={theme.spacing(0.3)}>
                  <BuildingIcon />
                </Box>
              )}
              <Text
                sx={{
                  marginLeft: 1,
                  color: topBarDark
                    ? theme.baseTheme.palette.surfaces.primaryBackground
                    : theme.baseTheme.palette.typography.shades900,
                }}
                variant={'bodyRegular'}>
                {state.user?.business_integration_source ===
                BusinessIntegration.shiprocket
                  ? state.user?.business_name?.split(' (ID: ')[0] || t('user')
                  : state.user?.business_name || t('user')}
              </Text>
            </>
          )}
        </Box>
      </ButtonBase>
    </>
  );
};
