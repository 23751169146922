import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

type ToastConfig = {
  variant: 'error' | 'warning' | 'success' | 'info';
  message: string;
};

type ToastContextConfig = {
  isToastShown: boolean;
  toastConfig: ToastConfig;
  showToast: (config: ToastConfig) => void;
  hideToast: () => void;
};

const ToastContext = createContext<ToastContextConfig>({
  isToastShown: false,
  toastConfig: {
    variant: 'error',
    message: 'Something went wrong!',
  },
  showToast: (_config: ToastConfig) => null,
  hideToast: () => null,
});

const ToastProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isToastShown, setIsToastShown] = useState(false);
  const [toastConfig, setToastConfig] = useState<ToastConfig>({
    variant: 'error',
    message: 'Something went wrong!',
  });

  const showToast = useCallback((config: ToastConfig) => {
    setIsToastShown(true);
    setToastConfig(config);
  }, []);

  const hideToast = useCallback(() => {
    setIsToastShown(false);
  }, []);

  return (
    <ToastContext.Provider
      value={{ isToastShown, toastConfig, showToast, hideToast }}>
      {children}
    </ToastContext.Provider>
  );
};

const useToastContext = () => useContext(ToastContext);

export { ToastProvider, useToastContext };
