import i18n from 'i18next';
import en from './en';
import es from './es';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes transalation down to react-i18next
  .init({
    resources: {
      en: en,
      es: es,
    },
    lng: 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    compatibilityJSON: 'v3',
  });

export default i18n;
