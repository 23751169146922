import { APIService } from './ApiService';

export class AuthenticationService {
  static login() {
    return APIService.createPostRequest('voice/dashboard/api/login/v3/', {
      isAuthenticated: true,
      getData: ({ email, mobile, password, g_recaptcha_response }) => {
        return {
          email,
          mobile,
          password,
          g_recaptcha_response,
        };
      },
    });
  }

  static signup() {
    return APIService.createPostRequest(
      'voice/dashboard/api/registration/v3/',
      {
        getData: ({
          email,
          password,
          g_recaptcha_response,
          first_name,
          last_name,
        }) => {
          return {
            email,
            password,
            g_recaptcha_response,
            first_name,
            last_name,
          };
        },
      }
    );
  }

  static inviteUser() {
    return APIService.createPostRequest('voice/dashboard/invite-user/', {
      getData: ({
        email,
        password,
        g_recaptcha_response,
        first_name,
        last_name,
        invite_id,
      }) => {
        return {
          email,
          password,
          g_recaptcha_response,
          first_name,
          last_name,
          invite_id,
        };
      },
    });
  }

  static logout() {
    return APIService.createPostRequest('voice/dashboard/api/logout/v3/');
  }

  static accountActivation() {
    return APIService.createPostRequest(
      'voice/dashboard/api/activate/account/v3/',
      {
        getData: ({ activation_id }) => {
          return {
            activation_id,
          };
        },
      }
    );
  }

  static resendActivationLink() {
    return APIService.createPostRequest(
      'voice/dashboard/api/resend/activation/link/v3/',
      {
        getData: ({ email }) => {
          return {
            email,
          };
        },
      }
    );
  }

  static forgotPassword() {
    return APIService.createPostRequest(
      'voice/dashboard/api/forgot/password/v3/',
      {
        getData: ({ email, g_recaptcha_response }) => {
          return {
            email,
            g_recaptcha_response,
          };
        },
      }
    );
  }

  static resetPassword() {
    return APIService.createPostRequest(
      'voice/dashboard/api/reset/password/v3/',
      {
        getData: ({ email, activation_id, password, g_recaptcha_response }) => {
          return {
            email,
            activation_id,
            password,
            g_recaptcha_response,
          };
        },
      }
    );
  }

  static getEmail(activation_id: unknown) {
    return APIService.createGetRequest(
      `voice/dashboard/api/get-email/v2/?activation_id=${activation_id}`
    );
  }

  static getUserInfo() {
    return APIService.createGetRequest('voice/dashboard/api/get-user-info/v2/');
  }

  static createCloneCampaign() {
    return APIService.createPostRequest(
      'voice/dashboard/plug-n-play/create-clone-campaign/',
      {
        getData: ({ template_campaign_id, title }) => {
          return { template_campaign_id, title };
        },
      }
    );
  }

  static createCloneCampaignV2() {
    return APIService.createPostRequest(
      'voice/dashboard/plug-n-play/v2/create-clone-campaign/',
      {
        getData: ({ template_campaign_id, title }) => {
          return { template_campaign_id, title };
        },
      }
    );
  }

  static getIndustryOptions(page: number, page_size: number) {
    return APIService.createGetRequest(
      `voice/dashboard/campaigns/api/industries/?page=${page}&page_size=${page_size}`
    );
  }

  static validateEmailOrPhone() {
    return APIService.createPostRequest(
      'voice/dashboard/api/validate_business_user/',
      {
        getData: ({ email_or_mobile }) => {
          return { email_or_mobile };
        },
      }
    );
  }

  static generateOtp() {
    return APIService.createPostRequest('voice/dashboard/api/generate_otp/', {
      getData: ({
        email,
        mobile,
        login_method,
        password,
        g_recaptcha_response,
        resend_otp = false,
      }) => {
        return {
          email,
          mobile,
          login_method,
          password,
          g_recaptcha_response,
          resend_otp,
        };
      },
    });
  }

  static loginViaOtp() {
    return APIService.createPostRequest('voice/dashboard/api/login_via_otp/', {
      getData: ({ email, mobile, otp }) => {
        return { email, mobile, otp };
      },
    });
  }

  static signupForSME() {
    return APIService.createPostRequest(
      'voice/dashboard/plug-n-play/create-business-user/',
      {
        getData: ({ first_name, last_name, email, mobile, company_name }) => {
          return {
            first_name,
            last_name,
            email,
            mobile,
            company_name,
          };
        },
      }
    );
  }

  static ssoLogin() {
    return APIService.createGetRequest(`voice/dashboard/aws-cognito/callback`, {
      getQueryParams: (queryParams) => {
        const obj = queryParams as { code: string; state: string };
        return {
          code: obj.code,
          state: obj.state,
        };
      },
    });
  }
}
