import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { CampaignRoutes } from '@/pages/routes/routes';
import { SelectChangeEvent, useTheme } from '@mui/material';
import {
  Box,
  Card,
  Text,
  OutlinedInput,
  Button,
  CircularLoader,
  Grid,
  FormControl,
  Select,
  SelectItem,
  ButtonBase,
} from '@/lib/components';
import { CrossIcon, NewCampaignIllustrationSME } from '@/lib/icons';
import { useAuthenticatedSWRMutation } from '@/lib/network';
import { AuthenticationService } from '@/lib/apis/AuthenticationService';
import { BadRequest } from '@/lib/network/errors';
import { useAuthContext } from '@/lib/contexts';
import { AnalyticsService } from '@/lib/analytics';
import { FilterOptionsDataType } from '../../type';
import { CampaignService } from '@/lib/apis/CampaignService';
import dynamic from 'next/dynamic';
import { SquadstackLoader } from '@/lib/loader';
import { useFeatureFlag } from '@/lib/utils/FeatureFlags';
import { CloneCampaignLoader } from '../CloneCampaignLoader';

export const ScriptLoadingAnimation = dynamic(
  () =>
    import('@/pages/campaigns/components/GDocEmbedComponent').then(
      (mod) => mod.GDocEmbedLoading
    ),
  {
    loading: () => <SquadstackLoader />,
  }
);

type AddCampaignNameProps = {
  campaignId?: string | number;
  handleClose?: () => void;
};

export const AddCampaignName: React.FC<AddCampaignNameProps> = ({
  campaignId,
  handleClose,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { state, updateUserInfo } = useAuthContext();
  const router = useRouter();
  const featureFlags = useFeatureFlag();

  const [title, setTitle] = useState<string>();
  const [useCaseList, setUseCaseList] = useState<FilterOptionsDataType[]>([]);
  const [useCase, setUseCase] = useState<string>('');
  const [campaignList, setCampaignList] = useState<FilterOptionsDataType[]>([]);
  const [selectedCampaign, setSelectedCampaign] = useState<string>('');
  const [errors, setErrors] = useState({
    title: '',
    useCase: '',
  });

  const useCaseDropDown = useMemo(() => {
    return useCaseList
      .filter(
        (option, index, self) =>
          self.findIndex((o) => o.label === option.label) === index
      )
      .map((option) => (
        <SelectItem key={option.label} value={option.label}>
          {option.label}
        </SelectItem>
      ));
  }, [useCaseList]);

  const {
    trigger: createCloneCampaignTrigger,
    isMutating: isCreateCampaignLoading,
  } = useAuthenticatedSWRMutation({
    ...AuthenticationService.createCloneCampaign(),
  });

  const {
    trigger: createCloneCampaignV2Trigger,
    isMutating: isCreateCampaignLoadingV2,
  } = useAuthenticatedSWRMutation({
    ...AuthenticationService.createCloneCampaignV2(),
  });

  const {
    trigger: triggerCampaignUseCaseFilterOptions,
    isMutating: isCampaignUseCaseFilterLoading,
  } = useAuthenticatedSWRMutation({
    ...CampaignService.getCampaignFilterOptions('campaign-use-case'),

    onSuccess: (data: { results: FilterOptionsDataType[] }) => {
      if (data && data.results) {
        setUseCaseList([...data.results]);

        // set campaign name when the value is supplied from the template campaigns
        if (campaignId) {
          const selectedUseCase = data.results.filter(
            (filter: FilterOptionsDataType) => filter.campaign_id == campaignId
          );

          if (selectedUseCase.length) {
            setUseCase(selectedUseCase[0].label as string);
            setSelectedCampaign(selectedUseCase[0].campaign as string);
          }
        }
      }
    },
  });

  useEffect(() => {
    triggerCampaignUseCaseFilterOptions();
  }, [triggerCampaignUseCaseFilterOptions]);

  useEffect(() => {
    setCampaignList(useCaseList.filter((option) => option.label === useCase));
  }, [useCase, useCaseList]);

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
    setErrors((prevErrors) => ({ ...prevErrors, title: '' }));
  };

  const handleUseCaseChange = (event: SelectChangeEvent<string>) => {
    setUseCase(event.target.value as string);
    setErrors((prevErrors) => ({ ...prevErrors, useCase: '' }));
  };

  const handleSelectedCampaignChange = (event: SelectChangeEvent<string>) => {
    setSelectedCampaign(event.target.value as string);
    setErrors((prevErrors) => ({ ...prevErrors, useCase: '' }));
  };

  const handleSuccess = (data: { template_campaign_id: number }) => {
    AnalyticsService.trackEvent('User creates a clone of Campaign', {
      actor: 'User',
      verb: 'creates',
      direct_object: 'clone of Campaign',
      direct_object_info: `campaign:${data.template_campaign_id}`,
      context: 'from Campaign Cards',
      context_metadata: 'of customer dashboard',
    });
    if (state.user?.has_campaign && handleClose) {
      handleClose();
    }

    if (state.user && !state.user?.has_campaign) {
      updateUserInfo({ ...state.user, has_campaign: true });
    }
  };

  const handleCreateButtonClick = () => {
    const selectedUseCase = useCaseList.filter(
      (filter) =>
        filter.label === useCase && filter.campaign === selectedCampaign
    );
    const templateCampaignId =
      campaignId ??
      (selectedUseCase.length ? selectedUseCase[0].campaign_id : null);

    if (title) {
      if (!featureFlags?.show_diy_script_builder_sme) {
        createCloneCampaignTrigger(
          {
            template_campaign_id: templateCampaignId,
            title: title,
          },
          {
            onSuccess: async (data) => {
              handleSuccess(data);
              await router.push({
                pathname: CampaignRoutes.CREATE_CAMPAIGN,
                query: { id: data.template_campaign_id.toString() },
              });
            },
            onError: (error) => {
              if (error instanceof BadRequest) {
                setErrors({
                  title:
                    `${title} ` + t('campaigns.addCampaignName.errorMessage'),
                  useCase: '',
                });
              } else {
                const message = error?.data?.error || t('somethingWentWrong');
                localStorage.setItem('errorMsgInCreateCampaign', message);
                router.reload();
              }
            },
          }
        );
      } else {
        createCloneCampaignV2Trigger(
          {
            template_campaign_id: templateCampaignId,
            title: title,
          },
          {
            onSuccess: async (data) => {
              handleSuccess(data);
              await router.push({
                pathname: CampaignRoutes.CREATE_CAMPAIGN,
                query: { id: data.template_campaign_id.toString() },
              });
            },
            onError: (error) => {
              if (error instanceof BadRequest) {
                setErrors({
                  title:
                    `${title} ` + t('campaigns.addCampaignName.errorMessage'),
                  useCase: '',
                });
              } else {
                const message = error?.data?.detail || t('somethingWentWrong');
                localStorage.setItem('errorMsgInCreateCampaign', message);
                router.reload();
              }
            },
          }
        );
      }
    }
  };

  return isCreateCampaignLoading || isCreateCampaignLoadingV2 ? (
    <CloneCampaignLoader />
  ) : (
    <Box display="flex" justifyContent="space-around" alignItems="center">
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          padding: theme.spacing(2),
          gap: theme.spacing(2),
          maxWidth: '70%',
          maxHeight: '50%',
          background: theme.baseTheme.palette.surfaces.surfaceBackground,
          boxShadow: theme.baseTheme.palette.components.card.boxShadow,
          borderRadius: '8px',
        }}>
        <Box display="flex" justifyContent="space-between" width="100%">
          <Text variant="bodySemibold">
            {state.user?.has_campaign
              ? t('campaigns.addCampaignName.newCampaigntitle')
              : t('campaigns.addCampaignName.firstCampaigntitle')}
          </Text>
          <ButtonBase
            onClick={() => {
              if (handleClose) handleClose();
            }}
            sx={{
              fontSize: 10,
              pb: 2,
            }}>
            <CrossIcon />
          </ButtonBase>
        </Box>

        <Image
          src={NewCampaignIllustrationSME}
          alt="An illustration depicting a campaign's journey"
          style={{ width: '100%', height: '100%' }}
        />

        <Grid container gap={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <Text variant="tinyRegular">
                {t('campaigns.addCampaignName.campaignNameLabel')}
              </Text>

              <OutlinedInput
                placeholder={
                  t('campaigns.addCampaignName.textFieldPlaceHolder') as string
                }
                value={title}
                onChange={handleTitleChange}
                name="title"
                type="text"
                error={errors.title.length > 0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleCreateButtonClick();
                }}
                size="small"
                autoFocus
              />
              {errors.title && (
                <Text
                  variant="captionRegular"
                  sx={{
                    color: theme.baseTheme.palette.typography.oldSpiceMain,
                  }}>
                  {errors.title}
                </Text>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
              <Text variant="tinyRegular">
                {t('campaigns.allCampaigns.campaignCard.campaignObjective')}
              </Text>

              <Select
                value={useCase}
                size="small"
                variant="outlined"
                onChange={handleUseCaseChange}
                disabled={!!campaignId}
                displayEmpty>
                <SelectItem disabled value="">
                  <Text
                    sx={{
                      color: theme.baseTheme.palette.typography.neutrals300,
                    }}>
                    {t('sme.campaign.createCampaignModal.useCasePlaceholder')}
                  </Text>
                </SelectItem>
                {isCampaignUseCaseFilterLoading ? (
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center">
                    <CircularLoader />
                  </Box>
                ) : (
                  useCaseDropDown
                )}
              </Select>
              {errors.useCase && (
                <Text
                  variant="captionRegular"
                  sx={{
                    color: theme.baseTheme.palette.typography.oldSpiceMain,
                  }}>
                  {errors.useCase}
                </Text>
              )}
            </FormControl>
          </Grid>

          {useCase ? (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Text variant="tinyRegular">
                  {t('campaigns.allCampaigns.campaignCard.campaignUseCase')}
                </Text>

                <Select
                  value={selectedCampaign}
                  size="small"
                  variant="outlined"
                  onChange={handleSelectedCampaignChange}
                  disabled={!!campaignId}
                  displayEmpty>
                  <SelectItem disabled value="">
                    <Text
                      sx={{
                        color: theme.baseTheme.palette.typography.neutrals300,
                      }}>
                      {t(
                        'sme.campaign.createCampaignModal.campaignListPlaceholder'
                      )}
                    </Text>
                  </SelectItem>
                  {isCampaignUseCaseFilterLoading ? (
                    <Box
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center">
                      <CircularLoader />
                    </Box>
                  ) : (
                    campaignList.map((option) => (
                      <SelectItem key={option.campaign} value={option.campaign}>
                        {option.campaign}
                      </SelectItem>
                    ))
                  )}
                </Select>
                {errors.useCase && (
                  <Text
                    variant="captionRegular"
                    sx={{
                      color: theme.baseTheme.palette.typography.oldSpiceMain,
                    }}>
                    {errors.useCase}
                  </Text>
                )}
              </FormControl>
            </Grid>
          ) : (
            ''
          )}
        </Grid>

        <Button
          onClick={handleCreateButtonClick}
          disabled={!title || !useCase || !selectedCampaign}
          sx={{
            backgroundColor:
              theme.baseTheme.palette.components.button.backgroundColor,
            borderRadius: '4px',
            px: theme.spacing(4),
            py: theme.spacing(1),
          }}>
          <Text
            color={theme.baseTheme.palette.typography.contrastTextColor}
            variant="bodyBold">
            {t('campaigns.addCampaignName.createButton')}
          </Text>
        </Button>
      </Card>
    </Box>
  );
};
