import React from 'react';
import { NextRouter } from 'next/router';
import { useAuthContext } from '@/lib/contexts';
import {
  AuthRoutes,
  Routes,
  SelectBusinessRoutes,
  CampaignRoutes,
} from './routes';
import { SidebarLayout } from '@/lib/layout/SidebarLayout/SidebarLayout';
import { Box } from '@/lib/components';
import { SignOutTypes } from '@/lib/contexts/AuthContext/types';
import { IS_SME } from '@/lib/constants/auth';

const isBrowser = () => typeof window !== 'undefined';

export const ProtectedRoute = ({
  router,
  children,
}: {
  router: NextRouter;
  children: React.ReactNode;
}) => {
  const pathIsProtected = !router.pathname.includes('auth');
  const redirect = router.query.redirect;
  const { state } = useAuthContext();

  if (!router.isReady) {
    return <Box />;
  }

  if (state.isAuthenticated === null) {
    return <Box />;
  }

  if (
    isBrowser() &&
    router &&
    router.query &&
    router.query._access_id &&
    router.pathname === CampaignRoutes.LEAD_JOURNEY
  ) {
    return <>{children}</>;
  }

  if (
    isBrowser() &&
    state.isAuthenticated &&
    state.user?.feature_flags_list.enable_invite_user_feature &&
    state.user?.is_business_user === false &&
    router.pathname !== SelectBusinessRoutes.SELECT_BUSINESS
  ) {
    router.push(SelectBusinessRoutes.SELECT_BUSINESS);
    return <Box />;
  }

  if (
    isBrowser() &&
    state.isAuthenticated &&
    state.user?.is_business_user &&
    router.pathname === SelectBusinessRoutes.SELECT_BUSINESS
  ) {
    router.push(Routes.HOME);
    return <Box />;
  }

  if (isBrowser() && !state.isAuthenticated && pathIsProtected) {
    if (
      state.signOutType !== SignOutTypes.SIGN_OUT_BUTTON &&
      router.pathname !== '/'
    ) {
      router.push({
        pathname: AuthRoutes.LOGIN,
        query: {
          redirect: router.asPath,
        },
      });
    } else {
      if (localStorage.getItem(IS_SME) === 'true') {
        router.push({
          pathname: AuthRoutes.LOGIN,
          query: {
            plug_n_play: true,
          },
        });
      } else router.push(AuthRoutes.LOGIN);
    }
    return <Box />;
  }

  if (isBrowser() && state.isAuthenticated && redirect) {
    const isValidRedirect = (redirectUrl: string) => {
      return redirectUrl.startsWith('/');
    };

    if (typeof redirect == 'string' && !isValidRedirect(redirect)) {
      router.push(Routes.HOME);
      return <Box />;
    }
  }

  if (isBrowser() && state.isAuthenticated && !pathIsProtected) {
    const redirectLink = router.query.redirect as string;
    router.push(redirectLink || Routes.HOME);
    return <Box />;
  }

  if (isBrowser() && pathIsProtected) {
    return <SidebarLayout>{children}</SidebarLayout>;
  }

  if (isBrowser() && !pathIsProtected) {
    return <>{children}</>;
  }

  return <Box />;
};
