export const eclipse = {
  main: '#162447',
  50: '#F8FAFC',
  100: '#F1F5F9',
  200: '#8A92A8',
  300: '#48526D',
  500: '#687699',
  600: '#48526D',
  700: '#0B1224',
};

export const jadeite = {
  main: '#39CEA0',
  100: '#E2F9F2',
  200: '#B5EDDB',
  300: '#80E0C2',
  400: '#39CEA0',
  500: '#15A97B',
  600: '#1D6750',
  700: '#113E30',
};

export const oasis = {
  main: '#2EB4E6',
  100: '#EAF8FD',
  200: '#ABE1F5',
  300: '#82D2F0',
  500: '#2EB4E6',
  600: '#2590B8',
  700: '#1C6C8A',
};

export const lavender = {
  main: '#6912CB',
  100: '#E5D4F7',
  200: '#C9A2F6',
  300: '#9F58EE',
  500: '#6912CB',
  600: '#4A0D8E',
  700: '#2A0751',
  800: '#F3EBFC',
};

export const warning = {
  main: '#FFBB38',
  100: '#FFF4E0',
  200: '#FFE3AD',
  300: '#FFCE70',
  500: '#FFBB38',
  600: '#DFA12B',
  700: '#805E1C',
};

export const oldSpice = {
  main: '#E74C3C',
  100: '#FADBD8',
  200: '#F5B7B1',
  300: '#F1948A',
  500: '#E74C3C',
  600: '#B93D30',
  700: '#8B2E24',
};

export const neutrals = {
  main: '#FFFFFF',
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#E0E0E0',
  300: '#BDBDBD',
  400: '#A3A3A3',
  500: '#737373',
  600: '#757575',
  700: '#171717',
  800: '#262626',
  900: '#171717',
};

export const shades = {
  main: '#FFFFFF',
  100: '#F5F5F5',
  300: '#E0E0E0',
  500: '#BDBDBD',
  700: '#757575',
  900: '#323232',
  black: '#171717',
};

export const indigo = {
  100: '#E0E7FF',
  600: '#4F46E5',
};

export const green = {
  100: '#DCFCE7',
};
