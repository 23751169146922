import { AUTH_CSRF_HEADER, NEXT_CSRF_HEADER } from '../constants/auth';
import * as Sentry from '@sentry/browser';
import {
  APIError,
  BadAuthentication,
  BadNetwork,
  BadRequest,
  BrowserCancel,
  ForbiddenAccess,
  NotFound,
  ServerError,
  TooManyRequests,
} from './errors';
import { HttpError } from './http';
import { HttpMethods, HttpRequest, SWRMutationConfig } from './types';
import { Logger } from '../logger';

const getCSRFToken = (): string => {
  return localStorage.getItem(AUTH_CSRF_HEADER) ?? '';
};

export const swrFetcher = async (
  request: HttpRequest,
  config: SWRMutationConfig,
  signal?: AbortSignal | undefined
) => {
  try {
    const { isAuthenticated } = config;

    const headers = new Headers({
      ...(request.headers as Headers),
    });
    if (typeof request.body === 'string') {
      headers.append('Content-type', 'application/json; charset=UTF-8');
    }
    if (
      request.method === HttpMethods.POST ||
      request.method === HttpMethods.PATCH ||
      (request.method === HttpMethods.PUT && isAuthenticated) ||
      (request.method === HttpMethods.DELETE && isAuthenticated)
    ) {
      const csrfToken = getCSRFToken();
      if (csrfToken) {
        headers.append(NEXT_CSRF_HEADER, csrfToken);
      }
    }

    const response = await fetch(request.url, {
      ...request,
      credentials: 'include',
      signal,
      headers,
    });

    const data = await response.json();
    if (!response.ok) {
      const status = response.status;
      const error = new HttpError(request, {
        status: response.status,
        body: data,
      });
      if (!Number.isFinite(status)) {
        throw new BadNetwork(error);
      } else if (status === 400) {
        throw new BadRequest(error);
      } else if (status === 401) {
        throw new BadAuthentication(error);
      } else if (status === 403) {
        throw new ForbiddenAccess(error);
      } else if (status === 404) {
        throw new NotFound(error);
      } else if (status === 429) {
        throw new TooManyRequests(error);
      } else if (status >= 500) {
        throw new ServerError(error);
      } else {
        throw new APIError(error);
      }
    }
    return data;
  } catch (error: unknown) {
    const err = error as HttpError;
    if (err.name === 'AbortError') {
      throw new BrowserCancel(err);
    }
    Sentry.captureException(error);
    Logger.error(err as Error, {
      error_msg: err?.data?.error ?? 'No message provided',
      error_status_code: err?.status ?? 'N/A',
    });
    throw error;
  }
};

export const fireNetworkRequest = async (
  request: HttpRequest,
  signal?: AbortSignal | undefined
) => {
  try {
    const requestBody =
      request.method === HttpMethods.GET
        ? undefined
        : JSON.stringify(request.body) ?? '';
    request.body = requestBody;

    const headers = new Headers({
      ...(request.headers as Headers),
    });
    if (typeof requestBody === 'string') {
      headers.append('Content-type', 'application/json; charset=UTF-8');
    }

    const csrfToken = getCSRFToken();
    if (csrfToken) {
      headers.append(NEXT_CSRF_HEADER, getCSRFToken());
    }

    const response = await fetch(request.url, {
      ...request,
      credentials: 'include',
      signal,
      headers,
    });

    const data = await response.json();
    if (!response.ok) {
      const status = response.status;
      const error = new HttpError(request, {
        status: response.status,
        body: data,
      });
      if (!Number.isFinite(status)) {
        throw new BadNetwork(error);
      } else if (status === 400) {
        throw new BadRequest(error);
      } else if (status === 401) {
        throw new BadAuthentication(error);
      } else if (status === 403) {
        throw new ForbiddenAccess(error);
      } else if (status === 404) {
        throw new NotFound(error);
      } else if (status === 429) {
        throw new TooManyRequests(error);
      } else if (status >= 500) {
        throw new ServerError(error);
      } else {
        throw new APIError(error);
      }
    }
    return data;
  } catch (error: unknown) {
    const err = error as HttpError;
    if (err.name === 'AbortError') {
      throw new BrowserCancel(err);
    }
    Sentry.captureException(error);
    Logger.error(err as Error, {
      error_msg: err?.data?.error ?? 'No message provided',
      error_status_code: err?.status ?? 'N/A',
    });
    throw error;
  }
};
