export const Routes = {
  HOME: '/campaigns',
};

export const AuthRoutes = {
  LOGIN: '/auth/login',
  SIGNUP: '/auth/signup',
  VERIFY: '/auth/verify',
  INVITE: '/auth/invite',
  VERIFICATION: '/auth/verification',
  FORGOT_PASSWORD: '/auth/forgotpassword',
  SENT_LINK: '/auth/sentlink',
  RESET_PASSWORD: '/auth/resetpassword',
  VERIFICATION_EMAIL: '/auth/verification',
  SENTLINK_EMAIL: '/auth/sentlink',
  CALLBACK: '/auth/cognito/callback',
};

export const CampaignRoutes = {
  CAMPAIGNS: '/campaigns',
  LEADS: '/campaigns/leads',
  LEAD_JOURNEY: '/campaigns/leads/[id]/journey',
  IMPORT_HISTORY: '/campaigns/ImportHistory',
  IMPORT_HISTORY_BLACKLIST_TAB: '/campaigns/ImportHistory?tab=blacklist',
  IMPORT_HISTORY_LEAD_IMPORT_TAB: '/campaigns/ImportHistory?tab=leadImport',
  CREATE_CAMPAIGN: '/campaigns/create/[id]',
  EDIT_CAMPAIGN: '/campaigns/edit/[id]',
  WORKFLOW_TEMPLATE: '/campaigns/[id]/workflow',
  VIEW_CAMPAIGN_SCRIPT: '/campaigns/[id]/script',
  VIEW_CAMPAIGN_FAQS: '/campaigns/[id]/faqs',
  CREATE_WORKFLOW: '/campaigns/[id]/workflow/[workflowid]',
};

export const ReportsRoutes = {
  LEAD_JOURNEY_REPORTS: '/reports',
};

export const AnalyticsRoutes = {
  ANALYTICS: '/analytics',
};

export const BillingRoutes = {
  BILLING: '/billing',
};

export const ScriptRoutes = {
  SCRIPT: '/script',
};

export const SettingsRoutes = {
  ACCOUNT: '/settings/account',
  INTEGRATIONS: '/settings/integrations',
  SUBSCRIPTIONS: '/settings/subscriptions',
  TEAMS: '/settings/teams',
};

export const HelpAndSupportRoutes = {
  FAQS: '/helpAndSupport/FAQ',
  GET_STARTED_FAQS: '/helpAndSupport/getStarted',
};

export const DeveloperLogRoutes = {
  LOGS: '/logs',
};

export const ProfileRoutes = {
  ACTIVITY_LOGS: '/profile/activityLogs',
};

export const ResourcesRoutes = {
  Resources: '/resources',
  Resources_ITEM: '/resources/[id]',
};

export const DownloadRoutes = {
  DOWNLOADS: '/downloads',
};

export const IntelAssistRoutes = {
  HOME: '/intel-assist',
};

export const Agents = {
  AGENTS: '/agents',
};

export const PlugNPlayCampaignRoutes = {
  PAYMENT_SUCCESS: '/plug-n-play/campaigns/payment-success',
};

export const SelectBusinessRoutes = {
  SELECT_BUSINESS: '/select-business',
};

export const ApiIntegration = {
  API_INTEGRATION: '/api-integration',
};
