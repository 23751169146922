import { Box, Button } from '@/lib/components';
import { CampaignRoutes } from '@/pages/routes/routes';
import { useTranslation } from 'react-i18next';

export const PaymentSuccessHeader = () => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    window.location.replace(CampaignRoutes.CAMPAIGNS);
  };

  return (
    <Box style={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
      <Button onClick={handleButtonClick}>
        {t('campaigns.createCampaign.navigatorTabs.goBackToDashboard')}
      </Button>
    </Box>
  );
};
