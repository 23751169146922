import { translate } from '@/lib/transalation';
import { Tab } from '@/lib/components/core/Tabs/TableTab';

export enum CampaignStatusOptionsValues {
  all = 'all',
  drafting = 'drafting',
  published = 'published',
  paused = 'paused',
  underReview = 'underReview',
}

export const CampaignStatusOptions: Tab<CampaignStatusOptionsValues>[] = [
  {
    value: CampaignStatusOptionsValues.all,
    label: translate('campaigns.allCampaigns.campaignStatus.all'),
  },
  {
    value: CampaignStatusOptionsValues.drafting,
    label: translate('campaigns.allCampaigns.campaignStatus.drafting'),
  },
  {
    value: CampaignStatusOptionsValues.published,
    label: translate('campaigns.allCampaigns.campaignStatus.published'),
  },
  {
    value: CampaignStatusOptionsValues.paused,
    label: translate('campaigns.allCampaigns.campaignStatus.paused'),
  },
  {
    value: CampaignStatusOptionsValues.underReview,
    label: translate('campaigns.allCampaigns.campaignStatus.underReview'),
  },
];

export const IntegrationStatusOptions = [
  translate('campaigns.allCampaigns.campaignStatus.all'),
  translate('campaigns.allCampaigns.integrationStatusOption.integrated'),
  translate('campaigns.allCampaigns.integrationStatusOption.notIntegrated'),
];

export const IntegrationStatusOptionsLabels = {
  all: translate('campaigns.allCampaigns.campaignStatus.all'),
  integrated: translate(
    'campaigns.allCampaigns.integrationStatusOption.integrated'
  ),
  notIntegrated: translate(
    'campaigns.allCampaigns.integrationStatusOption.notIntegrated'
  ),
};

export const IntegrationStatusOptionMap = {
  [translate('campaigns.allCampaigns.campaignStatus.all')]: 'all',
  [translate('campaigns.allCampaigns.integrationStatusOption.integrated')]:
    'integrated',
  [translate('campaigns.allCampaigns.integrationStatusOption.notIntegrated')]:
    'notIntegrated',
};

export const defaultCampaignStatusValue: Tab<CampaignStatusOptionsValues> = {
  value: CampaignStatusOptionsValues.published,
  label: translate('campaigns.allCampaigns.campaignStatus.published'),
};
export const defaultIntegrationStatusValue = 'all';
