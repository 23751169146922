import { useAuthContext } from '@/lib/contexts';

export const useFeatureFlag = () => {
  const { state } = useAuthContext();

  let featureFlags = state.user?.feature_flags_list ?? {};
  featureFlags = Object.assign({ is_sme: state.user?.is_sme }, featureFlags);

  return featureFlags;
};
