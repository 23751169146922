import { TextField as MuiTextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { sanitizeInputValue } from '@/lib/utils/sanitizer';

const SanitizedTextField: React.FC<TextFieldProps> = ({
  value,
  InputProps,
  ...props
}) => {
  return (
    <MuiTextField
      {...props}
      value={sanitizeInputValue(value)}
      InputProps={
        InputProps
          ? {
              ...InputProps,
              value: sanitizeInputValue(
                InputProps?.value ? InputProps.value : value
              ),
            }
          : {}
      }
    />
  );
};

export { SanitizedTextField as TextField };
