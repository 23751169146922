export enum SidebarItemsVariant {
  CAMPAIGN = 'Campaigns',
  ANALYTICS = 'Analytics',
  REPORTS = 'Reports',
  BILLING = 'Billing',
  SETTINGS = 'Settings',
  HELP_AND_SUPPORT = 'Help & Support',
  API_LOGS = 'API Logs',
  RESOURCES = 'Resources',
  INTEL_ASSIST = 'Intel Assist',
  AGENTS = 'Agents',
  GET_STARTED_FAQS = 'Get Started',
  REALTIME_LOGS = 'RealTIme Logs',
  API_INTEGRATION = 'API Integration',
}

export interface SidebarItem {
  label: SidebarItemsVariant;
  icon?: JSX.Element;
  baseRoute?: string;
  link: string;
  openInNewTab?: boolean;
  showItem?: boolean;
}
