import { serviceNames } from './constants';
import { MixpanelService } from '../Mixpanel';
import type {
  AnalyticsConfig,
  AnalyticsModuleConfig,
  EventOptions,
  EventProperties,
  UserProperties,
} from './types';

class Analytics {
  private modules: AnalyticsModuleConfig = {};
  private config: AnalyticsConfig;

  constructor() {
    this.config = {
      [serviceNames.mixpanel]: {
        enable: process.env.NEXT_PUBLIC_ENABLE_MIXPANEL === 'true',
      },
    };
    this.initServices();
  }

  private initServices() {
    Object.keys(this.config).forEach((key) => {
      if (this.config[key].enable) {
        switch (key) {
          case serviceNames.mixpanel: {
            this.modules[key] = new MixpanelService();
            break;
          }
          default:
            break;
        }
      }
    });
  }

  private modulesToProcess(options?: EventOptions) {
    let modules = Object.keys(this.modules).map((key) => this.modules[key]);
    if (!options) {
      return modules;
    }
    if (options.includes) {
      modules = modules.filter((m) => options.includes?.includes(m.name));
    }
    if (options.excludes) {
      modules = modules.filter((m) => !options.excludes?.includes(m.name));
    }
    return modules;
  }

  trackEvent(
    name: string,
    properties?: EventProperties,
    options?: EventOptions
  ): void {
    this.modulesToProcess(options).forEach((module) => {
      module.trackEvent(name, properties);
    });
  }

  identify(
    userId: string,
    userProperties?: UserProperties,
    options?: EventOptions
  ): void {
    this.modulesToProcess(options).forEach((module) => {
      module.identify(userId, userProperties);
    });
  }

  page(
    name: string,
    properties?: Partial<EventProperties>,
    options?: EventOptions
  ): void {
    this.modulesToProcess(options).forEach((module) => {
      module.page(name, properties);
    });
  }

  timeEvent(eventName: string, options?: EventOptions): void {
    this.modulesToProcess(options).forEach((module) => {
      module.timeEvent(eventName);
    });
  }

  reset(options?: EventOptions): void {
    this.modulesToProcess(options).forEach((module) => {
      module.reset();
    });
  }
}

export const analytics = new Analytics();
