import { DatadogMonitoringService } from '../Datadog';
import {
  BaseMonitoringInterface,
  MonitoringConfig,
  EventOptions,
  MonitoringUserProperties,
} from './types';

class MonitoringService {
  modules: Array<BaseMonitoringInterface> = [];

  init(config: MonitoringConfig) {
    if (config.datadog && config.datadog.enable) {
      this.modules.push(new DatadogMonitoringService(config.datadog));
    }
  }

  modulesToProcess(options?: EventOptions) {
    let modules = this.modules;
    if (!options) {
      return modules;
    }
    if (options.includes) {
      modules = modules.filter((m) => options.includes?.includes(m.name));
    }
    if (options.excludes) {
      modules = modules.filter((m) => !options.excludes?.includes(m.name));
    }
    return modules;
  }

  setUser(
    userId: string,
    userProperties?: MonitoringUserProperties,
    options?: EventOptions
  ) {
    this.modulesToProcess(options).forEach((module) => {
      module.setUser(userId, userProperties);
    });
  }

  clearUser(options?: EventOptions) {
    this.modulesToProcess(options).forEach((module) => {
      module.clearUser();
    });
  }
}

export const Monitoring = new MonitoringService();
