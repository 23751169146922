import { Box } from '@/lib/components';
import { useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { SettingsTabsConfig, SettingsTab } from './constants';
import { StyledTab, StyledTabs } from '@/lib/components/core/StyledTabs';
import { SettingsRoutes } from '@/pages/routes/routes';
import { useAuthContext } from '@/lib/contexts';
import { AnalyticsService } from '@/lib/analytics';

export const SettingsTabs = () => {
  const { route, push } = useRouter();

  const calculateInitialTabValue = useCallback((newRoute: string) => {
    if (newRoute === SettingsRoutes.INTEGRATIONS) {
      return 1;
    }
    const routeConfig = SettingsTabsConfig.find((o) => o.route === newRoute);
    return routeConfig?.index;
  }, []);

  const [value, setValue] = useState(calculateInitialTabValue(route) || 0);
  const { state } = useAuthContext();

  useEffect(() => {
    setValue(calculateInitialTabValue(route) || 0);
  }, [route, calculateInitialTabValue]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    const updatedValue = newValue === 1 ? value : newValue;
    setValue(updatedValue);
    const routeConfig = SettingsTabsConfig.find((o) => o.index === newValue);
    if (routeConfig?.route === SettingsRoutes.TEAMS) {
      AnalyticsService.trackEvent('User clicks on Teams tab', {
        actor: 'User',
        verb: 'clicks',
        direct_object: 'on Teams tab',
        context: 'from topbar',
        context_metadata: 'of settings page of customer portal',
      });
    }
    if (routeConfig?.route) {
      push(routeConfig.route);
    }
  };

  const showTabItems = (tabItem: SettingsTab) => {
    if (tabItem.route === SettingsRoutes.INTEGRATIONS) {
      return state.user?.feature_flags_list?.show_integrations_in_settings;
    } else if (tabItem.route === SettingsRoutes.SUBSCRIPTIONS) {
      return state.user?.feature_flags_list?.show_subscriptions_in_settings;
    } else if (tabItem.route === SettingsRoutes.TEAMS) {
      return (
        state.user?.feature_flags_list?.enable_invite_user_feature &&
        !state.user?.is_sme
      );
    } else {
      return true;
    }
  };

  return (
    <Box sx={{ paddingY: 0.5 }}>
      <Box>
        <StyledTabs
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example">
          {SettingsTabsConfig &&
            SettingsTabsConfig.length &&
            SettingsTabsConfig.map((o, key) => {
              if (showTabItems(o))
                return <StyledTab key={key} label={o.label} />;
            })}
        </StyledTabs>
      </Box>
    </Box>
  );
};
