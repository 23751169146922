import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@/lib/components';
import Link from 'next/link';
import React, { memo } from 'react';
import { useSidebarContext } from './SidebarContext';
import { AnalyticsService } from '@/lib/analytics';

type SidebarMenuItemProps = {
  itemSelected: boolean;
  label: string;
  icon: React.ReactNode;
  link?: string;
  openInNewTab?: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
};

type SidebarTooltipProps = {
  children: JSX.Element;
  tooltip: string;
};

type SidebarLinkProps = {
  children: JSX.Element;
  link?: string;
  target?: string;
  label?: string;
};

const SidebarTooltip: React.FC<SidebarTooltipProps> = ({
  children,
  tooltip,
}) => {
  return (
    <Tooltip title={tooltip} placement="right">
      {children}
    </Tooltip>
  );
};

const SidebarLink: React.FC<SidebarLinkProps> = ({
  children,
  link,
  target,
  label,
}) => {
  const handleLinkClick = () => {
    AnalyticsService.trackEvent(`User clicks on ${label}`, {
      actor: 'User',
      verb: 'clicks',
      direct_object: `on ${label}`,
      context: 'in sidebar',
      context_metadata: 'of customer dashboard',
    });
  };

  return (
    <Link
      href={link ?? ''}
      target={target}
      style={{ textDecoration: 'none', width: '100%' }}
      onClick={handleLinkClick}>
      {children}
    </Link>
  );
};

export const SidebarMenuItem: React.FC<SidebarMenuItemProps> = memo(
  ({
    itemSelected,
    label,
    icon,
    link,
    openInNewTab,
    disabled,
    disabledTooltip,
  }) => {
    const { slimProfile } = useSidebarContext();
    const target = openInNewTab ? '_blank' : undefined;

    return (
      <ListItem key={label.toLowerCase()}>
        {disabled ? (
          <SidebarTooltip tooltip={disabledTooltip ?? ''}>
            <ListItemButton selected={itemSelected}>
              {slimProfile ? (
                <ListItemIcon>{icon}</ListItemIcon>
              ) : (
                <>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </>
              )}
            </ListItemButton>
          </SidebarTooltip>
        ) : (
          <SidebarLink link={link} label={label} target={target}>
            <ListItemButton selected={itemSelected}>
              {slimProfile ? (
                <Tooltip
                  title={label}
                  placement="right"
                  PopperProps={{
                    popperOptions: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, 16],
                          },
                        },
                      ],
                    },
                  }}>
                  <ListItemIcon>{icon}</ListItemIcon>
                </Tooltip>
              ) : (
                <>
                  <ListItemIcon>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </>
              )}
            </ListItemButton>
          </SidebarLink>
        )}
      </ListItem>
    );
  }
);

SidebarMenuItem.displayName = 'SidebarMenuItem';
