export enum UserRole {
  ADMIN = 'admin',
  USER = 'user',
}

export type UserInfoResponse = {
  access_url?: string | null;
  business_name?: string;
  industry?: string;
  last_name: string;
  token: string;
  campaign_frequency?: string;
  first_name: string;
  email: string;
  has_campaign?: boolean;
  use_case?: string;
  lead_volume?: string;
  is_tableau_dashboard_enabled?: boolean;
  is_sme: boolean;
  first_user_login?: boolean;
  is_internal: boolean;
  is_diy_customer_script_enabled?: boolean;
  business_id?: number;
  feature_flags_list: FeatureFlagsList;
  business_user_id?: number;
  is_demo_account?: boolean | null;
  is_business_user: boolean;
  user_role: UserRole;
  business_integration_source: string | null;
  has_existing_subscription?: boolean | null;
  is_internal_business: boolean | null;
  is_api_integration_enabled: boolean | null;
};

type FeatureFlagsList = {
  [key: string]: boolean;
};

export enum UserTypes {
  STAFF_USER = 'Staff user',
  SME_CUSTOMER = 'SME customer',
  NON_SME_CUSTOMER = 'Non-SME customer',
  DEMO_CUSTOMER = 'Demo customer',
}
