import { Card, ButtonBase, Text } from '@/lib/components/core';
import { DownloadIcon } from '@/lib/icons';
import { CardContent } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getRespectiveDateTime } from '@/lib/utils/DateUtils';
import { theme } from '@/lib/theme';
import { AnalyticsService } from '@/lib/analytics';

type ReportCardProps = {
  date: string;
  message: string;
  timeZone: string;
};

export const ReportCard: React.FC<ReportCardProps> = ({
  date,
  message,
  timeZone,
}) => {
  const splitMessage = message.split('Download link:');
  const displayMessage = splitMessage[0];
  const url = splitMessage[1] ? splitMessage[1].trim() : '';
  const { t } = useTranslation();

  const handleDownloadClick = () => {
    AnalyticsService.trackEvent(
      'User clicks on download icon in notification',
      {
        actor: 'User',
        verb: 'clicks',
        direct_object: 'on download icon in notification',
        direct_object_info: 'to download report from report type notification',
        context: 'from notification drawer',
        context_metadata: 'of customer dashboard',
      }
    );
  };

  return (
    <Card sx={{ pt: 1, pb: 2, my: 2, ml: 1, mr: 2 }}>
      <Box
        sx={{
          px: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexShrink: '0',
        }}>
        <Text
          variant="captionSemibold"
          sx={{
            color: theme.baseTheme.palette.typography.labelFocusedColor,
            padding: theme.spacing(0.75, 1),
          }}>
          {t('notificationHistory.reportCard.title')}
        </Text>
        <Text
          variant="tinyRegular"
          sx={{
            color: theme.baseTheme.palette.typography.neutrals300,
            padding: 1,
          }}>
          {getRespectiveDateTime(date, timeZone)}
        </Text>
      </Box>
      <CardContent sx={{ py: 0, px: 2 }}>
        <Text variant="captionRegular" sx={{ textAlign: 'justify' }}>
          {displayMessage}
        </Text>
      </CardContent>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 0.5 }}>
        <ButtonBase
          href={url}
          target="_blank"
          sx={{
            color: theme.baseTheme.palette.typography.oasisMain,
            gap: '4px',
            px: 1,
          }}
          onClick={handleDownloadClick}>
          <DownloadIcon />
          <Text variant="captionSemibold">
            {t('notificationHistory.reportCard.download')}
          </Text>
        </ButtonBase>
      </Box>
    </Card>
  );
};
