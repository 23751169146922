import { DatadogLoggerService } from '../Datadog';
import {
  BaseLoggerInterface,
  LoggerConfig,
  EventOptions,
  LoggerUserProperties,
} from './types';

class LoggerService {
  modules: Array<BaseLoggerInterface> = [];

  init(config: LoggerConfig) {
    if (config.datadog && config.datadog.enable) {
      this.modules.push(new DatadogLoggerService(config.datadog));
    }
  }

  modulesToProcess(options?: EventOptions) {
    let modules = this.modules;
    if (!options) {
      return modules;
    }
    if (options.includes) {
      modules = modules.filter((m) => options.includes?.includes(m.name));
    }
    if (options.excludes) {
      modules = modules.filter((m) => !options.excludes?.includes(m.name));
    }
    return modules;
  }

  error(exception: Error, extra?: object, options?: EventOptions) {
    this.modulesToProcess(options).forEach((module) => {
      module.error(exception, extra);
    });
  }

  info(message: string, extra?: object, options?: EventOptions) {
    this.modulesToProcess(options).forEach((module) => {
      module.info(message, extra);
    });
  }

  warning(message: string, extra?: object, options?: EventOptions) {
    this.modulesToProcess(options).forEach((module) => {
      module.warning(message, extra);
    });
  }

  setUser(
    userId: string,
    userProperties?: LoggerUserProperties,
    options?: EventOptions
  ) {
    this.modulesToProcess(options).forEach((module) => {
      module.setUser(userId, userProperties);
    });
  }

  clearUser(options?: EventOptions) {
    this.modulesToProcess(options).forEach((module) => {
      module.clearUser();
    });
  }
}

export const Logger = new LoggerService();
