import React from 'react';
import { useToastContext } from './ToastContext';
import { Snackbar, Alert as MuiAlert, AlertProps } from '@/lib/components';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  }
);

export const Toast = () => {
  const { isToastShown, toastConfig, hideToast } = useToastContext();

  const handleClose = (
    _event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    hideToast();
  };

  return (
    <Snackbar
      open={isToastShown}
      autoHideDuration={2500}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      <Alert
        onClose={handleClose}
        severity={toastConfig.variant}
        sx={{ width: '100%' }}>
        {toastConfig.message}
      </Alert>
    </Snackbar>
  );
};
