import { HttpMethods, SWRMutationConfig } from '@/lib/network/types';
import * as process from 'process';

type MutationApiCreator = {
  url?: string;
  method?: HttpMethods;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getData?: (...args: any[]) => any;
  getQueryParams?: (...args: unknown[]) => Record<string, unknown>;
  getPathParams?: (...args: unknown[]) => Record<string, unknown>;
  isAuthenticated?: boolean;
  optimisticData?: string;
  revalidate?: boolean;
  populateCache?: boolean;
  rollbackOnError?: boolean;
  throwOnError?: boolean;
};

const urlCreator = (url: string, params?: string): string => {
  return (
    `${process.env.NEXT_PUBLIC_BASE_URL}/${url}` +
    (params ? '?' + new URLSearchParams(params) : '')
  );
};

export class APIService {
  static createCommonRequestByMethod(
    url: string,
    method: HttpMethods,
    { getData, getQueryParams, getPathParams }: MutationApiCreator
  ): SWRMutationConfig {
    return {
      url: urlCreator(url),
      method,
      getData,
      getQueryParams,
      getPathParams,
    };
  }
  static createGetRequest(url: string, args: MutationApiCreator = {}) {
    return APIService.createCommonRequestByMethod(url, HttpMethods.GET, args);
  }

  static createPostRequest(url: string, args: MutationApiCreator = {}) {
    return APIService.createCommonRequestByMethod(url, HttpMethods.POST, args);
  }

  static createPatchRequest(url: string, args: MutationApiCreator = {}) {
    return APIService.createCommonRequestByMethod(url, HttpMethods.PATCH, args);
  }

  static createPutRequest(url: string, args: MutationApiCreator = {}) {
    return APIService.createCommonRequestByMethod(url, HttpMethods.PUT, args);
  }

  static createDeleteRequest(url: string, args: MutationApiCreator = {}) {
    return APIService.createCommonRequestByMethod(
      url,
      HttpMethods.DELETE,
      args
    );
  }
}
